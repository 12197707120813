.privacypage{
    display: grid;
    grid-template-columns: 2fr 1fr;  
    background-color: #EDF6FF;
    width: 100%;
    margin-top: 4vw;
}

.privacybanner{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-column-start: 1;
    grid-column-end: 3;
    padding-left: 8.3%;
    height: 14vw;
    background: linear-gradient(92deg, #00517E 0%, #01A4D6 100%);
}

.privacyinfo{
    color: #000;
font-family: Manrope;
font-size: 18px;
/* font-size: 1.566vw; */
font-style: normal;
font-weight: 700;
line-height: normal;
}

.privacybanner h1{
    color: #FFF;
    font-family: Manrope;
    font-size: 64px;
    /* font-size: 3vw; */
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.privacyterms{
    background-color: white;
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.5vw;
    margin-left: 5%;
    margin-top: 4%;
    margin-bottom: 4%;
    border-radius: 7px;
    padding: 3% 4%;
}

.privacyterms div{
    display: flex;
    flex-direction: column;
    gap: 1vw;
    background-color: white;
}

.privacyintro{
    color: #000;
    font-family: Manrope;
    font-size: 20px;
    /* font-size: 1.74vw;
    font-size: 1.4vw; */
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

.privacyterms div p{
    color: #000;
    font-family: Manrope;
    font-size: 18px;
    /* font-size: 1.566vw;
    font-size: 1.35vw; */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 3%;
}

.privacyterms h5{
    color: #000;
font-family: Manrope;
font-size: 24px;
/* font-size: 2.088vw;
font-size: 1.8vw; */
font-style: normal;
font-weight: 600;
line-height: normal;
}
.privacysupport {
    color: #0D99FF;
font-family: Manrope;
font-size: 18px;
/* font-size: 1.566vw;
font-size: 1.4vw; */
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
}
.privacyterms div  h7{
    color: #000;
font-family: Manrope;
font-size: 20px;
/* font-size: 1.5vw; */
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 10px;
}

.privacydate{
    color: #000;
font-family: Manrope;
font-size: 18px;
/* font-size: 1.4vw; */
font-style: normal;
font-weight: 400;
line-height: normal;
}

.privacypolicy{
    display: flex;
    flex-direction: column;
    padding: 5% 0%;
    gap: 1vw;
    background-color: white;
    grid-column-start: 2;
    grid-column-end: 3;
    height: fit-content;
    margin-top: 8%;
    border-radius: 7px;
    margin-left: 3%;
    width: 89%;
}

.privacypolicy h5{
    color: #000;
font-family: Manrope;
font-size: 25px;
/* font-size: 2.172vw; */
font-style: normal;
font-weight: 700;
line-height: normal;
margin-left: 4%;
margin-right: 4%;
}

.privacypolicy h6{
    color: #000;
font-family: Manrope;
font-size: 18px;
/* font-size: 1.566vw;
font-size: 1.45vw; */
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left: 4%;
margin-right: 4%;

}

.privacybottomline{
    width: 100%;
    background-color: #D0D0D0;
    height:0.8px;
}

.privacytable{
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.privacytable h5{

    color: #000;
font-family: Manrope;
font-size: 20px;
/* font-size: 1.75vw; */
font-style: normal;
font-weight: 600;
line-height: normal;
}
.privacytable p{
    color: #000;
font-family: Manrope;
font-size: 15px;
/* font-size: 1.2vw; */
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
/* padding-left: 6% */
margin-left: 6%;
margin-right: 0%;

}

.privacytable p{
    cursor: pointer;
}




@media (max-width:768px) {

    .privacypage{
        margin-top: 15vw;
        display: flex;
        flex-direction: column;
        margin-bottom: 15vw;
        

    }
    
    .privacybanner h1{
        font-size: 20px;
        width: 100%;

    }

    .privacyterms{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.5vw;
    margin: 2% 2%;
    padding: 3%;
    order:1;
    }

    .privacyintro{
        font-size: 10px; 
    }
    .privacyterms h5{
        font-size: 12px;
        }

    .privacyterms div p{
        font-size: 10px;
        padding-left: 3%;      
    }

    .privacyinfo{
        font-size: 10px;
    }
 
    
    .privacyterms div h7{
        font-size: 8px;
    }
 
    .privacysupport{
        font-size: 10px;
    }
    
    .privacydate{
        font-size: 8px;
    }

    .privacypolicy{
        padding: 5% 0%;
        margin: 2% 3%;
        width: auto;
        order:0;
    }
    
    .privacypolicy h5{
        font-size: 14px; 
    }
    
    .privacypolicy h6{
        color: #000;
        font-family: Manrope;
        font-size: 8px;
 
    }
    
    .privacybottomline{
        width: 100%;
        background-color: #D0D0D0;
        height:0.8px;
    }
    
    .privacytable{
        display: flex;
        flex-direction: column;
        gap: 1vw;
    }
    
    .privacytable h5{
        font-size: 12px; 
    }
    .privacytable p{
        color: #000;
    font-family: Manrope;
    font-size: 10px;
    
    }
    
    

    

}