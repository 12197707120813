@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
.infobar{
    font-family: Manrope;
    background-color: #0081DF;
    border-radius: 7px;
    border:none;
    padding: 3.5% 1%;
    display: flex;
    gap:9%;
    justify-content: center;
    align-items: center;
    padding-top: 0.8vw;
    padding-bottom: 0.8vw;
}
.infobar-section{
    
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 8vw;
    
}
.infobar-section  p{
    font-size: 15px;
    font-size: 1.042vw;
    font-weight: 500;
    color:white;
    text-align: center;
    margin-top: 7%;
    
    
}

.infobar-section .bar-component {
    width: 3.5vw; 
    height:3.5vw; 
    
    
}
/* @media (max-width: 1080px) {
    .infobar{
        gap:7%;
    }
    .infobar-section > p{
        font-size: 14px;
        font-size: 0.972vw;
    }
    .infobar-section .bar-component {
        width: 40px; 
        height:40px; 
    }
}
@media (max-width: 900px) {
    .infobar{
        gap:7%;
    }
    .infobar-section > p{
        font-size: 13px;
        font-size: 0.903vw;
    }
    .infobar-section .bar-component {
        width: 35px; 
        height:35px; 
    }
}
@media (max-width: 821px) {
    .infobar-section > p{
        font-size: 12px;
    }
    .infobar-section .bar-component {
        width: 32px; 
        height:32px; 
    }
} */

@media (max-width :768px) {

    .infobar{
        padding: none;
        justify-content: space-around;
        margin-top: 2.5%;
        margin-bottom: 1%;
        margin-left: 3%;
        margin-right: 3%;
        height:auto;
        padding-top: 10px;
        padding-bottom: 10px;
        gap: 3%;
    }

    .infobar-section {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        gap: 1px;
        align-items: center;
        
        height: 60px;
        padding-top: 5px;
        padding-bottom: 5px;

    }

    .infobar-section > p{
        color: #FFF;
        text-align: center;
        font-family: Manrope;
        font-size: 10px;
        font-style: normal;
        font-weight: 550;
        line-height: normal;
        
      
    }

    .infobar-section .bar-component {
        width: 25px; 
        height:25px; 
        
                
        
    }

    #custom-bar{

        width: 25px; 
        height:50px;
        padding-top: 2px;
    }

    #custom-bar-div{
        
        gap: 0px;
        padding-top: 6px;
    }
}
/* @media screen and (max-width :768px) {

    .infobar{
        padding: none;
        justify-content: space-around;
        margin-top: 2.8%;
        margin-bottom: 1%;
    }

    .infobar-section{
        display: flex;
        flex-direction: column;
        gap: 1px;
        align-items: center;
    }

    .infobar-section > p{
        color: #FFF;
        text-align: center;
        font-family: Manrope;
        font-size: 10px;
        font-style: normal;
        font-weight: 550;
        line-height: normal;
       
    }

    .infobar-section .bar-component {
        width: 25px; 
        height:25px; 
    }
} */