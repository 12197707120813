@media (max-width: 768px) {
  .bottom-bar {
    display: flex;
    justify-content: space-evenly;
    background-color: #0081df;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
    /* padding: 7px; */
    position: fixed;
    bottom: 0px;
    z-index: 2000;
  }
  .show-label {
    display: none;
  }
  .icon {
    padding: 5px;
    margin-top: 0;
    transition: 0.1s ease-in-out;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 5px solid transparent;
    position: relative;
  }
  .icon span{
    position: absolute;
    left:0;
    right: 0;
    bottom: 0.2vw;
    font-family: Inter;
    font-size: 10px;
    font-weight: 400;
    color: white;
  }
  .cart-number-footer {
    position: fixed;
    bottom: 8vw;
    right: 12vw;
    width: 15px;
    height: 15px;
    background-color: #DF0D00;
    color: white;
    border-radius: 50%;
    z-index: 5;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cart-number-footer-1 {
    position: fixed;
    bottom: 5.5vw;
    right: 1vw;
    width: 15px;
    height: 15px;
    background-color: #DF0D00;
    color: white;
    border-radius: 50%;
    z-index: 5;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .active-option {
    transform: scale(1) translateY(-1.2em);
    transition: transform 0.3s linear;
    background: linear-gradient(135deg, #0081df, #0081df);
    border: 5.4px solid white;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 10px; */
  }

  /* .active-option::before {   
        position: absolute;
        top: 13px;
        left: -25px;
        content: ''; 
        height: 21px;
        width: 21.5px;
        
        background-color: #0081DF;
        background-color: green;       

        
        border-top-right-radius: 80px;
        box-shadow:  5px -1px 0 0;    
        
      }  */

  /* .active-option::after {
        position: absolute;
        top: 10px;
        right: -14px;
        content: ''; 
        height: 10px;
        width: 10px;       
        background-color: #0081DF;       
        border-top-left-radius: 20px;
                background-color: green;       

        box-shadow:  -3px -2px 0 0;
        
      }  */
}
