@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
.contact-us-page {
  margin: 6% 5%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 0.1fr;
  font-family: Manrope;
  background-color: navy;
  height: auto;
  margin-bottom: 2%;
  background-image: linear-gradient(
      98deg,
      rgba(0, 90, 155, 0.8) 0.47%,
      rgba(7, 0, 88, 0.8) 95.9%
    ),
    url("../../images/contactusbg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 7px;
}

.contact-info {
  /* background-image: url("../../assets/images/contactusbg.png"); */
  margin: 1% 0 1% 0;
  background-size: 100% 100%;
  color: white;
  padding: 5%;
  padding-left: 12%;
  /* padding-bottom: 15%; */
  padding-top: 6vw;
}
.cont-info {
  font-size: 24px;
  font-size: 1.75vw;
  margin-top: 15%;
  color: #fff;
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  margin: 0;
  margin-bottom: 4%;
}

.contact-info .contact-1 {
  margin-top: 1.7vw;
  font-size: 18px;
  font-size: 1.3vw;
  font-weight: 300;
  width: auto;
  display: flex;
  align-items: flex-start;
  gap: 2vw;
}

.contact-icons {
  height: 17px;
  width: 17px;
  height: 1.3vw;
  width: 1.3vw;
  /* align-self: flex-start; */
  padding-top: 1.2%;
}
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  padding-top: 2.222vw;
  margin-right: 6%;
}

.contact-form > h3 {
  font-size: 24px;
  font-size: 1.667vw;
  font-weight: 600;
  color: white;
  font-family: Manrope;
  line-height: normal;
}

.contact-form > p {
  font-size: 0.9vw;
  color: white;
  font-family: Manrope;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.basic-form-ContactUs {
  /* margin-top: 5%; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 2fr;
  column-gap: 5%;
  row-gap: 5%;
  height: auto;
  padding-bottom: 2.222vw;
}

.basic-form-ContactUs div {
  display: flex;
  justify-content: flex-end;
  grid-column-start: 2;
  grid-column-end: 3;
}

.basic-form-ContactUs div p {
  color: white;
}
.basic-form-ContactUs p {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.86px;
}

.form-name-contactus,
.form-city {
  border: 0.7px solid #cfcfcf;
  border-radius: 3px;
  font-size: 14px;
  padding: 3% 3%;
  margin-top: 2%;
  background-color: transparent;
  color: white;
}

.form-name-contactus {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  height: 0.5vw;
}

.form-name-contactus:focus,
.form-city:focus {
  outline: solid 1.5px #0081df;
  border: none;
}

.mobile-number2 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  height: 3vw;
  border: 0.7px solid #cfcfcf;
  border-radius: 3px;
  font-size: 14px;
  margin-top: 2%;
  background-color: transparent;
  color: white;
  padding-left: 7%;
}
.form-email {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  height: 3vw;
  border: 0.7px solid #cfcfcf;
  border-radius: 3px;
  font-size: 14px;
  padding-left: 7%;
  margin-top: 2%;
  background-color: transparent;
  color: white;
}
.form-city {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;

  height: 0.5vw;
}

.mobile-number2::placeholder,
.form-email::placeholder {
  color: #dedede;
  font-family: Manrope;
  font-size: 1.042vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  /* padding-left: 3%; */
}

.form-name-contactus::placeholder,
.form-city::placeholder {
  color: #dedede;
  font-family: Manrope;
  font-size: 1.042vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  /* padding-right: 3%; */
}
.mobile-number2:focus,
.form-email:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.ContactUs-info {
  border: 0.7px solid #cfcfcf;
  border-radius: 3px;
  /* padding:1.5% 2%; */
  font-family: Manrope;
  resize: none;
  font-weight: 500;
  font-size: 15px;
  margin-top: 1%;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 5;
  height: 100px;
  height: 6vw;
  background-color: transparent;
  color: white;
  padding: 1.5% 3%;
}
.ContactUs-info::placeholder {
  color: #dedede;

  font-family: Manrope;
  font-size: 1.042vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}
.ContactUs-info:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.contact-us-active-sell {
  background-color: #0081df;
  font-size: 17px;
  border-radius: 5px;
  color: white;
  padding: 7px 25px;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-self: flex-end;
  cursor: pointer;
}
.active-contactus:hover {
  opacity: 0.7;
  cursor: pointer;
}

.contact-us-disabled-sell {
  background-color: rgb(172, 166, 166);
  font-size: 17px;
  border-radius: 5px;
  color: white;
  padding: 7px 25px;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-self: flex-end;
  cursor: not-allowed;
}

.zoplar-map {
  height: 22vw;
}

.phone-contact-form {
  display: none;
}

@media (max-width: 768px) {
  .zoplar-map,
  .contact-form h3,
  .contact-form p,
  .contact-form form {
    display: none;
  }
  
  .contact-us-page {
    margin: 16% 5%;
    display: block;
    height: auto;
    margin-bottom: 2%;
    padding: none;
    border-radius: 7px;
    padding-top: 10px;
    /* padding-bottom: 20vw; */
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    height: 100%;
    margin: 1% 0 1% 2%;
    background-size: 100% 100%;
    color: white;
    padding: 4%;

    gap: 5px;
    margin: none;
    background: linear-gradient(
      98deg,
      rgba(0, 90, 155, 0.8) 0.47%,
      rgba(7, 0, 88, 0.8) 93.42%,
      url("../../images/contactusbg.jpg")
    );
  }
  .cont-info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: #fff;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    margin-bottom: none;
  }

  .contact-info .contact-1 {
    width: auto;
    margin-top: none;
    display: flex;
    gap: 15px;
    color: #fff;
    font-family: Manrope;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: none;
  }

  .contact-icons {
    height: 10px;
    width: 10px;

    align-self: flex-start;
    padding-top: 0.5%;
  }
  .phone-contact-form {
    display: flex;
    flex-direction: column;
    /* gap: 1vw; */
    padding-top: 4vw;
    padding-bottom: 20vw;
    margin-right: 6% 5%;
    position: relative;
  }

  .phone-contact-form > h3 {
    color: #0081df;
    text-align: center;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
  .phone-error-contact{
    color: #df0d00;
    font-size: 2.6vw !important;
    position: absolute;
    top: 31%;
    left:7%;
  }
  .phone-contact-form > p {
    color: black;
    text-align: center;
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 15px;
  }

  .basic-form-ContactUs {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 10px;
    align-items: center;
  }

  .basic-form-ContactUs div {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    align-items: center;
    width: 87%;
  }

  .basic-form-ContactUs input {
    border: 0.7px solid #cfcfcf;
    border-radius: 5px;
    padding: 4.5% 3%;
    margin-top: 2%;
    background-color: transparent;
    color: black;
    width: 80%;
    font-family: Manrope;
    resize: none;
    font-weight: 500;
    font-size: 15px;
  }
  .mobile-number2{
    height:1vw;
  }
  .form-email{
    height:1vw;
  }
  .form-name-contactus:focus,
  .form-city:focus {
    outline: solid 1.5px #0081df;
    border: none;
  }

  .mobile-number2::placeholder,
  .form-email::placeholder {
    text-align: left;
    padding-left: 0%;
    color: #bababa;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    
    
    color: #868686;
  }

  .form-name-contactus::placeholder,
  .form-city::placeholder {
    text-align: left;
    color: #bababa;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  
  
  color: #868686;
  }
  .mobile-number2:focus,
  .form-email:focus {
    outline: solid 1.5px #0081df;
    border: none;
  }

  .ContactUs-info,
  .basic-form-ContactUs textarea {
    border: 0.7px solid #cfcfcf;
    border-radius: 5px;
    margin-top: 2%;
    height: 100px;
    background-color: transparent;
    padding: 1.5% 2%;
    width: 82%;
    font-family: Manrope;
    font-weight: 500;
    font-size: 15px;
    color: black;
  }
  .ContactUs-info::placeholder {
    color: #e9e9e9;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;

    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    
    
    color: #868686;
  }
  .ContactUs-info:focus {
    outline: solid 1.5px #0081df;
    border: none;
  }
  .active-contactus {
    background-color: #0081df;
    font-size: 17px;
    border-radius: 5px;
    color: white;
    padding: 7px 25px;
    border: none;
    /* position: absolute;
        right: 5%;
        top:80%; */
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    align-self: flex-end;
  }
  .active-contactus:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .disabled-contactus {
    background-color: rgb(172, 166, 166);
    font-size: 17px;
    border-radius: 5px;
    color: white;
    padding: 7px 25px;
    border: none;
    /* position: absolute;
        right: 5%;
        top:80%; */
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    align-self: flex-end;
  }
  .zoplar-map {
    height: 22vw;
  }

  .phonepermission{
    width: 80%;
    align-self: flex-start;
    color: #A59F9F;
text-align: center;
font-family: Manrope;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: left;
margin-top: 5px;
margin-right: 20px;
margin-left: 22px;
  }
}