@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: Manrope;
}
@font-face {
  font-family: 'Manrope';
  src: url('https://fonts.gstatic.com/s/manrope/v7/3JnlS0xuDWRBEpijkSlEGIY3.ttf') format('truetype');
  font-weight: 200; /* Adjust the font weight as needed */
  font-style: normal; /* Adjust the font style as needed */
  font-display: swap;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/*input[type="number"] {
  -moz-appearance: textfield;
}*/

.greenColor {
  color: green !important;
}
.redColor {
  color: red !important;
}
/* Example fade transition */
.page-enter {
  opacity: 0;
}
.page-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}
.page-exit {
  opacity: 1;
}
.page-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}


/* Override the background color and text color of the alert */
.react-alert {
  background-color: #d62323;
  color: #fff;
}

/* Override the styles for the alert message */
.react-alert-message {
  font-size: 18px;
}

/* Override the styles for the alert close button */
.react-alert-button {
  background-color: #ff5722;
  color: #fff;
  border: none;
}
.alert-styling{
  background-color: red;
}

