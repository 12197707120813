@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
.cartPage-container{
  position: sticky;
  top: 12%;
}
.cartPage {
  font-family: Manrope;
  border: 0.7px solid #cfcfcf;
  border-radius: 7px;
  max-height: fit-content;
  margin-top: 2.2%;
  margin-left: 1%;
  position: relative;
}
.cart-scroll{
  max-height: 60vh;
  overflow-y: auto;

}
.cart-scroll::-webkit-scrollbar {
  width: 3px;
}

.cart-scroll::-webkit-scrollbar-thumb {
  background-color: #c4c4c4; /* Color of the thumb (the draggable part) */
  border-radius: 6px;
}
.cart-scroll::-webkit-scrollbar-track {
  background-color: transparent;

  /* background: #d9d9d9;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  background-clip: padding-box; */
}
.cartHeader {
  font-size: 18px;
  font-weight: 700;
  color: black;
  padding: 4% 2%;
  margin-left: 4%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.icons-cart{
  cursor: pointer;
  position: absolute;
  right: 6%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1vmax;
}
.cart-line {
  background-color: #c4c4c4;
  height: 0.5px;
}
.cartContainer {
  margin: auto;
  width: 90%;
  height: 5vmax;
  display: grid;
  grid-template-columns:2.5fr 2fr 0.5fr;
  gap:2%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-bottom: 0.7px solid #CFCFCF;
}
.cartContainer span{
  cursor: pointer;
}
.cartInput{
  margin-top: 13%;
}
.cartInputBox{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 0.7px solid #CFCFCF;
  border-radius: 5px;
  /* width: 100%; */
  /* height: 3vmax; */
  position: relative;
}
/* .cartInputBox > button{
  border: none;
  background-color: transparent;
  color:#0081DF;
  padding: 0 2%;
  font-size:18px ;
  font-weight: 400;
  cursor: pointer;
}
.cartInputBox > button:hover{
  color:#035a98;
} */
.cartInputBox > input {
  font-size: 13px;
  font-weight: 500;
  border:none;
  width:13%;
  outline: none;
  margin: 0%;
  text-align: center;
  color: #0081DF;
}
.cartInputBox > p{
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  color: #0081DF;
  margin-left: 5%;
}
.vertical-line{
  border-left: 1px solid #CFCFCF;
  height: 38px;   
}
.dropdown{
  cursor: pointer;
  padding: 4%;
}
.dropdown p{
  color:#0081DF;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  padding-top: 1%;
}
.dropdown ul{
  list-style: none;
  position: absolute;
  margin-top: 1%;
  top: 100%;
  left: 50%;
  right: 0;
  padding-left: 0;
  border: #c4c4c4 solid 0.7px;
  border-radius: 5px;
  z-index: 1;
  background-color: white;
  box-shadow: 4px 4px 3px 2px rgba(0, 0, 0, 0.10);
}
.dropdown li{
  color:#0081DF;
  font-size: 13px;
  cursor: pointer;
  padding: 9% 10%;
  background-color: white;
  border-radius: 5px;
  font-weight: 500;
}
.dropdown li:hover{
  border-radius: 3px;
  background-color: #0081DF;
  color: white;
  transition: 0.1s ease-in;
}
.cartInputBox .arrow-down{
  position: absolute;
  right:2%;
}
/* .cartSubtotal {
    display: flex;
    padding: 0.5vmax;
    height: 8vmax;
    align-items: center;
    box-sizing: border-box;
    font: 300 1vmax cursive;
    justify-content: flex-end;
    color: rgba(0, 0, 0, 0.753);
  } */

/* .cartGrossProfit {
    display: grid;
    grid-template-columns: 2fr 1.2fr;
  } */

/* .cartGrossProfitBox {
    border-top: 3px solid tomato;
    margin: 1vmax 4vmax;
    box-sizing: border-box;
    padding: 2vmax 0;
    font: 300 1vmax "Roboto";
    display: flex;
    justify-content: space-between;
  } */

.checkOutBtn {
  margin-top: 15%;
  display: flex;
  justify-content: center;
}
.checkOutBtn > button {
  
  width: 90%;
  background-color: #0081df;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  color: white;
  padding: 7px 20px;
  border: none;
  white-space: nowrap;
  margin-bottom: 6%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.checkOutBtn > button:hover{
  opacity:0.7;
  cursor: pointer;
}
@media (min-width:1600px) {
  .cartPage{
    margin-top: 3%;
  }
}
@media (min-width:1800px) {
  .cartPage{
    margin-top: 3.2%;
  }
}
@media (max-width:1080px) {
  .cartPage-container{
    top: 15%;
  }
}
@media screen and (max-width: 600px) {
  .cartPage {
    padding: 0;
    min-height: 60vh;
  }

  .cartHeader {
    width: 100%;
    font: 300 1.7vmax "Roboto";
    grid-template-columns: 3fr 1fr 1fr;
  }

  .cartContainer {
    width: 100%;
    grid-template-columns: 3fr 1fr 1fr;
  }

  .cartInput {
    height: 20vmax;
  }

  .cartInput > button {
    padding: 1.5vmax;
  }

  .cartInput > input {
    width: 2vmax;
    padding: 1.5vmax;
    font: 400 1.8vmax "Roboto";
  }

  .cartSubtotal {
    padding: 1.5vmax;
    height: 20vmax;
    font: 300 2vmax "Roboto";
  }

  .cartGrossProfit {
    display: grid;
    grid-template-columns: 0fr 2fr;
  }

  .cartGrossProfitBox {
    padding: 2vmax;
    font: 300 2vmax "Roboto";
  }

  .checkOutBtn > button {
    padding: 2vmax 4vmax;
    width: 100%;
    font: 300 2vmax "Roboto";
  }
}
