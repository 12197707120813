@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
.Sell {
  margin: 6% 5% 2% 5%;
  padding-top: 10px;
  font-family: Manrope;
  display: grid;
  grid-template-columns: 0.95fr 1fr;
  column-gap: 2.3%;
  position: relative;
}
.sell-img {
  height: fit-content;
}
.sell-img > div {
  display: flex;
  justify-content: center;
}
.sell-img img {
  width: 80%;
  margin-top: 2%;
  aspect-ratio: 1.504;
}
.sell-img > h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 3%;
}
.sell-img > p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 4.5%;
}
.sell-form {
  background-image: url("../../images/bg-sell.jpeg");
  background-size: 100% 100%;
  border: none;
  border-radius: 7px;
  padding: 2.5% 5%;
  margin-top: 2%;
  height: fit-content;
  position: relative;
  /* max-height: 25vmax;
  overflow-y: auto; */
}
.sell-form > h3 {
  font-size: 20px;
  margin-bottom: 2%;
}
.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0%;
}
.basic-form-sell > div > div {
  margin-bottom: 6%;
  width: 94%;
}
.basic-form-sell input {
  border: none;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  width: 90%;
  padding: 3.5% 6%;
  margin-top: 2%;
}
.basic-form-sell .form-name::placeholder {
  color: #868686;
  font-weight: 500;
}
.name-container {
  margin-bottom: 3%;
  width: 100%;
}
.name-container .placeholder {
  left: 2.9%;
  top: 40%;
}
.input-container {
  position: relative;
}
.input-container input:valid + .placeholder {
  display: none;
}
.input-container .form-name::placeholder {
  color: #868686;
  font-weight: 600;
  opacity: 1;
}
.placeholder {
  position: absolute;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  pointer-events: none;
  top: 33%;
  left: 6%;
  margin: auto;
  color: #868686;
}
.placeholder span {
  color: #df0d00;
}

.form-name:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.basic-form-sell .mobile-number::placeholder {
  color: #868686;
  font-weight: 500;
}
.mobile-number:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.phone-error{
  font-size: 0.8vw;
  font-weight: 500;
  margin-top: 1%;
  color: #df0d00;
  position: absolute;
  top:35%;
  z-index: 2;
}
.sell-info {
  border: none;
  border-radius: 5px;
  width: 92%;
  font-size: 13px;
  padding: 1.5% 3%;
  margin-top: 1.5%;
  font-family: Manrope;
  resize: none;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 2%;
}
.sell-form .sell-info::placeholder {
  color: #868686;
  font-weight: 600;
  font-size: 14px;
  opacity: 0.99;
}
.sell-info:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.active-sell {
  background-color: #0081df;
  font-size: 17px;
  font-weight: 500;
  border-radius: 7px;
  color: white;
  padding: 1.5% 6%;
  border: none;
  /* position: absolute;
  right: 4%;
  top: 89%; */
  margin-left: 75.5%;
  margin-bottom: 2%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.active-sell:hover {
  opacity: 0.7;
  cursor: pointer;
}
.disabled-sell {
  /* background-color: #0081df; */
  background-color: #c4c4c4;
  cursor: not-allowed;
  font-size: 17px;
  font-weight: 500;
  border-radius: 7px;
  color: white;
  padding: 1.5% 6%;
  border: none;
  margin-left: 75.5%;
  margin-bottom: 2%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.phone-sell-form {
  display: none;
}
@media (min-width: 1500px) {
  .Sell {
    margin: 6% 8% 2% 8%;
  }
}
@media (max-width: 1300px) {
  .sell-img > p {
    font-size: 15px;
  }
}
@media (max-width: 1030px) {
  .Sell {
    grid-template-columns: 0.9fr 1fr;
  }
  .sell-img > h3 {
    font-size: 19px;
  }
  .sell-img > p {
    font-size: 14px;
  }
  .sell-form > h3 {
    font-size: 19px;
  }
  .basic-form-sell .placeholder {
    font-size: 13px;
  }
  .sell-info {
    font-size: 14px;
  }
  .disabled-sell {
    font-size: 16px;
    margin-left: 73.5%;
  }
  .active-sell {
    font-size: 16px;
    margin-left: 73.5%;
  }
}
@media (max-width: 947px) {
  .basic-form-sell .placeholder {
    font-size: 12px;
  }
}
@media (max-width: 874px) {
  .Sell {
    margin-top: 7%;
  }
  .sell-img > h3 {
    font-size: 18px;
  }
  .sell-img > p {
    font-size: 13px;
  }
  .sell-form > h3 {
    font-size: 18px;
  }
  .basic-form-sell .placeholder {
    font-size: 11px;
  }
  .sell-info {
    font-size: 13px;
  }
  .disabled-sell {
    font-size: 15px;
    margin-left: 72.5%;
  }
  .active-sell {
    font-size: 15px;
    margin-left: 72.5%;
  }
}
@media (max-width: 800px) {
  .sell-img > h3 {
    font-size: 17px;
  }
  .sell-img > p {
    font-size: 13px;
  }
  .sell-form > h3 {
    font-size: 17px;
  }
  .basic-form-sell .placeholder {
    font-size: 10px;
  }
  .sell-info {
    font-size: 12px;
  }
  .disabled-sell {
    font-size: 14px;
    margin-left: 71.5%;
  }
  .active-sell {
    font-size: 14px;
    margin-left: 71.5%;
  }
}
/* @media (max-width: 730px) {
  .Sell {
    margin: 6% 12% 2% 12%;
    padding-top: 10px;
    font-family: Manrope;
    display: grid;
    grid-template-rows: 0.4fr 1fr;
    grid-template-columns: 1fr;
    row-gap: 2%;
    position: relative;
  }
  .sell-img img {
    display: none;
  }
  .sell-img > h3 {
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 2%;
  }
  .sell-img > p {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 1.5%;

  }
  .sell-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  .sell-form {
    background-image: url("../../images/bg-sell.jpeg");
    background-size: 100% 100%;
    border: none;
    border-radius: 7px;
    padding: 2.5% 5%;
    margin-top: 2%;
    height: fit-content;
    position: relative;
    
  }
  .sell-form > h3 {
    font-size: 20px;
    margin-bottom: 2%;
  }
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0%;
  }
  .basic-form-sell > div > div {
    margin-bottom: 6%;
    width: 94%;
  }
  .basic-form-sell input {
    border: none;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    width: 90%;
    padding: 3.5% 6%;
    margin-top: 2%;
  }
  .basic-form-sell .form-name::placeholder {
    color: #868686;
    font-weight: 500;
  }
  .name-container{
    margin-bottom: 3%;
    width:100%;
  }
  .name-container .placeholder{
    left: 2.9%;
    top: 40%;
  }
  .input-container {
    position: relative;
  }
  .input-container input:valid + .placeholder {
    display: none;
  }
  .placeholder {
    position: absolute;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    pointer-events: none;
    top: 33%;
    left: 6%;
    margin: auto;
    color: #868686;
  }
  .placeholder span {
    color: #DF0D00;
  }
  .form-name:focus {
    outline: solid 1.5px #0081df;
    border: none;
  }
  .basic-form-sell .mobile-number::placeholder {
    color: #868686;
    font-weight: 500;
  }
  .mobile-number:focus {
    outline: solid 1.5px #0081df;
    border: none;
  }
  .sell-info {
    border: none;
    border-radius: 3px;
    width: 92%;
    font-size: 14px;
    padding: 1.5% 3%;
    margin-top: 1.5%;
    font-family: Manrope;
    resize: none;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 2%;
  }
  .sell-form .sell-info::placeholder {
    color: #bababa;
  }
  .sell-info:focus {
    outline: solid 1.5px #0081df;
    border: none;
  }
  .active-sell {
    background-color: #0081df;
    font-size: 17px;
    font-weight: 500;
    border-radius: 7px;
    color: white;
    padding: 1.5% 6%;
    border: none;
    
    margin-left: 75.5%;
    margin-bottom: 2%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .active-sell:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .disabled-sell {
    background-color: #0081df;
    cursor: not-allowed;
    font-size: 17px;
    font-weight: 500;
    border-radius: 7px;
    color: white;
    padding: 2% 6%;
    border: none;
    margin-left: 75.5%;
    margin-bottom: 2%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
} */

@media (max-width: 768px) {
  .Sell{
    margin-left:0;
    margin-right: 0;
  }
  .sell-img div {
    display: none;
  }
  .sell-img{
    margin-left: 5%;
    margin-right: 5%;
  }

  .basic-form-sell-mobile div {
    position: relative;
    display: flex;
    justify-content: flex-start;

    align-items: center;
    width: 87%;
  }

  .mobile-placeholder-name {
    color: #bababa;
    position: absolute;
    top: 16px;
    left: 12px;
    color: #6f6f6f;

    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    pointer-events: none;
  }

  .mobile-placeholder-name:after {
    content: "*";
    color: red;
  }

  .form-name-sell:valid + .mobile-placeholder-name {
    display: none;
  }

  .mobile-placeholder-mobile {
    color: #bababa;
    position: absolute;
    top: 16px;
    left: 12px;
    color: #6f6f6f;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    pointer-events: none;
  }
  .mobile-placeholder-mobile:after {
    content: "*";
    color: red;
  }

  .mobile-number-sell:valid + .mobile-placeholder-mobile {
    display: none;
  }

  .mobile-placeholder-city {
    color: #bababa;
    position: absolute;
    top: 16px;
    left: 12px;
    color: #6f6f6f;

    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    pointer-events: none;
  }
  .mobile-placeholder-city:after {
    content: "*";
    color: red;
  }

  .form-city-sell:valid + .mobile-placeholder-city {
    display: none;
  }
  .Sell {
    display: flex;
    flex-direction: column;
    margin: none;
    padding-top: 10vw;
    padding-bottom: 12vw;
    /* margin-bottom: 25vw;
    margin-top: 25%; */
  }

  .sell-img {
    display: flex;
    flex-direction: column;
  }
  .sell-img h3 {
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .sell-img p {
    color: #000;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .sell-form {
    display: none;
  }

  .phone-sell-form {
    display: flex;
    flex-direction: column;
    background-color: white;
    /* width: 100%; */
    margin-top: 3vw;
    height: fit-content;
    padding-bottom: 10px;
    border-radius: 15px 15px 0px 0px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.15);
    padding-left: 1vw;
    padding-right: 1vw;
    margin-left: 1vw;
    margin-right: 1vw;
  }
  .phone-error-mob{
    font-size: 2.4vw;
    font-weight: 500;
    /* margin-top: 1%; */
    color: #df0d00;
    /* position: absolute; */
    /* top:35%; */
    z-index: 2;
    align-self: flex-start;
    margin-left: 7%;
  }
  .phone-sell-form h4 {
    align-self: flex-start;
    margin-left: 6vw;
    margin-bottom: 3vw;
    margin-top: 4vw;
  }

  .basic-form-sell-mobile {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 6px;
    align-items: center;
  }

  .basic-form-sell-mobile input,
  .dropdown-type div {
    border-radius: 5px;
    padding: 4% 3%;
    margin-top: 2%;
    background-color: transparent;

    width: 100%;
    font-family: Manrope;
    resize: none;

    height: 10px;
    border: 0.7px solid #c7c7c7;

    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .sell-info,
  .basic-form-sell-mobile textarea {
    border: 0.7px solid #cfcfcf;
    border-radius: 3px;
    margin-top: 2%;
    height: 100px;
    background-color: transparent;
    padding: 1.5% 2%;
    width: 82%;
    font-family: Manrope;
    font-weight: 500;
    font-size: 15px;
    color: black;
    border-radius: 5px;
  }
  

  input:focus {
    outline: solid 2px #0081df;
    border: none;
  }
  
  textarea:focus{
    outline: solid 5px #0081df;
    border: none;
  }
  /* .active-sell{
    background-color: #0081df;
    font-size: 17px;
    border-radius: 5px;
    color: white;
    padding: 7px 25px;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    align-self: flex-end;
}
.active-sell:hover{
    opacity: 0.7;
    cursor: pointer;
}

.disabled-sell{
  background-color: grey;
  font-size: 17px;
  border-radius: 5px;
  color: white;
  padding: 7px 25px;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-self: flex-end;
} */

  .active-sell-mobile {
    background-color: #0081df;
    font-size: 16px;
    font-weight: 600;
    border-radius: 7px;
    color: white;
    padding: 7px 25px;
    border: none;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.25);
    align-self: flex-end;
  }
  .active-sell-mobile:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .disabled-sell-mobile {
    background-color: #E3E3E3;
    font-size: 16px;
    font-weight: 600;
    border-radius: 7px;
    color: #A59F9F;
    padding: 7px 25px;
    border: none;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.25);
    align-self: flex-end;
    margin-bottom: 2vw;
  }

  .form-email-sell::placeholder,
  .form-company-sell::placeholder,
  .sell-info::placeholder {
    color: #6f6f6f;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
