@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
.MuiDialogContent-root {
  overflow-y: hidden !important; /* Use !important to ensure it overrides Material UI styles */
  max-height: none !important;
  /* border-radius: 7px; */
}

.close-button {
  display: inline-flex;
  position: relative;
  float: right;
  border: none;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: black; /* Replace with your desired circle color */
  cursor: pointer;
  margin-top: 3%;
  margin-right: 3%;
}
.login-signup {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.close-button:hover {
  background-color: grey;
  transition: 0.2s ease-in-out;
}
.login-container {
  position: relative;
}
.login-img {
  width: 320px;
  height: 450px;
}
.login-img > img {
  width: 100%;
  height: 100%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  object-fit: cover;
  /* padding: 0;
  margin: 0; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.login-text {
  font-family: Manrope;
  margin-left: 26%;
  margin-top: 14%;
}
.login-text > h1 {
  font-size: 30px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 19px;
}
.login-text > p {
  font-size: 17px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 7px;
}
.mobile-field {
  display: flex;
  gap: 10px;
}
.cc > select {
  width: 50px;
  height: 30px;
  border: none;
  background-color: #f1f1f1;
  font-size: 18px;
  padding: 5px;
}
.country-code {
  width: 40px;
  height: 30px;
  border: none;
  background-color: #f1f1f1;
  border-radius: 3px;
  font-family: Manrope;
  font-size: 18px;
  padding: 5px;
  text-align: center;
}
/* Style for the custom select container */
.custom-select {
  position: relative;
}

/* Style for the custom select button */
.custom-select-button {
  font-family: Manrope;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  padding: 5px 8px;
  cursor: pointer;
  width: 61px;
  height: 36px;
  border: none;
  background-color: #f1f1f1;
  border-radius: 3px;
}

/* Style for the custom dropdown */
.custom-select-dropdown {
  position: absolute;
  top: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 197px;
  overflow-y: scroll;
  color: black;
  list-style: none;
  margin: 0; /* Add this to remove any default margin */
  padding: 0; /* Add this to remove any default padding */
  margin-top: 5px;
  padding-bottom: 0;
  max-width: 100%;
  overflow-x: hidden;
}
.custom-select-dropdown::-webkit-scrollbar {
  width: 0.5em; /* Width of the scrollbar */
}

.custom-select-dropdown::-webkit-scrollbar-thumb {
  background-color: transparent; /* Color of the thumb (the draggable part) */
}

.custom-select-dropdown::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the track (the background) */
}
/* Style for the custom dropdown items */
.custom-select-dropdown li {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  font-family: "Manrope";
  font-size: 13px;
  font-weight: 400;
  padding: 4px 3px;
  cursor: pointer;
}

/* Style for the custom dropdown items on hover */
.custom-select-dropdown li:hover {
  color: #0081df;
}
.mobile-no {
  width: 155px;
  height: 26px;
  border: none;
  background-color: #f1f1f1;
  border-radius: 3px;
  font-family: Manrope;
  font-size: 17px;
  padding: 5px 15px;
  position: relative;
}
.mobile-no:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.l-active-button {
  background-color: #0081df;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  color: white;
  padding: 8px 17px;
  border: none;
  /* margin-top: 15px; */
  position: absolute;
  right: 19%;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.l-active-button:hover {
  cursor: pointer;
  opacity: 0.7;
}
.l-disabled-button {
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  color: white;
  padding: 8px 17px;
  border: none;
  /* margin-top: 15px; */
  background-color: #ccc;
  cursor: not-allowed;
  position: absolute;
  right: 19%;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.whatsapp-alerts {
  margin-top: 3.5%;
  display: flex;
  /* align-items: center; */
  gap: 0%;
}
.whatsapp-alerts > p {
  font-size: 13.5px;
  font-weight: 400;
}
/* Hide the default checkbox */
.custom-checkbox input[type="checkbox"] {
  display: none;
}

/* Style the custom checkbox container */
.custom-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 28px; /* Adjust the space for the checkbox */
  cursor: pointer;
  font-size: 16px;
}

/* Style the custom checkbox when checked */
.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: white; /* Blue background color */
  border-color: #a0a0a0;
}

/* Create the checkmark icon */
.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  border: 1.5px solid #a0a0a0;
  border-radius: 2px;
  background-color: #fff;
}

/* Style the checkmark icon when checked */
.custom-checkbox input[type="checkbox"]:checked + .checkmark::before {
  content: "\2713";
  display: block;
  color: #0081df; /* White color for the tick */
  font-size: 16px; /* Adjust the size of the tick */
  font-weight: 700;
  text-align: center;
  line-height: 1;
}

.otp-text {
  font-family: Manrope;
  margin-top: 4.5%;
  position: relative;
}
.invalid-otp {
  position: absolute;
  top: 19%;
  left: 29%;
  color: #df0d00;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 3px;
}
.resend-otp {
  position: absolute;
  top: 100%;
  left: 0%;
  margin-top: 4px;
  /* color:#0081df; */

  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}
.otp-text > p {
  font-size: 17px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 10px;
}
.otp-field {
  width: 245px;
  height: 21px;
  display: flex;
  justify-content: center;
  border: transparent;
  background-color: #f1f1f1;
  border-radius: 3px;
  font-family: Manrope;
  font-size: 17px;
  padding: 7px;
  text-align: center;
}
.invalid-box {
  border: 1px solid #df0d00;
}
.otp-input-field {
  height: 21px;
  border: transparent;
  outline: none;
  background-color: #f1f1f1;
  font-family: Manrope;
  font-size: 17px;
  padding: 7px;
  text-align: center;
}
.privacy-policy {
  position: absolute;
  top: 92%;
  right: 2%;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  color: black;
}
/* .custom-otp-input input {
  caret-color: auto; 
  animation: none; 
} */
.register-text {
  font-family: Manrope;
  padding: 14% 21%;
  margin-left: 6%;
}
.register-text > h1 {
  font-size: 30px;
  font-weight: 800;
  text-align: left;
  margin-bottom: 16px;
}
.register-text p {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 2.5%;
}
.name-field > input {
  width: 100%;
  height: 32px;
  border-radius: 3px;
  background-color: #f1f1f1;
  font-size: 14px;
  border: none;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: black;
}
.name-register:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.name-register::placeholder {
  color: #dedede;
}
.name::placeholder {
  color: #dedede;
}
.name:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.second-field {
  display: flex;
  gap: 4%;
}
.second-field p {
  margin-bottom: 5.5%;
}
.second-field input {
  margin-bottom: 12px;
}
.dropdown-occ > div {
  position: relative;
  background-color: #f1f1f1;
  height: 27px;
  /* width: 100%; */
  width: 130px;
  margin-top: 6%;
  border-radius: 3px;
  padding-left: 12px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.dropdown-occ p {
  color: #0081df;
  font-size: 15px;
  cursor: pointer;
  font-weight: 600;
}
.dropdown-occ .selected-type {
  color: #6f6f6f;
}
.dropdown-icon-occ {
  position: absolute;
  right: 13px;
  margin-top: 5%;
  width: 14px;
  height: 10px;
}
.dropdown-occ ul {
  list-style: none;
  position: absolute;
  margin-top: 3%;
  width: 100%;
  top: 100%;
  left: 0;
  right: 0;
  padding-left: 0;
  border: #cdcdcd solid 0.3px;
  border-radius: 7px;
  z-index: 1;
  background-color: white;
  box-shadow: 4px 4px 3px 2px rgba(0, 0, 0, 0.1);
}
.dropdown-occ li {
  color: black;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  padding: 5% 10%;
  background-color: white;
  border-radius: 7px;
  transition: all 0.1s ease-in-out;
}
.dropdown-occ li:hover {
  border-radius: 5px;
  background-color: #0081df;
  color: white;
}
.register-text select {
  border: none;
  background-color: #f1f1f1;
  font-size: 15px;
  padding: 0px;
  width: 100%;
  height: 32px;
  border-radius: 3px;
  color: black;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.register-text select:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.register-text option {
  padding: 10px;
  border-radius: 3px;
}

.register-text input {
  width: 100%;
  height: 32px;
  border-radius: 3px;
  background-color: #f1f1f1;
  border: none;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
}
.register-text > input:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.r-active-button {
  background-color: #0081df;
  display: block;
  font-size: 15px;
  font-weight: 500;
  border-radius: 7px;
  color: white;
  padding: 8px 30px;
  border: none;
  margin-top: 20px;
  position: absolute;
  right: 11%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.r-active-button:hover {
  cursor: pointer;
  opacity: 0.7;
}
.r-disabled-button {
  display: block;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
  color: white;
  padding: 8px 30px;
  border: none;
  margin-top: 20px;
  position: absolute;
  right: 11%;
  background-color: #ccc;
  cursor: not-allowed;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

Dialog {
  /* background-color: pink; */
}
.phone-login {
  display: none;
}

@media screen and (max-width: 768px) {
  .full-screen-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    
  }
  .open-popup {
    animation: slide-down 0.5s ease;
  }
  .close-popup{
    animation: slide-up 0.5s ease;
  }
  .login-popup,
  .login-signup {
    background-color: white;
    width: 100%;
    height: 100%;
    padding-top: 100%;
    padding-right: 14%;
  }

  /* purana code */
  .MuiDialogContent-root {
    overflow-y: hidden !important; /* Use !important to ensure it overrides Material UI styles */
    max-height: none !important;
    /* border-radius: 7px; */
    width: 332px;
    height: 340px;
    width: 100%;

    /* display: none; */
  }

  .resend-otp {
    position: absolute;
    top: 105%;
    left: 0%;
    margin-top: 4px;
    /* color:#0081df; */
    font-size: 10px;
    font-weight: 600;
    cursor: pointer;
  }

  .close-button {
    display: inline-flex;
    position: absolute;
    right: 3%;
    top: 2%;
    /* float: right; */
    border: none;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: black; /* Replace with your desired circle color */
    cursor: pointer;
    margin-top: 3%;
    margin-right: 3%;
  }

  /* .phone-login-popup{
    height: 100%;
    background-color: red;
  }
  .phone-login-signup {
    display: grid;
    grid-template-columns: 1fr 2fr;
    background-color: yellow;
    
  } */
  .login-img {
    display: none;
  }
  .phone-login-img > img {
    display: none;
  }

  .login-text {
    font-family: Manrope;
    margin-left: 10%;
    margin-top: 8%;
    position: relative;
  }

  .login-text img {
    margin-left: 15vw;
    width: 70%;
    aspect-ratio: 3.56;
  }
  .login-text > h1 {
    padding-left: 35%;
    margin-bottom: 20px;
    margin-top: 18px;
    color: #000;
    font-family: Manrope;
    font-size: 19px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .login-text > p {
    text-align: left;
    margin-bottom: 7px;
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .mobile-field {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-left: 8vw;
  }
  .cc > select {
    width: 47px;
    height: 30px;
    border: none;
    background-color: #f1f1f1;
    font-size: 14px;
    padding: 3px;
  }
  .country-code {
    width: 40px;
    height: 30px;
    border: none;
    background-color: #f1f1f1;
    border-radius: 3px;
    font-family: Manrope;
    font-size: 14px;
    padding: 3px;
    text-align: center;
  }
  /* Style for the custom select container */
  .custom-select {
    position: relative;
  }

  /* Style for the custom select button */
  .custom-select-button {
    font-family: Manrope;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding: 2px 5px;
    cursor: pointer;
    width: 48px;
    height: 39px;
    border: 0.7px solid #cfcfcf;
    background-color: #fff;
    border-radius: 3px;
  }

  /* Style for the custom dropdown */
  .custom-select-dropdown {
    position: absolute;
    top: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 197px;
    overflow-y: scroll;
    color: black;
    list-style: none;
    margin: 0; /* Add this to remove any default margin */
    padding: 0; /* Add this to remove any default padding */
    margin-top: 3px;
    padding-bottom: 0;
    max-width: 100%;
    /* overflow-x: hidden; */
  }

  /* Style for the custom dropdown items */
  .custom-select-dropdown li {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    font-family: "Manrope";
    font-size: 10px;
    font-weight: 400;
    padding: 4px 3px;
    cursor: pointer;
  }

  .mobile-no {
    width: 220px;
    height: 28px;
    border: 0.7px solid #cfcfcf;
    background-color: #fff;
    border-radius: 3px;
    font-family: Manrope;
    font-size: 16px;
    padding: 5px 12px;
    position: relative;
    font-weight: 600;
  }
  .mobile-no::placeholder {
    color: #a59f9f;
    font-weight: 600;
    font-size: 15px;
  }
  .l-active-button {
    background-color: #0081df;
    font-size: 14px;
    border-radius: 5px;
    color: white;
    padding: 8px 10px;
    border: none;
    margin-top: 15px;
    position: absolute;
    right: 0;
    align-items: center;
    box-shadow: none;
  }

  .l-disabled-button {
    font-size: 14px;
    border-radius: 5px;
    color: white;
    padding: 5px 10px;
    border: none;
    margin-top: 12px;
    background-color: #c1c1c1;
    cursor: not-allowed;
    position: absolute;
    right: 0;
    align-items: center;
    box-shadow: none;
  }
  .invalid-otp {
    position: absolute;
    top: 19%;
    left: 53%;
    color: #df0d00;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 3px;
  }
  .otp-text {
    font-family: Manrope;
    margin-top: 30px;
    margin-left: 8vw;
  }
  .otp-text > p {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 15px;
  }
  .otp-field {
    width: 275px;
    width: 96%;
    height: 28px;
    display: flex;
    justify-content: center;
    /* border: transparent; */
    border: 0.7px solid #c7c7c7;
    background-color: #fff;
    border-radius: 3px;
    font-family: Manrope;
    font-size: 14px;
    padding: 5px;
    text-align: center;
  }
  .invalid-box {
    border: 1px solid #df0d00;
  }
  .otp-input-field {
    height: 20px;
    border: none;
    outline: none;
    outline-color: transparent;
    background-color: #fff;
    font-family: Manrope;
    font-size: 20px;
    padding: 7px;
    text-align: center;
  }
  .otp-input-field:focus {
    outline: none;
  }
  .privacy-policy {
    margin-left: 4vw;
    text-align: center;
    justify-content: flex-start;
    gap: 0px;
  }
  .privacy-policy > svg {
    width: 17px;
  }
  .whatsapp-alerts {
    margin-left: 8vw;
  }
  .whatsapp-alerts > p {
    font-size: 12.5px;
    font-weight: 400;
  }
  .register-text {
    font-family: Manrope;
    /* padding: 10px 20px; */
    width: 172%;
    height: fit-content;
    margin-left: 15px;
    position: relative;

    /* padding-left:10px ; */
  }

  .register-text img {
    margin-left: 0vw;
    width: 75%;
    padding-left: 10%;
  }
  .register-text > h1 {
    padding-left: 37%;
    margin-bottom: 25px;
    margin-top: 15px;
    color: #000;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .register-text > p {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 10px;
  }
  .name-field {
    display: flex;
    gap: 10px;
  }
  .name-field > input {
    width: 150px;
    width: 100%;
    height: 37px;
    border-radius: 3px;
    font-size: 16px;
    border: 0.7px solid #c7c7c7;
    background-color: #fff;
    margin-bottom: 12px;
    /* padding-left: 10px; */
    /* padding-right: 10px; */
    color: black;
  }
  .name::placeholder,
  .name-register::placeholder {
    color: #a59f9f;
    font-weight: 600;
    font-size: 15px;
  }

  .second-field {
    width: 94%;
  }
  .second-field input {
    border: 0.7px solid #c7c7c7;
    background-color: #fff;
    width: 98%;
    margin-bottom: 12px;
    height: 37px;
  }
  .register-text > select {
    border: 0.7px solid #c7c7c7;
    background-color: #fff;
    font-size: 15px;
    padding: 0px;
    width: 200px;
    height: 42px;
    border-radius: 3px;
    color: black;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
  }
  .register-text option {
    padding: 10px;
    border-radius: 3px;
  }

  .register-text > input {
    width: 160px;
    width: 93%;
    height: 37px;
    border-radius: 3px;
    border: 0.7px solid #c7c7c7;
    background-color: #fff;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .dropdown-occ {
    width: 0%;
  }

  .dropdown-occ div {
    /* width: 100%; */
    border: 0.7px solid #c7c7c7;
    background-color: #fff;
    margin-bottom: 12px;
    height: 31.5px;
  }
  .dropdown-icon-occ {
    margin-top: 6%;
  }
  .r-active-button {
    background-color: #0081df;
    display: block;
    font-size: 16px;
    font-weight: 500;
    border-radius: 7px;
    color: white;
    padding: 8px 20px;
    border: none;
    margin-top: 20px;
    position: absolute;
    right: 9vw;
    box-shadow: none;
  }
  .r-active-button:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  .r-disabled-button {
    display: block;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
    color: white;
    padding: 8px 20px;
    border: none;
    margin-top: 20px;
    position: absolute;
    right: 9vw;
    background-color: #c1c1c1;
    cursor: not-allowed;
    box-shadow: none;
  }

  /* .full-screen-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  } */
  @keyframes slide-up {
    0% {
      top: 0;
    }
    100% {
      top: -200vh;
    }
  }
  @keyframes slide-down {
    0% {
      top: -200vh;
    }
    100% {
      top: 0;
    }
    
  }
  
}

