@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;800&display=swap');
.wrapper {
  margin: 0 1% 0 1%;
}
.imageslider-container{
  padding-top: 15%;
  padding-bottom:20%;
}
.recommendations{
  font-family: "Manrope", sans-serif;
  font-size: 25px;
  font-weight: 700;
}
.carousel-card {
  min-width: 200px;
  border:none;
  padding: 10px;
  margin-right:5px;

  
  position: relative;
}

.image-layer{
}
.pdp-product-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #CFCFCF;
  margin-bottom: 8px;
  cursor:pointer;
}
#pdp-product-name{
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  font-size:  18px;

  text-align: left;
}
#pdp-product-model{
  font-family: 'Manrope', sans-serif;

  margin-top:3%;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}
.carousel-card button {
  font-family: 'Manrope', sans-serif;
  border: none;
  outline: 0;
  padding: 10px 15px;
  color: white;
  background-color: #0081DF;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  border-radius: 4px;
}

.addToCart{
  font-family: 'Manrope', sans-serif;
  text-align: left;
}

.carousel-card button:hover {
  opacity: 0.7;
}
.control-btn .next,
.control-btn .prev {
  position: absolute;
  top: 40%;
  width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 50%;
  transform: 0.5s;
  /* z-index: 5; */
  border:none;
}

.wrapper-carousel-pdp{
  
  margin: 0px 3%;
}

.control-btn .prev {
  top: 50px;
  top: 23%;
  left: -30px;
}
.control-btn .next {
  top: 50px;
  top: 23%;
  right: -15px;
  
}
.control-btn .prev i,
.control-btn .next i {
  font-size: 20px;
  transition: 0.5s;
  color: #fff;
}
.control-btn .prev:hover,
.control-btn .next:hover {
  color: grey;
  cursor: pointer;
}

#pdp-product-name-mobile{
  display: none;
}

@media (max-width:768px) {

  .imageslider-container{
    padding:   1% 0% ;
    margin-left: 5px;
    
  }

  .carousel-card button{
    color: #FFF;
font-family: Manrope;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: normal;
border-radius:7px ;
padding: 8px 10px;
  }
  .addToCart{
    font-family: 'Manrope', sans-serif;
    text-align: left;
  }

  .wrapper-carousel-pdp{
    width: 90%;
    padding: 0px;
  
    margin: 0px 0px;
   

    padding: 0px;
  }

  .recommendations{
    font-size: 18px;
    padding-left: 0px;
    
    
  }
  #pdp-product-name{
    display: none;
  }
  
  #pdp-product-name-mobile{
    display: inline;
    font-size:  10px;
    text-align: left;
  }
  #pdp-product-model{
    font-family: 'Manrope', sans-serif;
  
    margin-top:5px;
    margin-bottom: none;
    font-size: 8px;
    font-weight: 500;
    text-align: left;
  }

  .carousel-card{
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 0px;
    margin-bottom: 0px;
    
    /* margin: 0px 5px 0px 5px; */
    margin-left: -10px;
    
 
    /* background-color: red; */
    /* margin-right: 15px; */
    
  }

  .image-layer{
    height: 10%;
    width: 100%;  
    margin-bottom: 10px;
  }
  
  .pdp-product-image{
     width: 55%;
    height: 100px;
    margin: 0px;
  }
  .control-btn .prev {
    left: -15px;
  }
  .control-btn .next {
    right: -15px;
    
  }

  
}