@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
.license-container {
  font-family: "Manrope", sans-serif;
  width:100%;
  padding-bottom: 1.5%;
}
.selected{
  padding-bottom: 4%;
}
.wrapper {
  margin: 0 7% 0 7%;
}
.license-heading {
  color: #A59F9F;
  font-size: 15px;
  margin-bottom: 2%;
}
.license-heading > span {
  margin-left: 5%;
  cursor: pointer;
  padding: 2% 5%;
  font-size: 19px;
}

.license-line {
  width: 100%;
  height: 0.5px;
  background-color: #6F6F6F;
}
.selected .license-line{
  background-color: #BEBEBE;
}

.license-content {
  color: black;
  display: flex;
  margin-top: 30px;
}

.license-title {
  font-size: 15px;
  margin-bottom: 5px;
  flex: 1;
}

.license-description {
  font-size: 15px;
  flex: 1.5;
}
