@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap");
.blog-landing-page {
  margin: 6% 9% 6%;
  font-family: Manrope;
}
.browse-section-blogs {
  display: flex;
  flex-direction: row;
  flex-wrap: none;
  gap: 1.3vw;
  /* background-color: red; */
  /* position: sticky;
  top: 4.8vw; */
  background-color: white;
  padding-bottom: 1.3vw;
  padding-top: 1.5vw;
  /* padding-right: 1vw; */
  z-index: 3;
}
.search-bar-blogs {
  border: none;
  border-radius: 7px;
  background-color: #f1f1f1;
  padding: 10px 15px;
  display: inline-flex;
  gap: 5px;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  width: 90vw;
}
.search-blogs {
  font-family: "Manrope";
  font-size: 15px;
  /* width: 90vw; */
  outline: 0;
  border: none;
  background: transparent;
  padding-left: 1vw;
  width: 100%;
}
.search-blogs::placeholder {
  color: #c4c4c4;
}
.category-blogs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  border: none;
  border-radius: 5px;
  background: rgba(0, 129, 223, 0.2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 0vw 1.5vw;
}
.category-blogs span {
  color: #000;
  font-family: Manrope;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.dropdown-blogs-icon {
  height: 1.2vw;
  width: 1.2vw;
}
.category-blogs:hover {
  cursor: pointer;
}
.category-list-blogs{
    font-family: Manrope;
    list-style: none;
    color:black;
    font-size: 15px;
    font-weight: 500;
    max-height: 25vw;
    overflow-y: scroll;
    margin-top: 3%;
    margin-bottom: 3%;
}
.category-list-blogs li{
  padding: 2% 6%;
  transition: all 0.2s;
  display: flex;
  align-items: flex-start;
}
.category-list-blogs li:hover{
  cursor: pointer;
  background-color: rgba(13, 153, 255, 0.20);
}
.category-list-blogs::-webkit-scrollbar {
  width: 5px;
}
.category-list-blogs::-webkit-scrollbar-thumb {
  background-color: #D9D9D9; /* Color of the thumb (the draggable part) */
  /* border-radius: 6px; */
}

.category-list-blogs::-webkit-scrollbar-track {
  background-color: transparent;
}

.blog-carousel {
  margin-left: 0.1%;
  width: 99.8%;
  /* aspect-ratio: 2.141; */
  height: 35vw;
  border-radius: 10px;
  /* position: relative; */
}
.blog-carousel-image{
    cursor: pointer;
}
.blog-carousel-image img {
  width: 100%;
  /* height: 100%; */
  /* aspect-ratio: 2.141; */
  height: 35vw;
  border-radius: 10px;
  object-fit: cover;
}
.gradient-overlay-blog {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0) 0%,
      rgba(0, 0, 0, 0.8) 91.67%
    );
    border-radius: 10px;
    opacity: 0.83;
  }
}
/* .slick-prev:before,
.slick-next:before {
  display: none;
} */
.custom-arrow-blog {
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.next-arrow-blogs {
  right: 1.5vw;
}

.prev-arrow-blogs {
  left: 1.5vw;
}
.swipe-icons-blogs{
    height: 26px;
    width: 25px;
}
.blog-carousel-text {
  position: absolute;
  z-index: 2;
  bottom: 4.5vw;
  left: 3vw;
}

.blog-carousel-text h1 {
  color: #fff;
  font-family: Manrope;
  font-size: 2.6vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1vw;
  margin-right: 4.5%;
  max-width: 92%;
}
.blog-carousel-text p {
  color: #fff;
  font-family: Manrope;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 65%;
  margin-bottom: 1.6vw;
}
.blog-carousel-text button{
    border:none;
    background-color: #FFD600;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6vw 1.6vw;
    font-weight: 700;
    font-size: 1.3vw;
    font-family: Manrope;
}
.blog-carousel-text button:hover{
    cursor: pointer;
}
.latest-blogs{
    font-size: 2.4vw;
    margin-top: 5%;
    font-weight: 700;
    margin-bottom: 2%;
}
.blog-cards-display{
    display: flex;
    /* gap: 1vw; */
    /* justify-content: space-between; */
    justify-content: flex-start;
    column-gap: 2.2vw;
    flex-wrap: wrap;
}

@media (max-width:768px){
  .blog-landing-page {
    margin: 18% 5% 16%;
    font-family: Manrope;
  }
  .browse-section-blogs {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: none;
    gap: 2vw;
    /* background-color: red; */
    /* position: sticky;
    top: 14.8vw; */
    background-color: white;
    padding-bottom: 3.5vw;
    padding-top: 1.5vw;
    z-index: 3;
  }
  .search-bar-blogs {
    border-radius: 5px;
    padding: 1.5vw 3vw 2vw;
    width: 68%;
  }
  .search-blogs {
    font-family: "Manrope";
    font-size: 15px;
    padding-left: 2vw;
    width: 100%;
  }
  .search-blogs:focus{
    outline: none;
  }
  .search-blogs::placeholder {
    color: #c4c4c4;
  }
  .category-blogs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.6vw;
    width: 27%;
    border: none;
    border-radius: 4px;
    background: rgba(0, 129, 223, 0.2);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 1.9vw 1.5vw ;
  }
  .category-blogs span {
    font-size: 3.3vw;
  }
  .dropdown-blogs-icon {
    margin-top: 1%;
    height: 2.5vw;
    width: 2.5vw;
  }
  .category-blogs:hover {
    cursor: pointer;
  }
  .categories-drawer > h5{
    padding-left: 8%;
    padding-top: 4%;
    font-weight: 800;
    font-size: 18px;

  }
  .close-filter-blog {
    position: absolute;
    top:2.2%;
    right: 5%;
    border: none;
    background-color: white;
  }
  .f-line-blogs{
    width: 100%;
    height: 0.5px;
    background-color: #e3e3e3;
    margin: 0;
    margin-top: 4%;
  }
  .checkbox-blogs{
    width: 15px;
    height: 15px;
  }
  .category-list-blogs{
      font-family: Manrope;
      list-style: none;
      color:black;
      font-size: 13.9px;
      font-weight: 500;
      max-height: 100%;
      overflow-y: auto;
      margin-top: 4%;
  }
  .category-list-blogs li{
      display: flex;
      align-items: center;
      padding: 1.9% 10% 1.9% 8%;
      transition: all 0.2s;
  }
  input[type="checkbox"]:checked {
    background-color: #007bff; /* Change to your desired background color */
    /* border: 0.5px solid #007bff;  */
  }
  
  /* This adds the checkmark icon inside the checkbox */
  input[type="checkbox"]:checked::before {
    content: "\2713"; /* Unicode checkmark character */
    display: block;
    text-align: center;
    line-height: 20px; /* Adjust based on checkbox height */
    color: white; /* Change to your desired checkmark color */
  }
  input[type="checkbox"]:focus{
    outline: none;
  }
  .category-list-blogs li:hover{
      cursor: pointer;
      background-color: rgba(13, 153, 255, 0.20);
  }
  .blog-carousel {
    width: 99.8%;
    /* aspect-ratio: 2.141; */
    height: 46vw;
    border-radius: 5px;
    margin-left: 0.1%;
    /* position: relative; */
  }
  .blog-carousel-image{
      cursor: pointer;
  }
  .blog-carousel-image img {
    width: 100%;
    height: 46vw;
    /* aspect-ratio: 2.141; */
    /* height: 35vw; */
    border-radius: 5px;
    object-fit: cover;
  }
  .gradient-overlay-blog {
  
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        180deg,
        rgba(217, 217, 217, 0) 0%,
        rgba(0, 0, 0, 0.8) 91.67%
      );
      border-radius: 5px;
      opacity: 0.95;
    }
  }
  /* .slick-prev:before,
  .slick-next:before {
    display: none;
  } */
  .custom-arrow-blog {
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
  
  .next-arrow {
    right: 1.5vw;
  }
  
  .prev-arrow {
    left: 1.5vw;
  }
  .swipe-icons-blogs{
      height: 12px;
      width: 13px;
  }
  .blog-carousel-text {
    position: absolute;
    z-index: 2;
    bottom: 5vw;
    left: 7vw;
  }
  
  .blog-carousel-text h1 {
    color: #fff;
    font-family: Manrope;
    font-size: 3.9vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1.5vw;
    max-width: 94%;
  }
  .blog-carousel-text p {
    color: #fff;
    font-family: Manrope;
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 75%;
    margin-bottom:2.6vw;
  }
  .blog-carousel-text button{
      border:none;
      background-color: #FFD600;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1vw 2vw;
      font-weight: 700;
      font-size: 2.6vw;
      font-family: Manrope;
  }
  .blog-carousel-text button:hover{
      cursor: pointer;
  }
  .latest-blogs{
      font-size: 4.5vw;
      margin-top: 6%;
      font-weight: 700;
      margin-bottom: 2%;
  }
  .blog-cards-display{
      display: flex;
      /* gap: 1vw; */
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;
  }
}
@media(min-width: 1500px){
  .blog-landing-page {
    margin: 6% 10.5% 6%;
    font-family: Manrope;
  }
}
@media(min-width: 1600px){
  .blog-landing-page {
    margin: 6% 11% 6%;
    font-family: Manrope;
  }
}
