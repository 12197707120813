@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
.wrapper-successCard{
  margin: 6% 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-card {
  background-color: white;
  font-family: "Manrope";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  width:60%;
  
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
}
.success-card > img {
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
}
.success-card > h3 {
  font-size: 26px;
  font-weight: 700;
  color: #0081DF;
  margin:15px 0px;
}
.success-card > p {
  font-size: 16px;
  font-weight: 400;
  color:#5F5F5F;
  margin-bottom: 20px;
  text-align: center;
}
.success-card .trackingID {
  font-size: 20px;
  font-weight: 700;
  color: #A59F9F;
}
.trackingID > span{
    color: #5F5F5F;
}
.track {
    background-color: #0081df;
    font-size: 16px;
    border-radius: 7px;
    color: white;
    padding: 10px 15px;
    border: none;
    margin-bottom: 30px;
  }
  .track:hover {
    cursor: pointer;
    opacity: 0.7;
  }


  .mobile-success-form{
    display: none;
  }

  @media (max-width:768px) {

    .success-card{
      display: none;
    }

    .enquiry-card{
      display: none;
    }

    .mobile-success-form{
      width: 75%;
      margin-top: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;
      padding: 5px 15px;
    }

    .mobile-success-form p{
      color: #000;
      text-align: center;
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    
  }