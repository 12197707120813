@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
.wrapper-pdp {
  margin: 0% 9% 0 9%;

 ;
}
.products-details-section {
  display: grid;
  grid-template-columns: 1fr;
 
}
.with-cart-pdp {
  display: grid;
  grid-template-columns: 2.5fr 1.2fr;
  column-gap: 2%;
}

.ProductDetails {
  display:grid;
  grid-template-columns: 1fr 1.3fr 1fr;
  column-gap: 3.5%;
  margin: 8% 0 5% 0;
  
}
.with-cart-pdp .ProductDetails {
  grid-template-columns: 1fr 1fr;
  margin: 9.5% 0 5% 0;
}
/* .with-cart-pdp .main-image-container{
  width:350px;
  height: 350px;
} */
.cart-section-pdp{
  position: relative;
  /* top:13.5%; */
}
.with-cart-pdp .cartPage-container{
  position: absolute;
  top:13.5%;
}
.with-cart-pdp .minimize-icon{
  display: none;
}
.image-section {
  display: flex;
  flex-direction: row;
  gap: 4%;
  justify-content: center;
  margin-left: 3%;
 
  
}
.image-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}
.single-img {
  transition: opacity 0.1s ease-in-out;
  height: 60px;
  width: 60px;
  object-fit: contain;
  border: #dfdfdf solid;
  border-width: 0.7px;
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
}
.main-image-container{
  border: 0.7px solid #DFDFDF;
  border-radius: 7px;
  width: 400px; 
  height: 400px; 
  overflow: hidden; 
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-image {
  width:100%;
  height: 300px;
  object-fit: contain;
}
.single-img:hover,
.single-img:focus{
  opacity: 0.7;
}
.carousel-container{

  border: 0.7px solid #DFDFDF;
  border-radius: 7px;
  width: 400px; 
  height: 400px; 
  overflow: hidden; 
  display: flex;
  justify-content: center;
  align-items: center;
}
.CarouselImage {
  width:100%;
  height: 300px;
}
.product_data {
  font-family: "Manrope", sans-serif;
  margin: 0;
  padding: 0;
}
.product-heading {
  font-size: 24px;
  margin-bottom: 2%;
}
.main-details > p{
  font-size: 18px;
  color: #6F6F6F;
  margin-bottom: 7%;
}
.main-details span {
  font-size: 24px;
}
.price_data  {
  font-size: 18px;
  color: #ababab;
  display: flex;
  gap: 40px;
  margin-bottom: 15px;
}

.original_price{

  color: #ababab;
  font-size: 20px;

}
.price_data sup {
  color: #dc3d0a;
  font-size: 15px;
}
.line {
  /* width: 100%; */
  height: 0.5px;
  background-color: #A59F9F;
  margin-bottom: 5%;
  /* margin-top: 10%; */
}

.payment-options {
  font-size: 16px;
  color: #ababab;
  margin-bottom: 25px;
}

.payment-options>p {
  font-size: 16px;
  color: #ababab;
  margin-bottom: 25px;
}
.discountedprice {
  color: #0081df;
  font-size: 20px;

}

.detailsBlock-3-1 {
  margin-top: 10%;
  margin-bottom: 10%;
  display: flex;
  gap:40px;
  align-items: center;
}

.detailsBlock-3-1-1 > button {
  font-size: 16px;
  border: none;
  background-color: rgba(0, 0, 0, 0.616);
  padding: 8px 10px;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
  width:3vmax;
}
.detailsBlock-3-1-1 > button:hover {
  background-color: rgba(0, 0, 0, 0.767);
}

.detailsBlock-3-1-1 > input {
  border: none;
  padding: 10px;
  width: 2vmax;
  text-align: center;
  outline: none;
  font: 600 14px ;
  color: black;
}
.btn {
  background-color: #F7F6F6;
  color:#0081df;
  font-size: 15px;
  font-weight: 600;
  border-radius: 7px;
  padding: 10px 15px;
  border: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* margin-bottom: 30px; */
}

.btn-added{
  background-color: #0081df;
  color: white; 
}
.btn-added{
  cursor: not-allowed;
}
.btn-notadded:hover {
  cursor: pointer;
  opacity: 0.7;
}
.features{
  margin-top: 7%;
  display: flex;
  flex-wrap: wrap;
  /* max-width: 60%; */
}
.features > span {
  border-radius: 5px;
  background-color: #f4f4f4;
  border: none;
  padding: 7px;
  margin-right: 15px;
  margin-bottom: 20px;
 
  font-size: 13px;
  /* white-space: nowrap; */
}
@media (max-width:1300px) {
  .with-cart-pdp .ProductDetails {
    grid-template-columns: 1fr 1fr;
    margin: 11% 0 5% 0;
  }
}

@media (max-width:768px) {


  .main-details > p{
    
    margin-bottom: 1%;
    color: #6F6F6F;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .product-heading {
    
    margin-bottom: 1%;
    color: #000;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }


  .main-image {
    width:100%;
    height: fit-content;
    object-fit: contain;
  }
  .cart-section-pdp{
    display: none;
  }

  .image-section {
    display: flex;
    flex-direction: row;
    gap: 4%;
    justify-content: center;
    margin-left: 0%;
    width: 90%;
    height: 50%;
    
  }

  .ProductDetails1{
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .main-image-container{
    border: 0.7px solid #DFDFDF;
    border-radius: 7px;
    width: 100%; 
    height: 30%; 
    overflow: hidden; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  .wrapper-pdp{
    width: 100%;
    margin: 0px;
    margin-left: 3%;
    margin-top: 20vw;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin-left: 0px;
    padding: 0px;
  }

  .wrapper-pdp .x{   
    width: 100%;
  }

  .wrapper-about{
    /* width: 100%; */
    padding-left: 2%;
    align-self: flex-start;
  }
  .products-details-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px;
    align-items: center;
  }

  .ProductDetails{
    display: flex;
    flex-direction: column;
    margin: 0%;
    align-items: center;
    
  }

  .product_data{
    
    padding-left: 0px;
    padding-top: 2vw;
    width: 90%;
  }


  
}