@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
.aboutus-container {
  display: flex;
  flex-direction: column;
  background-color: aqua;
  padding-top: 5vw;
  gap: 2vw;
  align-items: center;
  height: auto;
  background: linear-gradient(
      98deg,
      rgba(0, 90, 155, 0.7) 0.47%,
      rgba(0, 77, 88, 0.7) 95.9%
    ),
    url("../../images/aboutus.jpg");
  background-size: 100% 35vw;
  background-repeat: no-repeat;
  background-position: center start;
  margin-left: 4%;
  margin-right: 4%;
}

.about-us-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  max-width: 82%;
  width: 50%;
  margin-top: 7%;
  padding: 2%;
  /* background-color: green; */
}

.about-us-img h3 {
  color: #fff;
  text-align: center;
  /* color: red; */
  font-family: Manrope;
  font-size: 36px;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.about-us-img p {
  color: #fff;
  /* color: red; */
  text-align: center;
  font-family: Manrope;
  font-size: 24px;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.about-us-cards {
  display: flex;
  /* justify-content: s; */
  /* background-color: yellow; */
  justify-content: center;
  gap: 2vw;
  /* background-color: orange; */
}

.about-us-cards div {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  padding-top: 2vw;

  align-items: center;
  width: 25%;
  height: 12vw;
  border-radius: 3px;
  border: 0.3px solid #cfcfcf;
  background: #e9f6ff;
  box-shadow: 3px 1px 8px 0px rgba(0, 0, 0, 0.15);
}

.about-us-cards h3 {
  color: #000;
  font-family: Manrope;
  font-size: 36px;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.about-us-cards p {
  color: #0e2438;
  text-align: center;
  font-family: Manrope;
  font-size: 24px;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 70%;
  text-align: center;
}

.about-us-cards .about-card-one {
  background: linear-gradient(#e9f6ff, #e9f6ff 50%, #eedefd 50%, #eedefd);
  background-size: 100% 200%;
  transition: 0.09s;
}

.about-us-cards .about-card-two {
  background: linear-gradient(to right, #eedefd 50%, #e9f6ff 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.09s ease-out;
}

.about-us-cards .about-card-two:hover {
  background-position: left bottom;
}

.about-us-cards .about-card-one:hover {
  background-position: 100% 100%;
}

/* .about-us-cards div:hover h3,
.about-us-cards div:hover p {
    color: #fff; 
    transition: 0.1s;
} */

.about-us-customers {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;

  justify-content: space-evenly;
  margin-top: 4vw;
  margin-bottom: 4vw;
}
.about-us-customers h3 {
  color: #a59f9f;
  font-family: Manrope;
  font-size: 36px;
  font-size: 2vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.investors-logo {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.saison {
  width: 170px;
  height: 170px;
  width: 11.806vw;
  height: 10vw;
}

.beenext {
  width: 400px;
  height: 50px;

  width: 27.778vw;
  height: 3vw;
}

.titan {
  width: 11vw;
  height: 10vw;
  padding-top: 15px;
}
.portfolio{
   width:100%;
}
.portfolio-heading{
    display: flex;
    justify-content: center;
    align-items: center;
}
.portfolio-heading > p {
  color: #a59f9f;
  font-family: Manrope;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 5%;
}
.portfolio .portfolio-images{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20%;
}
.mamaearth{
   margin-left: 12%;
   width: 16.5vw;
   height: 2vw;
   margin-bottom: 4%;
}
.mpl{
    margin-left: 1%;
    width: 7vw;
    height: 7vw;
    margin-bottom: 4%;
}
.urbancompany{
    width: 13vw;
    height: 5vw;
    margin-bottom: 4%;
}
.park{
    margin-left: -2%;
    width: 10vw;
    height: 4vw;
    margin-bottom: 4.5%;
}
.milkbasket{
    margin-left: 2%;
    width: 14vw;
    height:5vw;
    margin-bottom: 4.5%;
}
.bharatpe{
    width: 12vw;
    height: 3vw;
    margin-bottom: 4.5%;
}
.housing{
    margin-top: 3%;
    margin-left: 13%;
    margin-bottom: 7%;
    width: 13.5vw;
    height: 2vw;
}
.ola{
    margin-left: 8%;
    margin-top: -3.5%;
    width: 8vw;
    height:3vw;
}


.flip-container{
  display: none;
}


@media (max-width:768px) {

  .investors-logo {
    margin-bottom: 4%;
  }

  .about-us-customers {
    margin-bottom: 12vw;
    
    height: auto;
  }

  .aboutus-container{

    display: flex;
    flex-direction: column;
    gap: 0vw;
    align-items: center;
    height: fit-content;  
    background-repeat: no-repeat;
    background-position: center start;
    margin-left: 0%;
    margin-right: 0%;
    background: none;
      padding: 0px;
    margin-top: 15vw;
    
  }

  .about-us-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4vw;
    max-width: 100%;
    width: 100%;
    margin-top: 0px;
    padding-top: 5vw;
    padding-bottom: 4vw;
    height: 50vw;
    
    background: linear-gradient(
        98deg,
        rgba(0, 90, 155, 0.7) 0.47%,
        rgba(0, 77, 88, 0.7) 95.9%
      ),
      url("../../images/aboutus.jpg");
    background-size: 100% 100%;
    padding-left: 0px;
    padding-right: 0px;
   
  }
  
  .about-us-img h3 {
    color: #FFF;
    text-align: center;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
   
  }
  
  .about-us-img p {
    color: #FFF;
    text-align: center;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 10vw;
    padding-right:10vw;
  }

  .about-us-cards {
   display: none;
  }

  .flip-container {
    perspective: 1000px;
    width: 100%;
    height: 200px;
    display: inline;

  }
  
  .flip-container .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
    
    height: 100%;
    width: 100%;
    
    justify-content: center;
  }
  
  .flip-container .front,
  .flip-container .back {
    backface-visibility: hidden;
    transition: 0.6s;
    transform-style: preserve-3d;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
  }
  
  .flip-container .front {
    background: #E9F6FF;
    box-shadow: 3px 1px 8px 0px rgba(0, 0, 0, 0.15);
    color: #0E2438;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 100%;
    
    
  }
  
  .flip-container .back {
    background: #E9F6FF;
    box-shadow: 3px 1px 8px 0px rgba(0, 0, 0, 0.15);
    transform: rotateY(-180deg);
    height: 100%;
    color: #0E2438;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 100%;
  }
  
  .flip-container.flip .flipper {
    transform: rotateY(180deg);
    width: 100%;
  }

  .front h3 , .back h3{
    color: #000;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 4vw;
  }
  
  .front p , .back p{
    color: #0E2438;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 65%;
  }

  .dots-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #ccc; /* Inactive dot color */
    margin: 0 5px;
    transition: background-color 0.3s ease;
  }
  
  .dot.active {
    background-color: black; /* Active dot color */
  }

  .portfolio{
    margin-bottom: 10%;
 }
  .about-us-customers h3 {
    color: #A59F9F;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

  }

  .portfolio-heading > p {
    line-height: normal;
    margin-top: 1vw;
    margin-bottom: 5%;

    color: #A59F9F;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .park{
    margin-left: 6%;
    width: 10vw;
    height: 4vw;
    margin-bottom: 4.5%;
}

.investors-logo a img{
  height: 3%;
  width: 70%;
}
.beenext{
  padding-left: 13%;
}

.saison{
  padding-left: 4%;
}

.portfolio .portfolio-images{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10%;
}

.mamaearth{
  margin-left: 6%;
  width: 20vw;
  height: 4vw;
  margin-bottom: 4%;
}
.mpl{
   margin-left: 0%;
   width: 14vw;
   height: 10vw;
   margin-bottom: 3%;
}
.urbancompany{
   width: 15vw;
   height: 7vw;
   margin-bottom: 4%;
}
.park{
   margin-left: -2%;
   width: 16vw;
   height: 6vw;
   margin-bottom: 4.5%;
}
.milkbasket{
   margin-left: 3%;
   margin-right: 0%;
   width: 22vw;
   height:10vw;
   margin-bottom: 4.5%;
}
.bharatpe{
   width: 20vw;
   height: 6vw;
   margin-bottom: 4.5%;
}
.housing{
   margin-top: 3%;
   margin-left: 3%;
   margin-bottom: 7%;
   width: 22vw;
   height: 3vw;
}
.ola{
   margin-left: 8%;
   margin-top: -3.5%;
   width: 18vw;
   height:5vw;
}

  
}