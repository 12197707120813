@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");

.terms-container {
  font-family: "Manrope", sans-serif;
  width:100%;
  padding-bottom: 5%;
}

.term-heading {
  color: #A59F9F;
  font-size: 15px;
  margin-bottom: 10px;
}
.term-heading > span {
  margin-left: 1.5%;
  cursor: pointer;
  padding: 2% 5%;
  font-size: 19px;
}
.terms-line {
  width: 100%;
  height: 0.5px;
  background-color: #bebebe;
}

.term-content {
  color: black;
  display: flex;
  margin-top: 30px;
}

.term-title {
  font-size: 15px;
  margin-bottom: 5px;
  flex: 1;
}

.term-description {
  font-size: 15px;
  flex: 1.5;
}
