/* CarouselComponent.css */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap");

.home-best-selling-machines {
  text-align: center;
  position: relative;
  /* top: 150px; */
  margin-top: 5%;
  background-color: #0081df;
  color: #fff;
  font-family: "Manrope";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 610px;
  height: auto;
  padding-top: 3%;
  padding-bottom: 4%;

}

.home-selling-machines{ 
  display: flex;
  
  margin-right: 8.8%;
  margin-left: 8.8%;
  margin-bottom: 4vw;
  display: flex;
  justify-content: space-between;
}

.home-selling-machines h3{
  color: #fff;
  /* margin-bottom: 40px; */
  font-family: Manrope;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

 .home-selling-machines-view-all {
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 0;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  align-self: flex-end;
}
.home-selling-machines-view-all:hover {
  border-bottom: 3px solid white;
}

.home-card-carousel {
  margin-left: 8.8%;
  margin-right: 8.8%;
}
.home-cards-carousel-container >div>div>div>div{
  margin-right: 1%;
  display: flex;
}
.home-card {
  border: 1px solid #ddd;
  /* height: 27vw; */
  height: 345px;
  margin: 7px;
  text-align: left;
  border-radius: 7px;
  background: #f7f6f6;
  padding: 1%;
}
.home-card > p {
  margin-bottom: 13px;
  /* margin-top: 6px; */
  background: #f7f6f6;
  padding-left: 7%;
}
.home-card > button {
  background-color: #f7f6f6;
  border: 0.7px solid;
  border-bottom: none;
  border-color: #c4c4c4;
  border-radius: 7px;
  cursor: pointer;
  width: 100%;
  color: #0d99ff;
  font-family: "Manrope-SemiBold", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 45px;
  width: 100%;
  position: relative;
  bottom: 0px;
  border-right: none;
  border-left: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.home-machine-image {
  width: 100%;
  height: 56.1%;
  object-fit: contain;
  background-color: white;
  border-radius: 7px;
}
.home-machine-name {
  color: #000;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.home-card > {
  margin-bottom: 15px;
  height: 19px;
}

.home-machine-model {
  color: #000;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.home-machine-location {
  color: #000;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}

.home-price {
  font-weight: bold;
}

/* .home-view-all-button-selling 
   { */



/* .home-selling-machines-view-all {
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 0;
  position: absolute;
  right: 126px;
  top: 70px;
  font-family: "Manrope";

  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.home-selling-machines-view-all:hover {
  border-bottom: 3px solid white;
}

.home-selling-machines>h3 {
  color: #fff;
  position: absolute;
  top: 45px;
  left: 9%;
  margin-bottom: 40px;
  font-family: Manrope;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
} */



.mobile-cards-home{
  display: none;
}
.swipe-right-icon{
  position: absolute;
  top:45%;
  right:-5px;
  z-index: 2000;
}
@media (min-width: 1350px) {
  .home-card-carousel {
    margin-left: 9%;
    margin-right: 9%;
  }
  .home-selling-machines-heading{
    margin-left: 9%;
  }
}
@media (min-width: 1500px) {
  .home-card-carousel {
    margin-left: 12.5%;
    margin-right: 12.5%;
  }
  .home-selling-machines-heading{
    margin-left: 10%;
  }
}

@media (max-width:768px) {

  .home-card-carousel {
    display: none; 
  }

  .home-best-selling-machines{  
    /* display: flex;
    flex-direction: column;
    
    height: auto;
    width: 100vw;
    padding: none;
    background-color: rgba(134, 154, 203, 0.30);
    margin: none;
    align-items: center;

    margin-top:0vw;
    padding: 5vw 1vw; */

    max-width: 100%;
    width: 100vw;
    background-color: rgba(134, 154, 203, 0.30);
    margin-top: 5%;  
    font-family: Manrope;
    margin-left: none;
    margin-right: none;
    width: 100%;
    padding: 0px;  
    margin-top: 5vw;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column; 
    align-items: center;
    padding-top: 4vw;
    padding-bottom: 4vw;
    
  }
  .home-selling-machines{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 95%;  
    
    
  }
  .home-selling-machines-view-all {
    color: #0081DF;
    font-family: Manrope;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
  }
  


  .home-selling-machines >h3 {
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  

  .mobile-cards-home{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    
  }
  .mobile-home-card{
    /* width: 50%; */
    border-radius: 5px;
    display: grid;
     grid-template-columns:0.8fr 1.2fr 1fr;
     grid-template-rows:1fr 1fr 1fr;
     margin-left: 10px;
    margin-right: 10px;
    background-color: white;
    margin-bottom: 7px;
    height: 109px;
    height: fit-content;
    place-items: center;
    width: 100%;
    row-gap: 0px;
    padding: 10px;
    padding-top: 5px;
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  }

  .mobile-home-machine-image{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 4;
    width: 100%;
    background-color: white;
    border-radius: 7px;
    height: 90%;
    object-fit: contain;
  }
  .mobile-home-machine-name{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    color: #000;
    align-content: center;
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    align-self:flex-end;
    position: absolute;
    left:0.4vw;
    white-space: nowrap;
    /* top:0; */

  }
  .mobile-home-machine-model{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    color: #000;
    font-family: Manrope;
    font-size: 10.5px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    align-self: end;
    position: absolute;
    left:0.5vw;

  }
  
.mobile-home-machine-location{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    color: #000;
    font-family: Manrope;
    font-size: 10.5px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    align-self: center;
    position: absolute;
    left:0.5vw;
}

.mobile-home-card button{
  grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;

    border-radius: 6px;
    background: #0081DF;
    color: #FFF;
    font-family: Manrope;
    font-size: 3vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    padding: 2vw 3.3vw;
    align-self: start;
}



}
