@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap");
.homepage {
  position: relative;
  margin-top: 7%;
}

/* image -carousel - image */

.img-carousel-img {
  /* margin-top: 3.69%; */
  margin-top: 3%;
  max-width: 82%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  gap: 15px;
  height: fit-content;
  position: relative;
  align-items: center;
  top: 10px;
}
.top-categories-container {
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  /* height: 100%; */
  aspect-ratio: 0.85;
  width: 23%;
  position: relative;
}
.top-categories-container > h3 {
  font-size: 20px;
  padding: 3.5% 8%;
  font-weight: 700;
}
.top-categories-container > ul {
  list-style: none;
  padding-top: 3%;
}
.top-categories-container li {
  padding: 3.1% 8%;
  font-size: 16px;
  font-weight: 500;
  position: relative;
}
.top-categories-container li:hover {
  cursor: pointer;
  background-color: #f2f7ff;
  color: #0081df;
}
.top-categories-container li .sidebar1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 0; /* Initially, no width */
  height: 100%; /* Occupies full height of the list item */
  background-color: #0081df;
  opacity: 0; /* Initially invisible */
  transition: width 0.1s, opacity 0.3s;
  border-radius: 0px 2px 2px 0px;
}
.top-categories-container li:hover .sidebar1 {
  width: 4px;
  opacity: 1;
}
.cat-link {
  position: absolute;
  right: 10%;
  margin-top: 2.5%;
}
.tc-line {
  width: 100%;
  height: 0.5px;
  background-color: #efefef;
}
.first-carousel-slider {
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 10px;
  gap: 0px;
}

.first-carousel-container {
  width: 51.91%;
  /* height: 100%; */
  aspect-ratio: 1.9337;
  background-color: white;
  border-radius: 10px;
}

.first-carousel-image {
  /* width: 100%; */
  /* height: 100%; */
  /* aspect-ratio: 1.9337; */
  /* display: flex;
  align-items: center; */
  /* justify-content: center; */
  background-color: white;
  border-radius: 10px;
  /* gap: 5px; */
}

.first-carousel-image > img {
  width: 100%;
  aspect-ratio: 1.9337;
  object-fit: fill;
  flex-shrink: 1;
  border-radius: 10px;
}

.custom-arrow {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.next-arrow {
  right: -4.3%;
  background-color: transparent;
}

.prev-arrow {
  left: -4.3%;
  background-color: transparent;
  z-index: 1;
}

.image-container-left,
.image-container-right {
  /* height: 100%; */
  aspect-ratio: 0.844;
  width: 22.8%;
  border-radius: 10px;
  margin-top: 0.3%;
}

.right-img {
  width: 100%;
  /* height: 100%; */
  aspect-ratio: 0.844;
  position: relative;
  object-fit: fill;
  border-radius: 10px;
}
.image-clickable{
  cursor: pointer;
}
.swipe-icons{
  height: 20px;
  width:20px;
}
/* Brand Carousel CSS*/
.brand-slider {
  margin: 0;
  padding: 0;
  /* margin-left: 8.88%;
  margin-right: 8.8%; */
  max-width: 82%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 38px;
  border: 1.5px solid rgb(235, 227, 227);
  border-radius: 10px;
  height: 130px;
  height: 8.5vw;
  position: relative;
  padding-top: 1vw;
  display: flex;
  align-items: center;
  padding-left: 0.8vw;
  padding-right: 0.2vw;
  background-color: transparent;
}
.brand-image-div {
  margin: 0;
  padding-top: 2px;
  border-color: none;
}
.brand-image {
  height: 6vw;
  width: 12.7vw;
  background-color: white;
  border-radius: 7px;
  padding: 1px 15px 1px 15px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
  margin-left: 5px;
  margin-right: 5px;
}
.slick-prev:before,
.slick-next:before {
  display: none;
}

/* why zoplar*/
.whyzoplar-cards {
  background-image: url("../../images/whyzoplar-bg.jpg");
  background-size: 100% 100%;
  margin-top: 25px;
  margin-left: 0;
  margin-right: 0;
  /* height: 80vh; */
  width: 100%;
  padding: 3.3% 0 1% 0;
}
.whyzoplar > h3 {
  margin-left: 9%;
  display: flex;
  flex-shrink: 1;
  justify-content: space-between;
  flex-basis: auto;
  color: #000;
  font-family: Manrope;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/*interactive card*/
.interactivecard {
  max-width: 82%;
  margin: 50px auto 40px auto;
  display: flex;
  justify-content: space-around;
  flex-shrink: 1;
  /* gap: 1.5%; */
  color: #000;
  font-family: Manrope;
}
.icard {
  width: 23.34%;
  /* width:25%; */
  aspect-ratio: 1.007;
  border: none;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  position: relative;
  margin-top: 2%;
  /* margin-left: 1%;
  margin-right: 1%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  /* transition: all 0.7s ease; */
}
.interactivecard .icard{
  transition: all 0.6s ease;
}
.interactivecard:hover > :not(:hover){
  transform: scale(0.97);
  filter: blur(1.5px);
}
.interactivecard:hover > :hover {
  transform: scale(1.1);
  box-shadow: 2px 2px 10px 10px rgba(0, 0, 0, 0.05);
}

.hovered-card:hover{
  transform: scale(1.1);
  box-shadow: 2px 2px 10px 10px rgba(0, 0, 0, 0.05);
}
.blurred-card{
  transform: scale(0.95); 
  filter: blur(1.5px); 
  /* animation: card-blur 0.01s linear 0s 1 forwards; */

}

.card-icon {
  margin-top: 10%;
  margin-bottom: 3%;
}

.card-heading {
  position: absolute;
  top: 35%;
  color: #4d6ba9;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4%;
  text-align: center;
}

.card-paragraph {
  position: absolute;
  top: 61%;
  margin: 0 9% 0 9%;
  color: #333333;
  text-align: center;
  font-family: Manrope;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  opacity: 0.9;
  line-height: 26px;
}

.icard .bottom-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 11px;
  border-radius: 0px 0px 7px 7px;
}



/* Define the keyframes for your animation */
/* @keyframes card-enter {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.07);
  }
}

@keyframes card-leave {
  from {
    transform: scale(1.07);
  }
  to {
    transform: scale(1);
  }
}
@keyframes card-blur {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(0.9);
  }
} */

/* Apply the animation to your interactive cards */
.icard-enter {
  animation: card-enter 0.3s ease;
}

.icard-leave {
  animation: card-leave 0.3s ease;
}

.mobileinteractivecard{
  display: none;
}

.phone-top-categories-container{
  display: none;
}
@media (min-width: 1350px) {
  .img-carousel-img,
  .brand-carousel,
  .interactivecard {
    max-width: 82.4%;
  }
}
@media (min-width: 1500px) {
  .img-carousel-img,
  .brand-slider,
  .interactivecard {
    max-width: 80%;
  }
  .whyzoplar > h3 {
    margin-left: 10%;
  }
  .card-paragraph {
    font-size: 18px;
    top: 63%;
  }
  .brand-image{
    width:12.5vw;
  }
}
@media (min-width: 1600px) {
  .img-carousel-img,
  .brand-slider,
  .interactivecard {
    max-width: 75%;
  }
  .card-paragraph {
    font-size: 18px;
    top: 63%;
  }
  .whyzoplar > h3 {
    margin-left: 12.5%;
  }
}
/* @media (max-width:1450px){
  .card-heading{
    font-size: 22px;
  }
  .card-paragraph{
    font-size: 18px;
  }
} */
@media (max-width: 1350px) {
  .card-icon {
    width: 50px;
    height: 51px;
  }
  .card-heading {
    font-size: 20px;
    top: 37%;
  }
  .card-paragraph {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 1199px) {
  .card-icon {
    width: 45px;
    height: 46px;
  }
  .card-heading {
    font-size: 17px;
  }
  .card-paragraph {
    font-size: 13px;
    line-height: 22px;
  }
}

@media (max-width:768px) {

  .homepage{
    margin-top: 15%;
  }

  .image-container-right{
    display: none !important;
  }

  .top-categories-container{
    display: none;
  }

  .brand-slider-div{
    display: none;
  }

  .whyzoplar-cards {
    display: none;
  }

  .phone-top-categories-container{
    display: flex;
    border-radius: 3px;
    background: #FAFAFA;
    width: 95vw;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px;
  
    
    

  }
  .phone-top-categories button{
    background-color: transparent;
    border: none;
    color: #525252;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .phone-top-categories button:hover{
    background-color: green;
  }

  
 
  .separator{
    stroke-width: 0.1px;
    stroke: #A59F9F;
    width: 0.1px;
    height: 8vw;
    background-color: #c5bfbf;
    
    
  }

  .img-carousel-img {
    
    max-width: 100%;
    width: 100vw;
    background-color:white;
    margin-top: 5%;  
    font-family: Manrope;
    margin-left: none;
    margin-right: none;
    width: 100%;
    padding: 0px;  
    margin-top: 5vw;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column; 
    align-items: center; 
 
  }
  
  .first-carousel-container {
    width: 95vw; 
  }

    
  .first-carousel-image > img {
    width: 100%;
    /* height: 34vh; */
  }

  .whyzoplar{
    display: none;
  }

  .mobileinteractivecard{
   
    display: flex;
    margin-top: 20px;
    align-items: center;
    /* padding-left: 10px; */
    margin-top: 12vw;

    
   
  }

  .mobileicard{
    width: 23%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 90%;
  }
  .mobileicard:last-child{
    width:27%;
  }

.mobilecard-heading{

color: #4D6BA9;
text-align: center;
font-family: Inter;
font-size: 3vw;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.mobilecard-icon svg{
  max-height: 22px;
  max-width: 22px;
  object-fit: contain;

}

  
  
}
