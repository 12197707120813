@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");

.footer-container {
  font-family: "Manrope";
  display: grid;
  background-color: #0081df;
  max-width: 100%;
  grid-template-columns: 10% 2fr 1fr 2fr 10%;
  padding-top: 5.5%;
  padding-bottom: 4%;
}
.aboutcompany {
  display: grid;
  grid-column: 2/3;
  justify-content: start;
  align-items: left;
}
.aboutcompany > img {
  width: 214px;
  height: 60px;
}
.address {
  color: white;
  font-size: 16px;
  font-weight: 100;
  white-space: nowrap;
  padding: 3%;
  margin-top: 6%;
}
.footer-links {
  margin-left: 20%;
}
.footer-links ul {
  list-style-type: none;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}
.footer-links li {
  margin-bottom: 7%;
  font-size: 16px;
  font-weight: 100;
}
.footer-links li a {
  color: white;
  transition: color 0.4s ease-out;
  text-decoration: none;
}
.footer-links li a:hover {
  text-decoration: none;
  color: rgba(198, 194, 194, 0.95);
  transition: 0.3s ease;
}
.social-links {
  margin-left: 20%;
  text-align: center;
}
.follow-us {
  color: white;
  font-size: 18px;
  font-weight: 100;
  white-space: nowrap;
  margin-bottom: 15px;
}
.icons {
  display: flex;
  justify-content: center;
  gap: 3%;
}
.icons > a {
  margin-right: 10px;
}
.icon-link {
  background-color: transparent;
  padding: 5px; /* Add some padding to the icons */
  border-radius: 50%; /* Make the icons circular by applying border-radius */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* @media (max-width: 768px) {
  .footer-container {
    grid-template-columns: 1fr; 
    text-align: center;
    padding-right: 5%;
  }
  .aboutcompany,
  .footer-links,
  .social-links {
    grid-column: auto; 
    margin: 0;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
  }
  .aboutcompany>img{
    margin: auto;
  }
  .address{
    text-align: center;
  }
  .footer-links li {
    margin-bottom: 1%;
    font-size: 16px;
  }
  .follow-us{
    display: flex;
    justify-content: center;
    padding-left: 8%;
  }
  .icons {
    justify-content: center;
    margin-left: 5%;
  }
} */


@media (max-width:768px) {
  
  .footer-container{
    display: none;
  }
  
}
.footer-copyright {
  grid-column: 2/5;
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: 100;
  margin-top: 20px;
}