@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");

.banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vmin;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
}

.banner > h1 {
  margin: 5vmax;

  font: 600 2.5vmax "Roboto";
}

.banner > p {
  font: 300 1.4vmax "Lucida Sans";
}

.banner > a > button {
  margin-bottom: 5vmax;
  cursor: pointer;
  background-color: white;
  border: 1px solid white;
  border-radius: 0;
  padding: 1vmax;
  transition: all 0.5s;
  width: 9vmax;
  font: 500 1vmax "Roboto";
}
.banner > a > button:hover {
  background-color: rgba(255, 255, 255, 0);
  color: white;
}

.banner::after {
  content: "";
  width: 100vw;
  height: 100vmin;
  background-color: #ffffff;
  position: absolute;
  top: 0%;
  left: 0;
  clip-path: polygon(100% 68%, 0 100%, 100% 100%);
  max-width: 100%;
}

.homeHeading {
  text-align: center;
  font-family: Roboto;
  font-size: 1.4vmax;
  border-bottom: 1px solid rgba(21, 21, 21, 0.5);
  width: 20vmax;
  padding: 1vmax;
  margin: 5vmax auto;
  color: rgb(0, 0, 0, 0.7);
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0px;
  justify-content: center;
  align-items: center;
}

.productCard {
  width: 22vmax;
  height: fit-content;
  padding: 0;
  margin-bottom: 1.5%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: rgba(15, 14, 14, 0.756);
  transition: all 0.4s;
  border-radius: 7px;
  border: #cfcfcf solid 0.5px;
  /* background-color: #fafafa; */
}
.with-cart-productCard {
  width: 20.8vmax;
  margin-bottom: 0%;
}
.productCard a {
  text-decoration: none;
}

.productCard:hover {
  box-shadow: 0 0 10px rgba(15, 15, 15, 0.26);
  /* transform: scale(1.01); */
}
.productimg-container {
  display: flex;
  justify-content: center;
  /* height: 220px; */
  height: 190px;
  margin:  3% 3.5%;
  /* width: 250px; */
  /* padding: 1%; */
  margin-bottom: 3%;
  border: #cfcfcf solid 0.5px;
  border-radius: 7px;
  position: relative;
}
.productimg-container > img {
  width: 100%;
  height: auto;
  /* object-fit: inherit; */
  object-fit: contain;
  border-radius: 7px;
}
.productimg-container > p {
  color: white;
  font-size: 12px;
  font-weight: 500;
  background-color: #0081df;
  border: none;
  border-radius: 13px;
  padding: 1.6% 4.2%;
  z-index: 1;
  position: absolute;
  right: 4%;
  top: 83%;
}
.myproduct-details {
  font-family: "Manrope", sans-serif;
  margin-left: 5%;
}
.myproduct-name {
  margin-bottom: 3%;
  font-size: 17px;
  font-weight: 800;
  color: black;
}
.myproduct-brand {
  font-size: 15px;
  font-weight: 500;
  color: #525252;
  margin-bottom: 4%;
}
.myproduct-brand > span {
  font-size: 14px;
  font-weight: 500;
  color: #a59f9f;
}
.myproduct-features{
  margin-bottom: 15px;
}

.myproduct-features > span {
  border-radius: 5px;
  background-color: #efefef;
  color: #6f6f6f;
  border: none;
  padding: 5px;
  margin-right: 7px;
  margin-bottom: 6%;
  font-size: 11px;
  /* white-space: nowrap; */
}
.myproduct-location {
  margin-top: 2%;
  display: flex;
  gap: 4%;
}
.myproduct-location > span {
  font-size: 13px;
  font-weight: 500;
  color: black;
}
.l-icon {
  margin-top: 5%;
}
.location-p {
  margin-top: 4%;
}
#myproduct-line {
  width: 100%;
  height: 0.5px;
  background-color: #cfcfcf;
  margin-top: 2%;
}
.rp-bg {
  display: grid;
  grid-template-columns: 1fr;
}
.request-price {
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #0081df;
  margin-top: 3.5%;
  margin-bottom: 3.5%;
  font-weight: 600;
  cursor: pointer;
}

.request-price:hover {
  color: #a59f9f;
  transition: 0.1s ease-in-out;
}
.added-to-cart {
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  border: none;
  background-color: #0081df;
  display: flex;
  justify-content: center;
  align-content: center;
  color: white;
  padding-top: 3%;
  padding-bottom: 3%;
  font-weight: 500;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.myproduct-features-mobile{
  display: none;
}
/* .quantity-button{
  font-family: Manrope;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  padding-top: 7px;
  padding-bottom: 6px;
}
.quantity-button>button{
  display: flex;
  justify-content: center;
  color:#0081DF;
  border:none;
  background-color: transparent;
  font-weight: 300;
  font-size: 24px;
  cursor: pointer;
}
.quantity-button>span{
  color:#0081DF;
  border:none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
} */


@media (min-width: 1450px) {
  .productCard {
    width: 21.3vmax;
  }
  .with-cart-productCard {
    width: 20vmax;
  }
}
@media (min-width: 1500px) {
  .productCard {
    width: 20.5vmax;
  }
  .with-cart-productCard {
    width: 19.5vmax;
  }
}
@media (min-width: 1600px) {
  .productCard {
    width: 17.7vmax;
  }
  .with-cart-productCard {
    width: 16vmax;
  }
}
@media (min-width: 1800px) {
  .productCard {
    width: 17.3vmax;
  }
  .with-cart-productCard {
    width: 16.2vmax;
  }
}
@media (max-width: 1230px) {
  .productCard {
    width: 21.8vmax;
  }
  .with-cart-productCard {
    width: 20vmax;
  }
}
@media (max-width: 1080px) {
  .productCard {
    width: 29vmax;
  }
  .with-cart-productCard {
    width: 28vmax;
  }
}

@media (max-width:768px) {

  .productCard{
   
    height: fit-content;
    padding-bottom: 0px;
    width: 96%;
  height: fit-content;
  padding: 0;
  margin-bottom: 1.5%;
 
  }

  /* .productCardContainer{
    
    padding: 2% 1%;
  } */
  .productimg-container{
    
    height: 45vw;
    margin: 4% ;
    
    /* width: 100px; */
  }

  .productimg-container img{
    width: 100%;
  }

  .myproduct-name{
    color: #000;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .myproduct-brand{
    font-size: 14px;
  }

  .myproduct-features > span {
    border-radius: 2px;
    background-color: #efefef;
    color: #6f6f6f;
    border: none;
    padding: 3px 4px;
    margin-right: 4px;
    margin-bottom: 5px;
    font-size: 9px;
  }

  .request-price {
    
    font-size: 13px;
    border: none;
    margin-top: 1%; 
     margin-bottom: 2%;
    font-weight: 700;
    padding: 5px 0px;
    cursor: pointer;
    /* background-color: pink; */
  }

  .added-to-cart {
    text-align: center;
    /* margin-top: 3.5%; 
     margin-bottom: 3.5%; */
    font-size: 13px;
    
    color: white;
    padding: 8px 0px;
    font-weight: 600;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

 
}













/* @media screen and (max-width: 600px) {
  .productCard > p {
    font-size: 1.7vmax;
  }

  .productCard > div {
    margin: 0vmax;
    display: block;
  }

  .productCard > span {
    font-size: 1.5vmax;
  }

  .productCard > div > span {
    margin: 0 0.5vmax;
    font: 300 1vmax "Roboto";
  }
} */
