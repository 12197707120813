a{
  text-decoration: none;
}
.blog-card {
  font-family: Manrope;
  width: 25.3vw;
  aspect-ratio: 0.68;
  padding: 0;
  /* margin-bottom: 1.5%; */
  display: flex;
  flex-direction: column;
  text-decoration: none;
  transition: all 0.4s;
  border-radius: 7px;
  border: #e7e7e7 solid 0.5px;
  background-color: #fcfcfc;
  margin-bottom: 2vw;
  position: relative;
}
.blog-card:hover {
  cursor: pointer;
  /* transform: scale(1.01); */
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
}
.blog-card-img-container {
  display: flex;
  justify-content: center;
  margin: 4.7% 4.6% 3.5%;
  height: 15vw;
}
.blog-card-img-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.blog-card-s1 {
  display: flex;
  margin-left: 4.8%;
  margin-right: 4.8%;
  position: relative;
  margin-top: 2%;
}
.blog-card-s1 p {
  color: #a59f9f;
  font-size: 1vw;
  margin-top: 1%
}
.blog-card-s1 span {
  position: absolute;
  right:0;
  align-self: flex-end;
  justify-items: flex-end;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bababa;
  font-size: 0.95vw;
  padding: 1px 5px;
  border: none;
  border-radius: 5px;
  font-weight: 300;
}
.blog-card h2{
    margin-left: 4.8%;
    margin-right: 4.8%;
    font-size: 1.5vw;
    margin-top: 3%;
    font-weight: 600;
}
.blog-content{
    margin-left: 4.8%;
    margin-right: 4.8%;
    color: #6F6F6F;
    font-size: 1.1vw;
    margin-top: 4%;
}
.blog-card-s2{
    display: flex;
    justify-content: flex-end;
    margin-right: 4.8%;
}
.read-more{
    position: absolute;
    bottom: 1.3vw;
    font-family: Manrope;
    background-color: #0D99FF;
    color: white;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1vw;
    font-weight: 400;
    border: none;
    padding: 2.5% 6%;
    /* margin-top: 6%; */
    transition: all 0.3s ease;

}
.read-more:hover{
    cursor: pointer;
    opacity: 0.7;
}
@media (max-width: 768px){
  .blog-card {
    width: 99.8%;
    aspect-ratio: 2.4;
    padding: 0;
    /* margin-bottom: 1.5%; */
    /* display: flex;
    flex-direction: row; */
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: 2.7vw;
    text-decoration: none;
    transition: all 0.4s;
    border-radius: 5px;
    border: #e7e7e7 solid 0.5px;
    background-color: #fcfcfc;
    margin-bottom: 2vw;
    position: relative;
  }
  .rec-card{
    display: flex;
    flex-direction: column;
    /* width: 42vw; */
    width: 96%;
    aspect-ratio: 0.79;
  }
  .rec-card .blog-card-img-container {
    display: flex;
    justify-content: center;
    margin: 4.5% 4.6%;
    width: 91%;
    height: 24vw;
  }
  .rec-card .blog-card-text{
    margin-top: 0;
  }
  .rec-card .blog-card-s1 {
    display: flex;
    margin-left: 4.8%;
    margin-right: 4.8%;
    position: relative;
    margin-top: 0%;
  }
  .rec-card .blog-card-s1 p {
    color: #a59f9f;
    font-size: 1.2vw;
    margin-top: 0%
  }
  .rec-card .blog-card-s1 span {
    position: absolute;
    top: 0%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bababa;
    font-size: 1.2vw;
    padding: 1.5px 2px 1px;
    border: none;
    border-radius: 2px;
    font-weight: 300;
  }
  .rec-card h2{
      margin-left: 4.8%;
      font-size: 1.5vw;
      margin-top: 0%;
      font-weight: 600;
  }
  .rec-card .blog-content{
      margin-left: 4.8%;
      margin-right: 4.8%;
      color: #6F6F6F;
      font-size: 1.1vw;
      margin-top: 2%;
  }
  .rec-card .blog-card-s2{
      display: flex;
      justify-content: flex-end;
      margin-right: 4.8%;
  }
  .rec-card .read-more{
      position: absolute;
      bottom: 1.3vw;
      font-family: Manrope;
      background-color: #0D99FF;
      color: white;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1vw;
      font-weight: 400;
      border: none;
      padding: 2.5% 6%;
      /* margin-top: 6%; */
      transition: all 0.3s ease;
  
  }
  .rec-card .read-more:hover{
      cursor: pointer;
      opacity: 0.7;
  }
  .blog-card:hover {
    cursor: pointer;
    /* transform: scale(1.01); */
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
  .blog-card-img-container {
    display: flex;
    justify-content: center;
    margin: 7%;
    /* height: 16vw; */
    width: 100%;
    height: 86%;
  }
  .blog-card-img-container > img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
  .blog-card-text{
    margin-top: 3%;
  }
  .blog-card-s1 {
    display: flex;
    margin-left: 4.8%;
    margin-right: 4.8%;
    position: relative;
    margin-top: 2%;
  }
  .blog-card-s1 p {
    color: #a59f9f;
    position: absolute;
    right: 0;
    margin-top: 0%;
    font-size: 2.5vw;

  }
  .blog-card-s1 span {
    position: absolute;
    left:0;
    right: 70%;
    top:18%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bababa;
    font-size: 2.5vw;
    padding: 0.7px 1px;
    border: none;
    border-radius: 2px;
    font-weight: 300;
  }
  .blog-card h2{
      margin-left: 4.8%;
      font-size: 3.7vw;
      margin-top: 10.5%;
      font-weight: 600;
  }
  .blog-content{
      margin-left: 4.8%;
      margin-right: 4.8%;
      color: #6F6F6F;
      font-size: 2.6vw;
      margin-top: 2%;
  }
  .blog-card-s2{
      display: flex;
      justify-content: flex-end;
      margin-right: 4.8%;
  }
  .read-more{
      position: absolute;
      bottom: 2.4vw;
      font-family: Manrope;
      background-color: #0D99FF;
      color: white;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.85vw;
      font-weight: 400;
      border: none;
      padding: 1% 3.5%;
      /* margin-top: 6%; */
      transition: all 0.3s ease;
  
  }
  .read-more:hover{
      cursor: pointer;
      opacity: 0.7;
  }
  .rec-card h2{
    margin-left: 4.8%;
    font-size: 2.3vw;
    margin-top: 8%;
    font-weight: 600;
}
.rec-card .blog-content{
    margin-left: 4.8%;
    margin-right: 4.8%;
    color: #6F6F6F;
    font-size: 1.5vw;
    margin-top: 2%;
}
.rec-card .blog-card-s2{
    display: flex;
    justify-content: flex-end;
    margin-right: 4.8%;
}
.rec-card .read-more{
  position: absolute;
  bottom: 2.4vw;
  font-family: Manrope;
  background-color: #0D99FF;
  color: white;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2vw;
  font-weight: 400;
  border: none;
  padding: 1.6% 4%;
  /* margin-top: 6%; */
  transition: all 0.3s ease;

}
.rec-card .read-more:hover{
    cursor: pointer;
    opacity: 0.7;
}
}