@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");

.wrapper-about {
  margin: 0 1% 0 1%;
}

.prodinfo-container {
  font-family: "Manrope", sans-serif;
}

.prodinfo-heading {
  color: black;
  font-size: 18px;
  font-weight: 700;
  margin-bottom:30px;
}

.about-line {
  width: 30%;
  height: 0.5px;
  background-color: #bebebe;
  margin-bottom: 3%;
}

/* .prodinfo-content {
  color: black;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap:0;
  margin-top:0%;
  margin-bottom: 30px;
}

.prodinfo-title {
  font-size: 15px;
  margin-bottom: 5px;
} */
/* 
.prodinfo-description {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 1.4%;
} */
.product-specs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: repeat(1, 1fr);
  grid-auto-rows: minmax(auto, 1fr);
  gap:5%;
  margin-bottom: 5%; */
  grid-template-rows: repeat(auto-fit, minmax(30px,1fr));
  column-gap: 7%;
  margin-bottom: 6%;
}
.spec {
  display: flex;
  margin-bottom: 10px;
  font-size: 15px;
}
.s-name {
  font-weight: 400;
  flex: 1;
  margin-bottom: 2%;
}
.s-desc {
  flex: 1;
}


@media (max-width:768px) {

  .prodinfo-container {
    font-family: "Manrope", sans-serif;
    width: 90%;
  }

  .prodinfo-heading {
    color: black;
    font-size: 18px;
    font-weight: 700;
    margin-bottom:3vw;
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .product-specs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto-fit, minmax(30px,1fr));
    column-gap: auto;
    margin-bottom: 6%;
  }

  .spec {
    display: flex;
  
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .s-name {
    
    margin-bottom: 2%;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  
}