.blog-details-page {
  margin: 6% 9% 6%;
  font-family: Manrope;
}
.blog-box {
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.5% 10%;
}
.blog-heading {
  max-width: 90%;
}
.blog-heading > h1 {
  text-align: center;
  font-size: 2.6vw;
}
.blog-socials {
  border: 1px solid #d0d0d0;
  border-left: none;
  border-right: none;
  margin-top: 2%;
  padding: 1.3% 1%;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 3%;
  position: relative;
}
.blog-socials a{
  display: flex;
  align-items: center;
}
.blog-icons {
  height: 1.7vw;
  width: 1.6vw;
  cursor: pointer;
}
.share-icon{
  height:1.7vw;
  width: 1.4vw;
}
.socials-text {
  position: absolute;
  /* right: 14.7%; */
  right: 6%;
  font-size: 1.1vw;
}
.like-count {
  position: absolute;
  right: 1%;
  color: #0d99ff;
  font-size: 1.2vw;
}
.like-icon {
  position: absolute;
  /* right: 9.3%; */
  right: 1%;
  height: 1.7vw;
  width: 1.7vw;
}
.blog-post-img-container {
  width: 90%;
  /* height: 30vw; */
  aspect-ratio: 1.84;
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 7px;
}
.blog-post-img-container > img {
  width: 100%;
  aspect-ratio: 1.84;
  /* height: 90%; */
  /* aspect-ratio: 1.84; */
  object-fit: cover;
  border-radius: 7px;
}
.blog-post-img-container > span {
  margin-top: 1.5%;
  font-size: 1.1vw;
  font-weight: 400;
}
.blog-content-main {
  width: 90%;
  margin-top: 5%;
  font-size: 1.2vw;
  font-weight: 600;
}
.blog-subsections {
  margin-top: 4%;
}
.blog-subsections h2 {
  margin-bottom: 2%;
  font-weight: 600;
}
.blog-subsections p {
  font-weight: 500;
  margin-bottom: 4%;
}
.form-blog h1 {
  font-size: 2.1vw;
  margin-top: 5%;
  font-weight: 700;
  margin-bottom: 0.5%;
}
.basic-form-blog {
  /* margin-top: 5%; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 2fr;
  column-gap: 2%;
  row-gap: 3%;
  height: auto;
  width: 80%;
  /* padding-bottom: 2.222vw; */
}

.basic-form-blog div {
  display: flex;
  justify-content: flex-end;
  grid-column-start: 2;
  grid-column-end: 3;
}

.basic-form-blog p {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.86px;
}

.form-name-blog,
.form-city-blog {
  border: 0.7px solid #c7c7c7;
  border-radius: 3px;
  font-size: 14px;
  padding: 2% 2%;
  margin-top: 2%;
  background-color: transparent;
}

.form-name-blog {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  height: 0.5vw;
}

.form-name-blog:focus,
.form-city-blog:focus {
  outline: solid 1.5px #0081df;
  border: none;
}

.mobile-number2-blog {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  height: 3.2vw;
  border: 0.7px solid #c7c7c7;
  border-radius: 3px;
  font-size: 14px;
  margin-top: 2%;
  background-color: transparent;
  padding-left: 4%;
}
.form-email-blog {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  height: 3.2vw;
  border: 0.7px solid #c7c7c7;
  border-radius: 3px;
  font-size: 14px;
  padding-left: 4%;
  margin-top: 2%;
  background-color: transparent;
}
.form-city-blog {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
  margin-top: 1%;
  height: 0.5vw;
}

.mobile-number2-blog::placeholder,
.form-email-blog::placeholder {
  color: #bababa;
  font-family: Manrope;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  /* padding-left: 3%; */
}

.form-name-blog::placeholder,
.form-city-blog::placeholder {
  color: #bababa;
  font-family: Manrope;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  /* padding-right: 3%; */
}
.mobile-number2-blog:focus,
.form-email-blog:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.blog-info {
  border: 0.7px solid #c7c7c7;
  border-radius: 3px;
  /* padding:1.5% 2%; */
  font-family: Manrope;
  resize: none;
  font-weight: 500;
  font-size: 15px;
  margin-top: 1%;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 5;
  height: 100px;
  height: 6vw;
  background-color: transparent;
  padding: 1% 2%;
}
.blog-info::placeholder {
  color: #bababa;

  font-family: Manrope;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}
.blog-info:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.blog-active-sell {
  background-color: #0081df;
  font-size: 20px;
  border-radius: 4px;
  font-weight: 500;
  color: white;
  padding: 7px 25px;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-self: flex-end;
  cursor: pointer;
}
.active-blog:hover {
  opacity: 0.7;
  cursor: pointer;
}

.blog-disabled-sell {
  background-color: #e3e3e3;
  font-size: 20px;
  font-weight: 500;
  border-radius: 4px;
  color: white;
  padding: 7px 30px;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-self: flex-end;
  cursor: not-allowed;
}
.blog-recs h1 {
  font-size: 2.1vw;
  margin-top: 5%;
  font-weight: 700;
  margin-bottom: 2.5%;
}

.disclaimer {
    width: 93%;
  border-radius: 12px;
  border: 1px solid #e7e7e7;
  padding: 2.5% 2.5%;
  margin-top: 4%;
  margin-bottom: 2%;
}
.disclaimer p{
    font-weight: 400;
    font-size: 1.15vw;
}
.disclaimer span{
    font-weight: 700;
}
@media (max-width:768px){
  .blog-details-page {
    margin: 15% 4% 18%;
    font-family: Manrope;
  }
  .blog-box {
    border-radius: 5px;
    border: 1px solid #e7e7e7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3.8% 2%;
    padding-bottom: 0;
    margin-top: 2%;
  }
  .blog-heading {
    max-width: 100%;
  }
  .blog-heading > h1 {
    text-align: center;
    font-size: 5.3vw;
    font-weight: 700;
  }
  .blog-socials {
    border: 0.5px solid #d0d0d0;
    border-left: none;
    border-right: none;
    margin-top: 4%;
    padding: 2.2% 1%;
    width: 92%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4%;
    position: relative;
  }
  .blog-socials a{
    display: flex;
    align-items: center;
  }
  .blog-icons {
    height: 4vw;
    width: 4.3vw;
    cursor: pointer;
    
  }
  .socials-text {
    position: absolute;
    /* right: 20%; */
    right: 9%;
    font-size: 2.8vw;
  }
  .like-count {
    position: absolute;
    right: 1%;
    color: #0d99ff;
    font-size: 2.2vw;
  }
  .like-icon {
    position: absolute;
    /* right: 12.1%; */
    right:1%;
    height: 4vw;
    width: 4.2vw;
  }
  .blog-post-img-container {
    width: 94%;
    /* height: 30vw; */
    aspect-ratio: 1.84;
    margin-top: 4%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 5px;
  }
  .blog-post-img-container > img {
    width: 100%;
    aspect-ratio: 1.84;
    border-radius: 5px;
  }
  .blog-post-img-container > span {
    margin-top: 1.5%;
    font-size: 2.7vw;
    font-weight: 400;
  }
  .blog-content-main {
    width: 94%;
    margin-top: 4%;
    font-size: 3.1vw;
    font-weight: 600;
    line-height: 4.8vw;
  }
  .blog-subsections {
    margin-top: 5%;
  }
  .blog-subsections h2 {
    margin-bottom: 2%;
    font-weight: 600;
    font-size: 4.3vw;
  }
  .blog-subsections p {
    font-size: 3.05vw;
    font-weight: 500;
    margin-bottom: 4%;
    line-height: 4.8vw;
  }
  .form-blog h1 {
    font-size: 5.3vw;
    margin-top: 5%;
    font-weight: 700;
    margin-bottom: 1%;
  }
  .basic-form-blog {
    /* margin-top: 5%; */
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 2fr;
    column-gap: 2%;
    row-gap: 3%;
    height: auto;
    width: 80%; */
    /* padding-bottom: 2.222vw; */
    display: flex;
    flex-direction: column;
    row-gap: 2%;
    width:100%;
  }
  
  .basic-form-blog div {
    display: flex;
    justify-content: flex-end;
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .basic-form-blog input{
    border: 0.5px solid #cfcfcf;
  }
  .basic-form-blog p {
    font-size: 16px;
    font-weight: 500;
    line-height: 21.86px;
  }
  
  .form-name-blog,
  .form-city-blog {
    border: 0.5px solid #cfcfcf;
    border-radius: 2px;
    font-size: 13px;
    padding: 3.5% 3%;
    margin-top: 2%;
    background-color: transparent;
  }
  
  .form-name-blog {
    height: 2vw;
  }
  
  .mobile-number2-blog {
    height: 7vw;
    border: 0.7px solid #c7c7c7;
    border-radius: 2px;
    font-size: 12px;
    margin-top: 3%;
    background-color: transparent;
    /* padding-left: 3%;
    padding-bottom: 0.5%; */
    padding: 1% 3% 1%;
  }
  .form-email-blog {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    height: 7vw;
    border: 0.7px solid #c7c7c7;
    border-radius: 2px;
    font-size: 13px;
    padding-left: 3%;
    margin-top: 3%;
    padding-bottom: 0.5%;
    background-color: transparent;
    padding: 1% 3% 1%;
  }
  .form-city-blog {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    margin-top: 3%;
    height: 2vw;
  }
  
  .mobile-number2-blog::placeholder,
  .form-email-blog::placeholder {
    color: #bababa;
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    /* padding-left: 3%; */
  }
  
  .form-name-blog::placeholder,
  .form-city-blog::placeholder {
    color: #bababa;
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    /* padding-right: 3%; */
  }
  .form-name-blog:focus,
  .form-city-blog:focus {
    outline: solid 0.5px #0081df;
    border: none;
  }

  .mobile-number2-blog:focus,
  .form-email-blog:focus {
    outline: solid 1.5px #0081df;
    border: none;
  }
  .blog-info {
    border: 0.7px solid #D0D0D0;
    border-radius: 2px;
    /* padding:1.5% 2%; */
    font-family: Manrope;
    resize: none;
    font-weight: 500;
    font-size: 13px;
    margin-top: 3%;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
    height: 100px;
    height: 15vw;
    background-color: transparent;
    padding: 1% 2%;
  }
  .blog-info::placeholder {
    color: #bababa;
  
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
  }
  .blog-info:focus {
    outline: solid 1.5px #0081df;
    border: none;
  }
  .blog-active-sell {
    background-color: #549B45;
    font-size: 4vw;
    border-radius: 5px;
    font-weight: 500;
    color: white;
    padding: 6px 20px;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* align-self: flex-end; */
    cursor: pointer;
    margin-top: 2%;
  }
  .active-blog:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  
  .blog-disabled-sell {
    background-color: #e3e3e3;
    font-size: 4vw;
    font-weight: 500;
    border-radius: 5px;
    color: white;
    padding: 6px 20px;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* align-self: flex-start; */
    cursor: not-allowed;
    margin-top: 2%;
  }
  .blog-recs h1 {
    font-size: 4vw;
    margin-top: 5%;
    font-weight: 700;
    margin-bottom: 2%;
  }
  .rec-panel-cont{
    display: flex;
    justify-content: center;
  }
  .rec-panel{
    width: 94%;
  }
  /* .rec-panel > div > div > div > div{
    width:100%;
  } */
  .disclaimer {
    width: 94%;
    border-radius: 9px;
    border: 1px solid #e7e7e7;
    padding: 3% 4%;
    margin-top: 4%;
    margin-bottom: 2%;
  }
  .disclaimer p{
      font-weight: 400;
      font-size: 2.7vw;
  }
  .disclaimer span{
      font-weight: 700;
      font-size: 2.9vw;
  }
}