@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
.infobar-service {
  font-family: Manrope;
  background-color: #0081df;
  border-radius: 7px;
  border: none;
  padding: 3.5% 1%;
  display: flex;
  gap: 7.5%;
  justify-content: center;
  align-items: center;
  
}
/* .infobar-section-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.infobar-section-service > p {
  font-size: 15px;
  font-weight: 500;
  color: white;
  text-align: center;
  position: relative;
  top: 0%;
} */
.service {
  margin: 6% 5% 2% 5%;
  padding-top: 10px;
  font-family: Manrope;
  display: grid;
  grid-template-columns: 0.95fr 1fr;
  column-gap: 2.3%;
  position: relative;
}
.service-img {
  height: fit-content;
}
.service-img > div {
  display: flex;
  justify-content: center;
}
.service-img img {
  width: 60%;
  margin-top: 3%;
  aspect-ratio: 0.8707;
}
.service-img > h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 3%;
}
.service-img > p {
  font-size: 16px;
  font-weight: 500;
  /* margin-bottom: 4.5%; */
  font-family: 'Manrope';
}

.service-img > p >span{
  color:#0D89E4;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Manrope';

}



.service-form {
  background-image: url("../../images/bg-service.jpeg");
  background-size: 100% 100%;
  border: none;
  border-radius: 7px;
  padding: 2.5% 5%;
  margin-top: 2%;
  height: fit-content;
  position: relative;
}
.phone-error{
  font-size: 0.8vw;
  font-weight: 500;
  margin-top: 1%;
  color: #df0d00;
  position: absolute;
  top:35%;
  z-index: 2;
}
.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0%;
}
.basic-form-service > div > div {
  margin-bottom: 6%;
  width: 94%;
}
.basic-form-service input {
  border: none;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  width: 90%;
  padding: 3.5% 6%;
  margin-top: 2%;
}
.basic-form-service .form-name::placeholder {
  color: #868686;
  font-weight: 500;
}
.input-container .form-name::placeholder{
  color: #868686;
  font-weight: 600;
  opacity: 0.98;
}
.name-container {
  margin-bottom: 3%;
  width: 100%;
}
.name-container .placeholder {
  left: 2.9%;
  top: 40%;
}
.input-container {
  position: relative;
}
.input-container input:valid + .placeholder {
  display: none;
}
.placeholder {
  position: absolute;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  pointer-events: none;
  top: 33%;
  left: 6%;
  margin: auto;
  color: #868686;
}
.placeholder span {
  color: #df0d00;
}
.form-name:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.basic-form-service .mobile-number::placeholder {
  color: #868686;
  font-weight: 500;
}
.mobile-number:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.service-info {
  border: none;
  border-radius: 3px;
  width: 92%;
  font-size: 14px;
  padding: 1.5% 3%;
  margin-top: 1.5%;
  font-family: Manrope;
  resize: none;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 2%;
}
.service-form .service-info::placeholder {
  color: #868686;
  font-weight: 600;
  font-size: 14px;
  opacity: 0.99;
}
.service-info:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.active-service {
  background-color: #0081df;
  font-size: 17px;
  font-weight: 500;
  border-radius: 7px;
  color: white;
  padding: 1.5% 6%;
  border: none;
  /* position: absolute;
    right: 4%;
    top: 89%; */
  margin-left: 75.5%;
  margin-bottom: 2%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.active-service:hover {
  opacity: 0.7;
  cursor: pointer;
}
.disabled-service {
  /* background-color: #0081df; */
  background-color: #C4C4C4;
  cursor: not-allowed;
  font-size: 17px;
  font-weight: 500;
  border-radius: 7px;
  color: white;
  padding: 2% 6%;
  border: none;
  margin-left: 75.5%;
  margin-bottom: 2%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.phone-service-form{
  display: none;
}
@media (min-width: 1500px) {
  .service {
    margin: 6% 8% 2% 8%;
  }
}
@media (max-width: 1300px) {
  .service-img > p {
    font-size: 15px;
  }
}

@media (max-width:768px) {
  .service{
    margin-left:0;
    margin-right: 0;
  }
  .service-img{
    margin-left: 5%;
    margin-right: 5%;
  }

  .basic-form-service-mobile div{
    position: relative;
    display: flex;
    justify-content: flex-start;
    /* margin-top:10px ; */
    align-items: center;
    width: 87%;
}


  .mobile-placeholder-name{
    color: #BABABA;
    position: absolute;
    top: 4.5vw;
    left: 12px;
    color: #6F6F6F;

    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    pointer-events: none;
    
  }

  
  .mobile-placeholder-name:after{
    content: '*';
    color: red
  }
  
  
  .form-name-service:valid + .mobile-placeholder-name { 
    display: none;
  }
  .mobile-placeholder-mobile{
    color: #BABABA;
    position: absolute;
    top: 4.5vw;
    left: 12px;
    color: #6F6F6F;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    pointer-events: none;

  }
  .mobile-placeholder-mobile:after{
    content: '*';
    color: red
  }
  
  
  .mobile-number-service:valid + .mobile-placeholder-mobile { 
    display: none;
  }

  .mobile-placeholder-city{
    color: #BABABA;
    position: absolute;
    top: 4.5vw;
    left: 12px;
    color: #6F6F6F;

    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    pointer-events: none;

  }
  .mobile-placeholder-city:after{
    content: '*';
    color: red
  }
  
  
  .form-city-service:valid + .mobile-placeholder-city{ 
    display: none;
  }
 
  .service-img div {
    display: none;
  }

  .service{
    display: flex;
    flex-direction: column;
    margin: none;
    padding-top: 10vw;
    padding-bottom:12vw;
  }

  .service-img{
    display: flex;
    flex-direction: column;
  }
  .service-img h3{
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .service-img p{
    color: #000;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
    
  }

  .service-img > p >span{
    color:#0D89E4;
    font-size: 12px;
    font-weight: 700;
    font-family: 'Manrope';
  
  }

  /* @media (max-width :768px) {

     .infobar-service{
        padding: none;
        justify-content: space-around;
        margin-top: 2.8%;
        margin-bottom: 1%;
    }

    .infobar-section {
      display: flex;
      flex-direction: column;
      
      gap: 1px;
      align-items: center;
  }

     .infobar-section-service{
        display: flex;
        flex-direction: column;
        
        gap: 1px;
        align-items: center;
    }

    .infobar-section-service > p{
        color: #FFF;
        text-align: center;
        color: red;
        font-family: Manrope;
        font-size: 10px;
        font-style: normal;
        font-weight: 550;
        line-height: normal;
       
    }

    .infobar-section .bar-component {
        width: 25px; 
        height:25px; 
    }
} */

  .service-form {
    display: none;
  }

  .phone-service-form{
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-top: 3vw;
    /* width: 100%; */
    height: fit-content;
    padding-bottom: 10px;
    border-radius: 15px 15px 0px 0px;
    border: 1px solid #E7E7E7;
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.15);
    padding-left: 1vw;
    padding-right: 1vw;
    margin-left: 1vw;
    margin-right: 1vw;
  }

  .phone-service-form h4{
    align-self: flex-start;
    margin-left: 6vw;
    margin-bottom: 3vw;
    margin-top: 4vw;
    font-size: 14px;
    
  }

  
  .basic-form-service-mobile{
        
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 6px;
    align-items: center;
}



.basic-form-service-mobile input , .dropdown-type div  {
  
    border-radius: 5px;
    padding:4% 3%;
    margin-top: 2%;
    background-color: transparent;
    color: black;
    width: 100%;
    font-family: Manrope;
    resize: none;
    font-weight: 500;
    font-size: 15px;
    height: 10px;
    border: 0.7px solid #C7C7C7;
    
}
 .basic-form-service-mobile textarea{
    border: 0.7px solid #CFCFCF;
    margin-top: 2.5%;
    height: 100px; 
    background-color: transparent;
    padding: 1.5% 2%;
    width: 82%;
    font-family: Manrope; 
    font-weight: 500;
    font-size: 15px;
    color: black;
    border-radius: 7px;

}
.form-email-service::placeholder , .form-company-service::placeholder , .LYP-info::placeholder{

  color: #6F6F6F;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

input:focus , textarea:focus{
    outline: solid 2px #0081DF;
    border:none;
}
.active-service-mobile{
  background-color: #0081df;
  font-size: 16px;
  font-weight: 600;
  border-radius: 7px;
  color: white;
  padding: 7px 25px;
  border: none;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.25);
  align-self: flex-end;
}
.active-service-mobile:hover{
    opacity: 0.7;
    cursor: pointer;
}

.disabled-service-mobile{
  background-color: #E3E3E3;
    font-size: 16px;
    font-weight: 600;
    border-radius: 7px;
    color: #A59F9F;
    padding: 7px 25px;
    border: none;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.25);
    align-self: flex-end;
    margin-bottom: 2vw;
}



  
}


