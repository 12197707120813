@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
.wrapper-qt {
  margin: 6% 5%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1%;
  font-family: Manrope;
}
a {
  text-decoration: none;
  color: black;
}
.profileBox {
  width: 100%;
  border: #e3e3e3 solid 0.5px;
  border-radius: 7px;
  margin-right: 5px;
  font-family: "Manrope", sans-serif;
  position: relative;
  height: fit-content;
  margin-bottom: 2%;
}
.profile {
  padding: 5%;
  padding-bottom: 4%;
  display: flex;
  gap: 4%;
  justify-content: flex-start;
  position: relative;
  padding-left: 7%;
}
.profile-name {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}
.f-line {
  width: 100%;
  height: 0.5px;
  background-color: #e3e3e3;
  margin: 0;
}

.profileBox-menu ul {
  list-style: none;
  padding: 0;
}

.profileBox-menu li {
  position: relative;
  display: flex;
  gap: 10%;
  font-weight: 500;
  font-size: 15px;
  padding: 4% 0px 3% 7%;
}
.profileBox-menu li:hover {
  background-color: #f2f7ff;
  color: #0081df;
  cursor: pointer;
}

.default-selected-item {
  background-color: #f2f7ff;
  color: #0081df;
  cursor: pointer;
}
.profileBox-menu .default-selected-item .sidebar1 {
  width: 4px; /* Width when not hovered, change to your desired width */
  opacity: 1; /* Make it visible always */
}

.profileBox-menu li .sidebar1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 0; /* Initially, no width */
  height: 100%; /* Occupies full height of the list item */
  background-color: #0081df;
  opacity: 0; /* Initially invisible */
  transition: width 0.1s, opacity 0.3s;
}
/* li:hover .sidebar1 {
  width: 4px; 
  opacity: 1; 
} */

.logout-btn {
  display: flex;
  font-family: Manrope;
  width: 100%;
  gap: 10%;
  font-weight: 500;
  font-size: 15px;
  padding: 4%;
  background-color: white;
  border: #e3e3e3 solid 0.5px;
  border-radius: 5px;
  padding-left: 7%;
  color: #d20000;
  padding-bottom: 6px;
  cursor: pointer;
}
.logout-btn:hover {
  background-color: #e3e3e3;
}
.tracking-details {
  width: 100%;
  border: #e3e3e3 solid 0.5px;
  border-radius: 10px;
}

.tracking-details > h4 {
  font-family: Manrope;
  font-size: 19px;
  font-weight: 700;
  padding: 15px 0px 12px 25px;
}
.tab-headings {
  display: flex;
  gap: 0px;
  margin-left: 3%;
  /* margin-bottom: 15px; */
}
.tab-headings > p {
  display: flex;
  gap: 10px;
  font-size: 15px;
  font-weight: 500;
  padding: 0.7% 0% 0.7% 1.3%;
  border: 0.7px solid #e3e3e3;
  border-radius: 7px 7px 0px 0px;
  margin-top: 2.5%;
  cursor: pointer;
}
.tab-headings .selected {
  border-color: #a3a3a3;
  box-shadow: 0px 1px 2px 1px #e3e3e3;
}
.tab-num {
  border: none;
  background-color: #f2f2f2;
  color: #a59f9f;
  font-size: 15px;
  border-radius: 5px;
  padding: 0px 8px;
  margin-right: 10px;
}
.enquiry-status-cards {
  padding-bottom: 2%;
}
.enquiry-status-cards > h4 {
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  padding: 15px 0px 20px 0px;
  margin-left: 3.2%;
}
.enquiry-cards-container {
  width: 95%;
  padding: 0;
  max-height: 370px;
  overflow-y: auto;
}
.enquiry-cards-container::-webkit-scrollbar {
  width: 6px;
}

.enquiry-cards-container::-webkit-scrollbar-thumb {
  background-color: #c4c4c4; /* Color of the thumb (the draggable part) */
  border-radius: 6px;
}
.enquiry-cards-container::-webkit-scrollbar-track {
  /* background-color: transparent; */
  background: #d9d9d9;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  background-clip: padding-box;
}

.tab-card {
  position: relative;
  width: 94%;
  margin-left: 25px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 4.5fr 2.4fr 1fr;
  column-gap: 4%;
  font-size: 15px;
  border-radius: 7px;
  border: 0.7px solid #e3e3e3;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}

.sidebar-tab {
  position: absolute;
  left: 0;
  top: 0;
  width: 7px;
  height: 100%; /* Occupies full height of the list item */
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.enquiry-details {
  white-space: nowrap;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 25px;
  font-size: 15px;
  color: #a59f9f;
  font-weight: 500;
}
.enquiry-details > p {
  margin-bottom: 15px;
}
.enq-id {
  color: #0081df;
}
.date,
.time {
  color: black;
}
.time {
  margin-left: 2px;
}
.enq-status {
  border: 0.5px solid #a59f9f;
  padding: 1px 7px;
  border-radius: 15px;
  font-size: 12px;
}
.enquired-products {
  margin-left: 0;
  padding: 0;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 15px;
  color: #a59f9f;
  font-weight: 500;
}
.view-details {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.view-details-btn {
  background-color: #0081df;
  font-size: 15px;
  font-weight: 500;
  border-radius: 7px;
  color: white;
  padding: 7px 15px;
  border: none;
  white-space: nowrap;
  transition: all 0.3s ease;
}
.view-details-btn:hover {
  cursor: pointer;
  opacity: 0.7;
}
.enqCard-pending .sidebar-tab {
  background-color: #e87f7f;
}
.enqCard-pending .enq-status {
  border: 0.5px solid #e87f7f;
  padding: 1px 7px;
  border-radius: 15px;
  font-size: 12px;
  margin-left: 5%;
  color: #e87f7f;
}
.enqCard-confirmed .sidebar-tab {
  background-color: #259f07;
}
/* .enqCard-confirmed .date{
  color:#CFCFCF;
}
.enqCard-confirmed .time{
  color:#CFCFCF;
}
.enqCard-confirmed .enq-id{
  color:#CFCFCF;
} */
.enqCard-confirmed .enq-status {
  border: 0.5px solid #259f07;
  color: #259f07;
  padding: 1px 7px;
  border-radius: 15px;
  font-size: 12px;
  margin-left: 5%;
}
.enqCard-available .sidebar-tab {
  background-color: #0081df;
}
.enqCard-available .enq-status {
  border: 0.5px solid #0081df;
  color: #0081df;
  padding: 1px 7px;
  border-radius: 15px;
  font-size: 12px;
  margin-left: 5%;
}
.expanded-card {
  margin-bottom: 2%;
  padding-bottom: 1%;
  position: relative;
  font-family: Manrope;
  width: 94%;
  margin-left: 25px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  border-radius: 7px;
  border: 0.7px solid #e3e3e3;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}
.expanded-card .sidebar-tab {
  background-color: #259f07;
}
.expanded-card .enquiry-details {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5%;
  padding-bottom: 0; */
  padding-bottom: 3px;
  /* display: flex; */
}
.expanded-card .enq-status {
  border: none;
  color: white;
  padding: 2px 12px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 200;
  margin-left: 20px;
}
.item-summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 27px;
  gap: 17%;
}
#item-summ {
  font-weight: 600;
}

.item-summ-table {
  font-size: 15px;
  font-weight: 500;
  width: 83%;
  padding: 2% 5% 0% 5%;
  border: 0.7px solid #e3e3e3;
  border-radius: 7px;
  margin-top: 1.5%;
  display: flex;
  flex-direction: column;
  margin-left: 27px;
  margin-bottom: 1%;
  max-height: 110px;
  overflow-y: auto;
}
.item-summ-table::-webkit-scrollbar {
  width: 7px;
}

.item-summ-table::-webkit-scrollbar-thumb {
  background-color: #c4c4c4; /* Color of the thumb (the draggable part) */
  border-radius: 6px;
}
.item-summ-table::-webkit-scrollbar-track {
  background-color: transparent;
}
.item-summ-table > p {
  margin: 0;
  padding: 0;
}
.entry {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  column-gap: 3%;
  margin-bottom: 15px;
}
.entry-name {
  color: #0081df;
  font-weight: 600;
}
.entry-model {
  color: #a59f9f;
  font-weight: 400;
  font-size: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5%;
}

.entry-quantity {
  color: #a59f9f;
  margin-left: 5%;
}
.entry-quantity > span {
  color: #525252;
}
.expEnqCard-pending .sidebar-tab {
  background-color: #e87f7f;
}
.expEnqCard-pending .enq-status {
  background-color: #e87f7f;
}
.expEnqCard-confirmed .sidebar-tab {
  background-color: #259f07;
}
.expEnqCard-confirmed .enq-status {
  background-color: #259f07;
}
.expEnqCard-available .sidebar-tab {
  background-color: #0081df;
}
.expEnqCard-available .enq-status {
  background-color: #0081df;
}
.confirm-order {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
  /* height: 40px; */
}
.confirm-order-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #259f07;
  font-size: 14px;
  font-weight: 500;
  border-radius: 7px;
  color: white;
  padding: 8px 15px;
  border: none;
  white-space: nowrap;
  margin-bottom: 0.5%;
  margin-right: 3%;
  transition: all 0.3s ease;
}

.confirm-order-btn:hover {
  cursor: pointer;
  opacity: 0.7;
}
.download-quot {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #0081df;
  font-size: 14px;
  font-weight: 500;
  border-radius: 7px;
  color: white;
  padding: 8px 15px;
  border: none;
  white-space: nowrap;
  /* margin-bottom: 3%; */
  /* margin-right: 3.5%; */
  transition: all 0.3s ease;
}
.download-quot:hover {
  cursor: pointer;
  opacity: 0.7;
}
.download-quot-conf {
  position: relative;
  right: 12%;
  margin-bottom: 2%;
}
.confirm-dialog {
  position: relative;
}
.close-button-confirm {
  position: absolute;
  display: inline-flex;
  top: 5%;
  right: 5%;
  /* position: relative; */
  /* top: 10%; */
  /* float: right; */
  border: none;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: black; /* Replace with your desired circle color */
  cursor: pointer;
}
.close-button:hover {
  background-color: grey;
  transition: 0.2s ease-in-out;
}
.confirm-order-card {
  background-color: white;
  font-family: "Manrope";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.confirm-order-card > img {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}
.confirm-order-card > h3 {
  font-size: 24px;
  font-weight: 700;
  color: #0081df;
  margin: 15px 0px;
  white-space: nowrap;
  padding-left: 30px;
}
.confirm-order-card > p {
  font-size: 18px;
  font-weight: 400;
  color: #5f5f5f;
  margin-bottom: 20px;
  text-align: center;
}
.view-less {
  position: absolute;
  top: 20px;
  right: 3%;
  display: inline-flex;
  justify-content: flex-end;
}
.view-less-btn {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  border-radius: 7px;
  color: #0081df;
  padding: 6px 10px;
  border: 0.7px solid #0081df;
  white-space: nowrap;
  transition: all 0.3s ease;
}
.view-less-btn:hover {
  background-color: #0081df;
  color: white;
  /* opacity: 0.5; */
  cursor: pointer;
}

.enquired-products-mobile {
  display: none;
}
.confirm-order-card-mobile {
  display: none;
}

@media (min-width: 1500px) {
  .wrapper-qt {
    margin: 6% 8%;
  }
}
@media (min-width: 1300px) {
  .wrapper-qt {
    margin: 6% 7%;
  }
}
@media (max-width: 768px) {
  .confirm-order-card {
    display: none;
  }
  .MuiDialogContent-root {
    height: 150px;
    position: relative;
  }
  .close-button-confirm {
    /* position: absolute; */
    float: right;
    display: inline-flex;
    /* top: 30px;
    right: 20px; */
    /* position: relative; */
    /* top: 10%; */
    /* float: right; */
    border: none;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: black; /* Replace with your desired circle color */
    cursor: pointer;
  }
  .confirm-order-card-mobile {
    /* width: 75%; */

    margin-top: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 5px 15px;
  }

  .confirm-order-card-mobile p {
    color: #000;
    text-align: center;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .enquiry-status-cards > h4 {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    padding: 15px 0px 10px 1%;
    margin-left: 0%;
  }

  .enquiry-status-cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0px;
    padding-left: 2%;
  }

  .enquired-products {
    display: none;
  }

  .left-section {
    display: none;
  }
  .wrapper-qt {
    grid-template-columns: 1fr;
    margin: 15% 0%;
    /* margin-left: 5px; */

    font-family: Manrope;
    width: 100vw;
    padding: 0px;
    margin: 0px;
    margin-top: 18vw;
    align-items: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .tracking-details {
    width: 100%;
    padding: 0px;
    margin: 0px;
    height: auto;
    width: 96%;
    justify-self: center;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tracking-details > h4 {
    padding: 15px 0px 12px 0px;
    /* padding: 0px;  */
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-self: flex-start;
    padding-left: 1%;
  }

  .profileBox {
    margin-bottom: 5%;
  }

  .profile {
    padding-left: 5%;
  }

  .profileBox-menu li {
    padding: 4% 0; /* Adjust padding for list items */
  }

  .tab-headings {
    margin-left: 0; /* Center tab headings */
    flex-direction: row; /* Stack tab headings vertically */
    /* gap: 5px; */
    justify-content: center;
    
    width: 98.5%;
  }

  .tab-headings > p {
    /* margin-bottom: 5%; */
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    width: 25%;
    padding: 1vw 1.5vw;
    gap: 3.5px;
    border-radius: 4px 4px 0 0;
    margin-top: 2%;
  }
  .tab-headings > p:first-child{
    gap: 8px;
  }
  .tab-num{
    
  font-size: 11px;
  border-radius: 3px;
  padding: 0px 6px;
  margin-right: 0px;
  height: 4.3vw;
  }

  .expanded-card .enq-status {
    border: none;
    color: white;
    padding: 1% 3%;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 200;
    margin: none;
    position: absolute;
    right: 4%;
  }
  .enquiry-cards-container {
    max-height: none;
    /* padding: 0px 10px 0px 10px; */
    width: 100%;
    padding-left: 0%;
    width: 98%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 0px;
    overflow-x: hidden;
  }

  .enqCard-pending .enq-status {
    border: 0.5px solid #e87f7f;
    padding: 1px 7px;
    border-radius: 15px;
    font-size: 12px;
    /* margin-left: 5%; */
    position: absolute;
    right: 5%;
    color: #e87f7f;
  }

  .tab-card {
    position: relative;
    width: 99%;
    margin-left: 0px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2px;
    font-size: 10px;
    border-radius: 7px;
    border: 0.7px solid #e3e3e3;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    /* display: flex; */
  }
  .f-line {
    display: none;
  }
  .expanded-card {
    width: 100%;
    margin: 0px;
    padding-left: 8px;
    margin-bottom: 8px;
  }

  .enquiry-details {
    width: 60%;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 12px;
    color: #a59f9f;
    font-weight: 500;
    /* padding-left: 15px 0px; */
  }
  .enquiry-details > p {
    margin-bottom: 15px;
    font-size: 12px;
  }

  .enq-status {
    margin-left: 2%; /* Adjust margin for status */
    position: absolute;
    right: 5%;
  }

  .enqCard-available .enq-status {
    padding: 1% 2%;
    position: absolute;
    right: 5%;
  }

  .enquired-products-mobile {
    display: inline;
    /* padding-top: 15px; */
  }

  .item-summ-table {
    margin-left: 15px; /* Adjust margin for item summary table */
  }

  .confirm-order {
    /* position: absolute;
    left: 7%;
    bottom: 0%; */
    justify-content: center;
    margin-top: 10px;
    margin-right: 5px;
    margin: 3% auto 0.7% 7%;
    align-self: flex-start;
  }
  .view-details {
    position: absolute;
    right: 4.5%;
    padding: 0px;
    align-self: flex-end;
    margin-bottom: 20px;
    margin-left: 26px;
    height: fit-content;
    padding: 0px;
  }

  .confirm-order-btn,
  .download-quot,
  .view-details-btn,
  .view-less-btn {
    width: 100%;
    font-size: 10px;
    padding: 7px;
  }

  .view-less {
    /* margin-top: 45px;
    margin-right: 20px; */
    right: 5%;
    top: 25%;
  }

  .confirm-order-btn {
    margin-bottom: 10px; /* Add space between buttons */
  }

  .confirm-dialog {
    padding: 5%; /* Add padding for confirmation dialog */
  }

  .close-button-confirm {
    top: 2%; /* Adjust close button position */
    right: 2%; /* Adjust close button position */
  }

  .entry-name,
  .entry-model,
  .entry-quantity {
    font-size: 10px;
  }
}
