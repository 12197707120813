@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");

.pheader {
  display: none;
}

@media screen and (max-width: 768px) {
  /* .name-visible {
    width: 70px;
  } */
  .pheader {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    margin-right: none;
    width: 100vw;
    position: fixed;
    top: 0px;
    z-index: 5;
    background-color: white;
  }
  .divider {
    height: 1px; /* Adjust the height of the line as needed */
    background-color: white;
    margin: 10px 0; /* Add some margin for spacing */
  }

  .mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 6.2%;
    padding-top: 2%;
  }

  .mobile-header-logo {
    padding-left: 3vw;
    padding-top: 2.5vw;
  }

  .mobile-header-logo > a img {
    width: 31.628vw;
    /* height: 8.837vw; */
    aspect-ratio: 3.578;
  }

  .menu-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }

  .login-button {
    font-family: Manrope;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    background-color: #0081df;
    border-radius: 5px;
    border: none;
    padding: 4.8px 8px 5.5px 8px;
    font-size: 12.5px;
    font-weight: 400;
    cursor: pointer;
    /* position: relative; */
    transition: all 0.2s ease;
    height: fit-content;
    position: absolute;
    right: 19%;
    top: 31%;
    min-width: 22vw;
    max-width: 29vw;
    width: -webkit-min-content; /* For older webkits */
    width: min-content;
    
  }

  .profile-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .profile-name {
    margin-right: 10px;
  }

  .logout-button div {
    margin-left: 10px;
    color: #df0d00;
    font-family: Manrope;
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .menu-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
  }

  .menu-options {
    padding: 0px 0px;
    text-align: left;
    cursor: pointer;

    color: transparent; /* Set the text color to white for all menu options */
  }
  .dropdown-header ul {
    /* width:100%; */
    background-color: white;
    border: 1px solid #ece9e9;
    border-radius: 5px;
    list-style: none;
    position: absolute;
    right: 25px;
    top: 55px;
    width: 50vw;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 1;
  }
  .dropdown-header li {
    color: black;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 6.5% 7% 0% 7.5%;
    position: relative;
  }
  .dropdown-header li:last-child {
    padding-bottom: 6%;
  }
  .phonecatlink {
    position: absolute;
    right: 8px;
    top: 4.1vw;
  }
  .menu-options:hover {
    background-color: white;
    color: white;
  }
  .MuiButtonBase-root {
    position: relative;
  }

  /* Style the icons within the dropdown */
  /* .menu-options .MuiSvgIcon-root {
    margin-right: 10px; 
    position: absolute;
    right: 1%;
    font-size: 24px;
    color: grey; 
  } */
  .phone-cat-link {
    position: absolute;
    right: 0;
  }
  .profile-bubble-phone {
    position: absolute;
    top: 86%;
  }
  .bubble-phone {
    position: relative;
  }
  .pb-name-phone {
    position: absolute;
    color: black;
    top: 17%;
    left: 0;
    right: 0;
    text-align: center;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* white-space: nowrap; */
  }
  .pb-num-phone {
    position: absolute;
    top: 36.5%;
    left: 20%;
    right: 20%;
    color: #6f6f6f;
    text-align: center;
    font-family: Manrope;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .pb-logout-phone {
    position: absolute;
    top: 54.5%;
    left: 15%;
    right: 15%;
    display: flex;
    padding: 0.6vw 0.7vw 0.8vw 0.7vw;
    justify-content: center;
    align-items: center;
    border:none;
    border-radius: 3px;
    font-size: 10px;
    background-color: #D20000;
    color:white;
    font-weight: 600;
  }
}
