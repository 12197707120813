@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
.Finance {
  margin: 6% 5% 2% 5%;
  padding-top: 10px;
  font-family: Manrope;
  display: grid;
  grid-template-columns: 0.95fr 1fr;
  column-gap: 1%;
  position: relative;
}
.Finance-img {
  height: fit-content;
}
.Finance-img > div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1%;
}
.Finance-img img {
  width: 70%;
  aspect-ratio: 1;
}
.Finance-img > p {
  text-align: center;
  font-weight: 500;
}
.finance-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
}
.finance-card {
  width: 36%;
  aspect-ratio: 1.622;
  border: none;
  border-radius: 7px 7px 0px 0px;
  background: #fcfcfc;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.finance-card:hover {
  transform: scale(1.01);
}
.finance-card > p {
  font-size: 20px;
  font-weight: 700;
  margin: 0 10% 0 12%;
}
.finance-card-bottom {
  background-color: #8ea4d1;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.Finance-text > h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 2%;
}
.Finance-text > p {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 3.5%;
  line-height: 21.86px;
}
.Finance-form {
  background-image: url("../../images/bg-finance.jpeg");
  background-size: 100% 100%;
  border: none;
  border-radius: 7px;
  padding: 2.5% 5%;
  margin-top: 2%;
  height: fit-content;
  position: relative;
}
.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0%;
}
.basic-form-Finance > div > div {
  margin-bottom: 6%;
  width: 94%;
}
.basic-form-Finance input {
  border: none;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  width: 90%;
  padding: 3.5% 6%;
  margin-top: 2%;

  
}
.basic-form-Finance .form-name::placeholder {
  /* color: #6F6F6F; */
  font-weight: 600;
}
.name-container {
  margin-bottom: 3%;
  width: 100%;
}
.name-container .placeholder {
  left: 2.9%;
  top: 40%;
}
.form-name::placeholder {
  color: #868686;
}
.input-container {
  position: relative;
}
.input-container input:valid + .placeholder {
  display: none;
}
.input-container .placeholder {
  position: absolute;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  pointer-events: none;
  top: 33%;
  left: 6%;
  margin: auto;
  color: #868686;
}
.placeholder span {
  color: #df0d00;
}
.form-name:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.basic-form-Finance .mobile-number::placeholder {
  color: #6f6f6f;
  font-weight: 500;
}
.mobile-number:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.dropdown-type > div {
  position: relative;
  padding-left: 7%;
  background-color: white;
  width: 90%;
  margin-top: 2%;
  border-radius: 3px;
  padding: 3.5% 6% 3.5% 6%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.dropdown-type p {
  /* color: #868686; */
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
}
.dropdown-type .selected-type {
  color: #868686;
}
.dropdown-icon {
  position: absolute;
  left: 85%;
  margin-top: 2%;
}
.dropdown-type ul {
  list-style: none;
  position: absolute;
  margin-top: 1.3%;
  width: 100%;
  top: 100%;
  left: 0;
  right: 0;
  padding-left: 0;
  border: #c4c4c4 solid 0.7px;
  border-radius: 5px;
  z-index: 1;
  background-color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.dropdown-type li {
  color: black;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  padding: 2.5% 6%;
  text-align: center;
  background-color: white;
  border-radius: 5px;
}
.dropdown-type li:hover {
  border-radius: 3px;
  background-color: #0081df;
  color: white;
  transition: 0.1s ease-in;
}
.finance-info {
  border: none;
  border-radius: 3px;
  width: 92%;
  font-size: 14px;
  padding: 1.5% 3%;
  margin-top: 1.5%;
  font-family: Manrope;
  resize: none;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 3.5%;
}
.Finance-form .finance-info::placeholder {
  color: #868686;
  font-weight: 600;
  font-size: 14px;
}
.finance-info:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.active-finance {
  background-color: white;
  font-size: 17px;
  font-weight: 600;
  border-radius: 7px;
  color: #0081df;
  padding: 1.8% 6%;
  border: none;
  /* position: absolute;
    right: 4%;
    top: 89%; */
  margin-left: 75%;
  margin-bottom: 2%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.active-finance:hover {
  opacity: 0.7;
  cursor: pointer;
}
.disabled-finance {
  background-color: white;
  background-color: #c4c4c4;
  cursor: not-allowed;
  font-size: 17px;
  font-weight: 500;
  border-radius: 7px;
  color: white;
  padding: 1.8% 6%;
  border: none;
  margin-left: 75%;
  margin-bottom: 2%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#mobile-finance-card {
  display: none;
}

.phone-Finance-form {
  display: none;
}

@media (min-width: 1500px) {
  .Finance {
    margin: 6% 8% 2% 8%;
  }
}
@media (max-width: 1300px) {
  .Finance-img > p {
    font-size: 15px;
  }
}
@media (max-width: 1300px) {
  .finance-card > p {
    font-size: 19px;
  }
}

@media (max-width: 768px) {


  .mobile-placeholder-name-finance {
    color: #bababa;
    position: absolute;
    top: 2.9vw;
    left: 12px;
    color: #6f6f6f;

    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    pointer-events: none;
  }

  .mobile-placeholder-name-finance:after {
    content: "*";
    color: red;
  }

  .form-name-finance:valid + .mobile-placeholder-name-finance {
    display: none;
  }

  .mobile-placeholder-mobile-finance {
    color: #bababa;
    position: absolute;
    top: 2.9vw;
    left: 12px;
    color: #6f6f6f;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    pointer-events: none;
  }
  .mobile-placeholder-mobile-finance:after {
    content: "*";
    color: red;
  }

  .mobile-number-finance:valid + .mobile-placeholder-mobile-finance {
    display: none;
  }

  .mobile-placeholder-city-finance {
    color: #bababa;
    position: absolute;
    top: 2.9vw;
    left: 12px;
    color: #6f6f6f;

    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    pointer-events: none;
  }
  .mobile-placeholder-city-finance:after {
    content: "*";
    color: red;
  }

  .form-city-finance:valid + .mobile-placeholder-city-finance {
    display: none;
  }

  .form-company-finance:valid + .mobile-placeholder-city-finance {
    display: none;
  }

  .mobile-placeholder-company-finance {
    color: #bababa;
    position: absolute;
    top: 2.9vw;
    left: 12px;
    color: #6f6f6f;

    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    pointer-events: none;
  }
  .mobile-placeholder-company-finance:after {
    content: "*";
    color: red;
  }

  .form-company-finance:valid + .mobile-placeholder-company-finance {
    display: none;
  }

  .Finance {
    padding-top: 10vw;
    padding-bottom: 12vw;
  }
  .Finance {
    display: flex;
    flex-direction: column;
    margin: 6% 0% 2% 0%;
  }
  .Finance-img div img {
    display: none;
  }

  .Finance-form {
    display: none;
  }
  .Finance-text {
    order: 1;
    padding-right: none;
    margin-left: 5%;
    margin-right: 5%;
  }

  .Finance-text h3 {
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .Finance-text p {
    color: #000;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .Finance-img {
    display: flex;
    flex-direction: column;
    order: 2;
    margin-left: 3%;
    margin-right: 3%;
  }

  .finance-cards-container {
    display: flex;
    gap: 5px;
  }
  .finance-card {
    width: 22%;
    padding: 3px;
    height: auto;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 13vw;
  }

  .finance-card p {
    color: #000;
    text-align: center;
    font-family: Manrope;
    font-size: 2.1vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .finance-card > p > span {
    display: none;
  }

  #web-finance-card {
    display: none;
  }
  #mobile-finance-card {
    display: block;
  }
  .fin-br {
    display: none;
  }

  .phone-Finance-form {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: fit-content;
    padding-bottom: 10px;
    border-radius: 15px 15px 0px 0px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.15);
    order: 3;
    padding-top: 4vw;
    padding-bottom: 20px;
    margin-top: 1vw;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-left: 1vw;
    margin-right: 1vw;
  }
  .phone-Finance-form-heading {
    color: #000;
    font-family: Manrope;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-left: 5vw;
    margin-bottom: 1vw;
  }
  .basic-form-Finance-mobile {
    display: flex;
    flex-direction: column;
    height: auto;
    gap:3px;
    align-items: center;
    margin-left: 5vw;
    margin-right: 5vw;
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 10px;
    padding-right: 14px;
    column-gap: 6%;
    row-gap: 6px; */
  }
  .basic-form-Finance-mobile div {
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    align-items: center;
    width: 100%;
  }
  .basic-form-Finance-mobile input,
  .basic-form-Finance-mobile textarea {
    border-radius: 5px;
    border: 0.7px solid #c7c7c7;
    width: 100%;
    color: #000;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .mobile-number-finance,
  .form-name-finance,
  .form-city-finance,
  .form-company-finance,
  .form-product-finance
  {
    /* grid-column-start: 1;
    grid-column-end: 3; */
    height: 10px;
    padding: 4% 3%;
  }
  .form-email-finance {
    height: 10px;
    padding: 4.1% 3%;
  }
  .form-product-finance{
    margin-top: 1vw;
  }
  
  .fin-dropdowns{
    display: flex;
    column-gap: 2vw;
    height:6vw;
  }

  /* .basic-form-Finance-mobile  .dropdown-type {
    grid-column-start: 2;
    grid-column-end: 3;
    height: fit-content;
    width: 100%;
  }

  .basic-form-Finance-mobile  .dropdown-type {
    grid-column-start: 1;
    grid-column-end: 2;
    height: fit-content;
    width: 100%;
  } */

  .dropdown-type p {
    text-align: left;
    padding-top: 2px;
    color: #000;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .dropdown-icon {
    position: absolute;
    left: 85%;
    margin-top: 4%;
  }
  .dropdown-iconPT {
    position: absolute;
    left: 93%;
    margin-top: 1%;
  }

  .finance-info-mobile {
    grid-column-start: 1;
    grid-column-end: 3;
    height: 80px;
    margin-top: 3%;
  }
  .finance-info-mobile::placeholder {
    color: #6f6f6f;

    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 2%;
    padding-left: 2%;
  }

  .active-finance-mobile {
    background-color: #0081df;
    font-size: 16px;
    font-weight: 600;
    border-radius: 7px;
    color: white;
    padding: 7px 25px;
    border: none;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.25);
    align-self: flex-start;
    margin-top: 2vw;
  }
  .active-finance-mobile:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .disabled-finance-mobile {
    background-color: #E3E3E3;
    font-size: 16px;
    font-weight: 600;
    border-radius: 7px;
    color: #A59F9F;
    padding: 7px 25px;
    border: none;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.25);
    align-self: flex-start;
    margin-bottom: 2vw;
    margin-top: 2vw;
  }

  .form-email-finance::placeholder,
  .form-product-finance::placeholder {
    color: #6f6f6f;

    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .dropdown-type div{
    position: relative;
  }

  .dropdown-type .selected-type {
    color: rgb(199, 196, 196);
    color: #6f6f6f;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 8%;
    /* margin-top: 2%; */
    margin-left: 0;
    position: absolute;
    left:3vw;
    
    
  }
  .dropdown-type .selected-typePT {
    color: rgb(199, 196, 196);
    color: #6f6f6f;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 8%;
    /* margin-top: 2%; */
    margin-left: 0;
    position: absolute;
    left:3vw;
    top: 9px;
  }


  .dropdown-type p{
    
    margin-top: 2%;
  }

  .dropdown-type {
    border-radius: 7px;
  }

  
}
