@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");

.CartItemCard {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap:3%;
    margin-left: 5%;
    margin-top: 7%;
  }
  .CartItemCard img {
    width: 4vmax;
    height:4vmax;
  }
  .CartItemCard > div > a {
    color: black;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    margin-top: 15%;
  }
  .CartItemCard > div > p{
    font-size: 13px;
    color:#ABABAB;
  }

  @media screen and (max-width: 768px) {
    .CartItemCard {   
      height:auto;
      padding: 0px;
      gap: 0px;
      align-items: start;
      margin: 0px;
      margin-top: 10px;
      width: 100%;
      
    }
    .CartItemCard img {
      width: 30px;
      height:auto;
      margin-top: 30%;
    }
  
    .CartItemCard > div {
     
      margin: 0px;
      margin-left: 8%;
    }
  
    .CartItemCard > div > a {
    
      
      text-decoration: none;
      margin-top: 15%;

      color: #000;
font-family: Manrope;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: normal;
   
      

    }
  
    .CartItemCard > div > span {
      font: 300 1.9vmax "Roboto";
     

    }
  
    .CartItemCard > div > p {
      color: #ABABAB;
      font-family: Manrope;
      font-size: 8px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

    }
  }