@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
.main-nav {
  font-family: "Manrope";
  display: grid;
  width: 100%;
  /* height: 5%; */
  grid-template-columns: 8% 1fr 5.2fr 1.2fr 8%;
  transition: all 0.5s ease;
  padding-top: 1%;
  padding-bottom: 1%;
  position: fixed;
  z-index: 5;
  background-color: white;
  top: 0;
}
.Logo {
  display: grid;
  grid-column: 2/3;
  justify-content: start;
  align-items: center;
  margin-top: 1.5%;
}
.logoImage {
  width: 130px;
  aspect-ratio: 3.64;
}
.menu-link {
  grid-column: 3/4;
  margin-top: 1.5%;
  margin-left: 2%;
  margin-right: 2%;
}
.menu-link ul {
  display: flex;
  list-style: none;
  justify-content: space-around;
  align-items: center;
}
.menu-link li {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
}
/* .menu-link ul li a {
  color: black;
  transition: 0.5s;
  text-decoration: none;
} */
.menu-link ul li:hover {
  transform-origin: left;
  transition: 0.2s ease-in-out;
  color: #0d99ff;
}

.menu-link ul li.active,
.cart-profile ul li.nav-item.active {
  position: relative;
}
.menu-link ul li.active::before {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  /* right: 0; */
  width: 90%;
  height: 2px;
  border-radius: 10px;
  background-color: #0d99ff;
  z-index: -1;
}
.cart-profile ul li.nav-item.active::before {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: 10px;
  background-color: #0d99ff;
  z-index: -1;
}
/* .menu-link a:hover {
  color: #0D99FF;
} */
.cart-profile {
  margin-top: 2%;
  /* margin-left: 2%; */
  display: flex;
  gap: 20%;
  list-style: none;
  justify-content: flex-end;
  align-items: center;
  padding-left: 0;
}
.cart-profile ul {
  list-style: none;
}
.cart-profile li {
  /* display: flex;
  align-items: center;
  gap:5px; */
  font-size: 14px;
  font-weight: 500;
  /* white-space: nowrap; */
  cursor: pointer;
}
.login-button {
  color: white;
  display: flex;
  justify-content: center;
  gap: 11px;
  background-color: #0081df;
  border-radius: 5px;
  border: none;
  padding: 4.5% 9.5%;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}
/* .login-button:hover{
  opacity: 0.7;
} */
.name-visible {
  gap: 6px;
  font-size: 15px;
  padding: 4.5% 6%;
}
.profile-bubble {
  position: absolute;
  top: 90%;
  right: -8%;
}
.bubble {
  position: relative;
  /* height:140px; */
}
.pb-name {
  position: absolute;
  color: black;
  top: 17%;
  left:0;
  right:0;
  text-align: center;
  font-family: Manrope;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* white-space: nowrap; */
}
.pb-num {
  position: absolute;
  top: 30%;
  left: 20%;
  right: 20%;
  color: #6f6f6f;
  text-align: center;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.pb-buttons {
  position: absolute;
  top: 46%;
  left: 12%;
  right: 10%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  gap: 5px;
  width: 75%;
  
}
.pb-track,.pb-logout{
  border-radius: 3px;
  border: 0.3px solid #d0d0d0;
  background-color: transparent;
  font-size: 11px;
  padding: 4.5px 0px;
  cursor: pointer;
}
.pb-track:hover,.pb-logout:hover{
  background-color: #e3e3e3;
}
.pb-track{
  color:#0081df;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10%;
}
.pb-logout{
  color: #D20000;
  font-weight: 600;
}
.cart-icon {
  position: relative;
}
.custom-icon {
  height: 22px;
  width: 27px;
}
.profile-icon {
  height: 22px;
  width: 27px;
}
.cart-number {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 15px;
  height: 15px;
  background-color: #0081df;
  color: white;
  border-radius: 50%;
  z-index: 5;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width:1356px){
  .name-visible{
    width: 166px;
  }
}

@media (max-width:1500px){
  .main-nav {
    grid-template-columns: 9.5% 1fr 5.2fr 1.2fr 9.5%;
  }
}
@media (max-width:1600px){
  .main-nav {
    grid-template-columns: 10.5% 1fr 5.2fr 1.2fr 10.5%;
  }
}
@media (max-width:1300px){
  .main-nav {
    grid-template-columns: 6.5% 1fr 5.2fr 1.2fr 6.5%;
  }
}
@media (max-width:1150px){
  .main-nav {
    grid-template-columns: 4.5% 1fr 5.2fr 1.2fr 4.5%;
  }
}

@media (max-width:1050px){
  .main-nav {
    grid-template-columns: 3.5% 1fr 5.2fr 1.2fr 3.5%;
  }
}
@media (max-width:768px) {

  .main-nav{
    display: none;
  }
  
}
