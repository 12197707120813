.rfSubmit{
    display: flex;
    flex-direction: column;
    margin-top: 6%;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    border: 0.3px solid #D0D0D0;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    width: 60%;
    position:relative;
    left: 20%;
    padding-bottom: 4vw;
    padding-top: 4vw;
    margin-bottom: 10vw;
}
.rsubmitpic{
    height: 250px;
    width: 350px;
    
}
.rfSubmit h3{
    color: #0081DF;
text-align: center;
font-family: Manrope;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.rfSubmit p{
    color: #5F5F5F;
text-align: center;
font-family: Manrope;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.rfSubmit div{
    display: flex;
    align-items: center;
    gap: 20px;
}
.rfSubmit div h5{
    color: #E85400;
font-family: Manrope;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;

}

.rfSubmit div button{
    border-radius: 7px;
    background: #0081DF;
    color: white;
    border: none;
    height: 50px;
    color: #FFF;
text-align: center;
font-family: Manrope;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
width: 200px;
cursor: pointer;
}

.rsubmitpicmobile{
    display: none;
}


@media (max-width:768px) {

    .rsubmitpic{
        display: none;
    }
    .rsubmitpicmobile{
        display: inline;
    }

    .rfSubmit{
        display: flex;
        flex-direction: column;
        margin-top: 150px;
        align-items: center;
        gap: 10px;
        border-radius: 0px;
        border: none;
        background: #FFF;
        box-shadow: none;
        width: 100%;
        position: static;
        padding-bottom: 4vw;
        padding-top: 4vw;
        margin-bottom: 10vw;
    }
    
    .rfSubmit h3{
        color: #0081DF;
        text-align: center;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    .rfSubmit p{
        color: #5F5F5F;
        text-align: center;
        font-family: Manrope;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .rfSubmit div{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
    .rfSubmit div h5{
        color: #E85400;
        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    
    }
    
    .rfSubmit div button{
        border-radius: 7px;
        background: #0081DF;
        color: white;
        border: none;
        height: 40px;
        color: #FFF;
        text-align: center;
        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 140px;
        cursor: pointer;
    }
}