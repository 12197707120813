@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap");

.wrapper-plp {
  margin: 6% 4.3%;
  /* background-color: orange; */
}
.products-listing {
  display: grid;
  grid-template-columns: 1.2fr 4fr;
  gap: 10px;
  /* background-color: red; */
}
.products-section {
  display: grid;
  grid-template-columns: 1fr;
  /* background-color: green; */
}
.with-cart {
  display: grid;
  grid-template-columns: 2fr 1.2fr;
}
.products {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 10px;
  /* background-color: magenta; */

  /* column-gap: 3.5%;
  row-gap: 1%; */
  /* display:flex;
  flex-wrap: wrap;
  gap:1.2%; */
  /* max-height: 1300px;
  overflow-y: auto; */
  margin-top: 1.5%;

  /* max-height: 1300px;
  overflow-y: auto; */
  /* margin-top: 3%; */
}
.with-cart .products {
  grid-template-columns: repeat(2, 1fr);
  row-gap: 15px;
  column-gap: 5px;
}
.products::-webkit-scrollbar {
  width: 0.5em;
}

.products::-webkit-scrollbar-thumb {
  background-color: #c4c4c4; /* Color of the thumb (the draggable part) */
  /* border-radius: 6px; */
  background-color: transparent;
}

.products::-webkit-scrollbar-track {
  background-color: transparent;
}

.filterBox {
  width: 98%;
  border: #e3e3e3 solid 0.5px;
  border-radius: 7px;
  margin-right: 5px;
  font-family: "Manrope", sans-serif;
  position: relative;
  height: fit-content;
  padding-bottom: 15px;
  /* box-shadow:0px 10px 10px -10px grey inset; */
}
.filterBox::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 7px;
  width: 100%;
  height: 50px; /* Adjust the height of the shadow as needed */
  background: linear-gradient(
    to top,
    rgba(55, 55, 55, 0.07) 23.74%,
    transparent 100%
  );
  z-index: -1;
}
.filters {
  padding: 5% 8%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4%;
  position: relative;
}
.filter-text {
  font-size: 19px;
  font-weight: 700;
  margin-top: 0;
}
.reset-btn {
  font-family: "Manrope";
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 0.5px solid #e3e3e3;
  background: #fff;
  padding: 3px 12px;
  /* Text Styles */
  font-size: 11px;
  font-weight: 500;
  color: #0081df;
  text-align: center;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  position: absolute;
  right: 7%;
  margin-top: 3px;
}
.f-line {
  width: 100%;
  height: 0.5px;
  background-color: #e3e3e3;
  margin: 0;
}
.device-status {
  font-size: 17px;
  font-weight: 700;
  padding: 18px;
}
.new-machine {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
}
.new-machine > span {
  margin-top: 5px;
  position: absolute;
  right: 0px;
}
.pre-owned-machine {
  display: flex;
  position: relative;
  margin-bottom: 15px;
}
.pre-owned-machine > span {
  margin-top: 5px;
  position: absolute;
  right: 0px;
}
.f-line2 {
  width: 100%;
  height: 0.5px;
  background-color: #e3e3e3;
  margin: 0;
}
.categoryBox {
  margin-top: 2%;
  /* margin-top: 10px; */
  max-height: 305px;
  overflow-y: auto;
  padding: 0;
}
.search-field {
  width: 90%;
  border: none;
  border-radius: 7px;
  background-color: #f1f1f1;
  padding: 8px 10px;
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
}
.search-bar {
  font-family: "Manrope";
  font-size: 14px;
  width: 100%;
  outline: 0;
  border: none;
  background: transparent;
  padding-left: 15px;
}
.search-bar:focus {
  outline: none;
}
.search-bar::placeholder {
  color: #c4c4c4;
}
.search-icon {
  position: absolute;
  left: 5px;
  top: 0;
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plp-container-top {
  display: flex;
  align-items: center;
}
.search-products-field {
  border: none;
  border-radius: 7px;
  background-color: #f1f1f1;
  padding: 10px 15px;
  display: inline-flex;
  gap: 5px;
  position: relative;
  justify-content: flex-start;
  align-items: center;
}
/* .search-products-field:active{
  border: 1px solid #0081df;
} */
.search-products-field input:focus + .animated-placeholder {
  display: none;
}
/* .search-products-field .form-name::placeholder{
  color: #868686;
  font-weight: 600;
  opacity: 1;
} */
.animated-placeholder {
  position: absolute;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 400;
  pointer-events: none;
  top: 25%;
  left: 6%;
  margin: auto;
  color: #c4c4c4;
}
.CustomCursor {
  font-size: 40px; /* Adjust the size as needed */
}
.search-products {
  font-family: "Manrope";
  font-size: 15px;
  width: 100%;
  outline: 0;
  border: none;
  background: transparent;
  padding-left: 10px;
}
.search-products::placeholder {
  color: #c4c4c4;
}
.searchProduct-Results {
  width: 100%;
  background-color: white;
  border: 0.5px solid #e3e3e3;
  padding-left: 0;
  border-radius: 7px;
  position: absolute;
  margin-top: 2px;
  top: 100%; /* Position below the input */
  left: 0;
  right: 0;
  list-style: none;
  z-index: 2;
}
.search-products-field .searchProduct-Results {
  border: none;
  border-radius: 0;
}

.search-products-field:focus-within
  input:not(:placeholder-shown)
  + .searchProduct-Results {
  border: 0.5px solid #e3e3e3;
  border-radius: 7px;
  display: block; /* Show the dropdown menu */
}
.search-Results {
  font-family: "Manrope";
  /* list-style: none; */
  color: black;
  font-size: 15px;
  font-weight: 500;
  padding: 10px;
  padding-left: 40px;
  cursor: pointer;
  transition: all 0.1s;
}
.search-Results:hover {
  background-color: #0081df;
  color: white;
  border-radius: 7px;
}
.view-cart {
  flex-basis: 1fr;
  background-color: #0081df;
  font-size: 16px;
  font-weight: 500;
  border-radius: 7px;
  color: white;
  padding: 6px 15px;
  border: none;
  margin-left: 2%;
  margin-top: 0;
}
.view-cart:hover {
  opacity: 0.7;
  cursor: pointer;
}
.cart-open {
  background-color: #f1f1f1;
  color: #0081df;
}
.disabled-view {
  background-color: #a0a0a0;
  color: white;
}
.disabled-view:hover {
  cursor: not-allowed;
}
.medfield {
  padding: 18px;
  padding-right: 7px;
  padding-top: 0;
  margin-bottom: 5px;
}
.medfield-heading {
  font-family: "Manrope";
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 10px;
}
.categoryBox::-webkit-scrollbar {
  width: 4px;
}

.categoryBox::-webkit-scrollbar-thumb {
  background-color: #e8e5e5; /* Color of the thumb (the draggable part) */
  border-radius: 6px;
}
.categoryBox::-webkit-scrollbar-track {
  background-color: transparent;
  background: #d9d9d9;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  background-clip: padding-box;
}
.category-link {
  display: flex;
  align-items: flex-start;
  gap: 1.2vw;
  /* gap: 5px; */
  list-style: none;
  color: black;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0 5px 0;
  cursor: pointer;
  transition: all 0.1s;
}
.category-link:hover {
  color: #0081df;
}

.filterBox > fieldset {
  border: 1px solid rgba(225, 144, 144, 0.329);
}
/* This styles the default checkbox */
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width:20px;
  height:20px;
  border: 0.5px solid #ccc;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  /* margin-right: 20px; */
}

/* This styles the checkbox when it's checked */
input[type="checkbox"]:checked {
  background-color: #007bff; /* Change to your desired background color */
  border: none /* Change to your desired border color */
}

/* This adds the checkmark icon inside the checkbox */
input[type="checkbox"]:checked::before {
  content: "\2713"; /* Unicode checkmark character */
  display: block;
  text-align: center;
  line-height: 20px; /* Adjust based on checkbox height */
  color: white; /* Change to your desired checkmark color */
}

.tags ul {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0;
  /* margin: 13px 0px 0px 0px; */
}
.tags li {
  font-family: "Manrope";
  font-size: 13px;
  font-weight: 500;
  color: #525252;
  display: flex;
  gap: 3px;
  background-color: #d9d9d9;
  border: none;
  border-radius: 7px;
  padding: 7px;
  padding-bottom: 3px;
  margin-top: 13px;
}
.tags .clear-all {
  font-family: "Manrope";
  font-size: 13px;
  font-weight: 500;
  background-color: #525252;
  color: white;
  display: flex;
  margin-right: 1%;
  gap: 3px;
  border: none;
  border-radius: 7px;
  padding: 7px;
  padding-bottom: 3px;
  cursor: pointer;
}
.tags span {
  padding: 0;
  margin: 0;
}
.tags i {
  cursor: pointer;
}
.paginationBox {
  display: flex;
  justify-content: center;
  margin: 6vmax;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 0;
}

.page-item {
  background-color: rgb(255, 255, 255);
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.178);
  padding: 1vmax 1.5vmax;
  transition: all 0.3s;
  cursor: pointer;
}
.page-item:first-child {
  border-radius: 5px 0 0 5px;
}

.page-item:last-child {
  border-radius: 0 5px 5px 0;
}
.page-link {
  text-decoration: none;
  font-family: "Manrope";
  font-size: 14px;
  color: black;
  transition: all 0.3s;
}

.page-item:hover {
  background-color: rgb(230, 230, 230);
}

.page-item:hover .page-link {
  color: rgb(0, 0, 0);
}

.pageItemActive {
  background-color: #0081df;
}

.pageLinkActive {
  color: white;
}

.Box-mobile,
.filter-btn-mobile {
  display: none;
}

.mobile-cart-section {
  display: none;
}

/* .filter-sidebar-mobile{
  display: none;
} */
@media (max-width: 1230px) {
  .wrapper-plp {
    margin: 6% 4.3%;
  }
  .products-listing {
    display: grid;
    grid-template-columns: 1.3fr 4fr;
    gap: 10px;
  }
  .products-section {
    display: grid;
    grid-template-columns: 1fr;
  }
  .with-cart {
    display: grid;
    grid-template-columns: 2fr 1.3fr;
  }
  .products {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 10px;
    margin-top: 1.5%;
  }
  .with-cart .products {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 15px;
    column-gap: 5px;
  }
}
@media (max-width: 1080px) {
  .wrapper-plp {
    margin: 6.5% 6%;
  }

  .products-listing {
    display: grid;
    grid-template-columns: 1.7fr 4fr;
    gap: 15px;
  }
  .products-section {
    display: grid;
    grid-template-columns: 1fr;
  }
  .with-cart {
    display: grid;
    grid-template-columns: 1.1fr 1.2fr;
  }
  .products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
    margin-top: 2.5%;
  }
  .with-cart .products {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 15px;
    column-gap: 5px;
  }
  .category-link {
    font-size: 14px;
  }
  .categoryBox::-webkit-scrollbar {
    width: 4px;
  }
  .view-cart {
    font-size: 14px;
    padding: 8px 15px;
  }
  .search-products-field {
    width: 6%;
  }
}

@media (max-width: 1399px) {
  .search-products-field {
    width: 82%;
  }
}
@media (min-width: 1400px) {
  .search-products-field {
    width: 83%;
  }
}
@media (max-width: 1200px) {
  .search-products-field {
    width: 80.5%;
  }
}
@media (max-width: 1080px) {
  .search-products-field {
    width: 76.5%;
    padding: 9px 15px;
    margin-bottom: 1%;
  }
  .search-products {
    font-size: 14px;
  }
}
@media (min-width: 1450px) {
  .wrapper-plp {
    margin: 5% 6%;
  }
}
@media (min-width: 1500px) {
  .wrapper-plp {
    margin: 5% 8%;
  }
}
@media (min-width: 1600px) {
  .wrapper-plp {
    margin: 5% 4.3%;
  }
  .products-listing {
    display: grid;
    grid-template-columns: 1.2fr 5fr;
    gap: 10px;
  }
  .products-section {
    display: grid;
    grid-template-columns: 1fr;
  }
  .with-cart {
    display: grid;
    grid-template-columns: 3.2fr 1.5fr;
  }
  .products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 10px;
    margin-top: 1.5%;
  }
  .with-cart .products {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 15px;
    /* column-gap: 5px; */
  }
  .search-products-field {
    width: 86%;
  }
}
@media (min-width: 1800px) {
  .wrapper-plp {
    margin: 5% 5%;
  }
  .products-listing {
    display: grid;
    grid-template-columns: 1.2fr 5fr;
    gap: 10px;
  }
  .products-section {
    display: grid;
    grid-template-columns: 1fr;
  }
  .with-cart {
    display: grid;
    grid-template-columns: 3.2fr 1.4fr;
  }
  .products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 10px;
    margin-top: 1.5%;
  }
  .with-cart .products {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 15px;
    /* column-gap: 5px; */
  }
  .search-products-field {
    width: 87%;
  }
}

@media (max-width: 768px) {
  .tags {
    padding-left: 1vw;
  }

  .tags ul {
    margin-top: -8px;
  }
  /* .MuiDrawer-paper{
    top:4vw;
    height: 95%;
    border-radius: 0px 10px 10px 0px;
  } */
  .f-line-prods {
    width: 100%;
    height: 0.5px;
    background-color: #e3e3e3;
    margin: 0;
  }
  .filters {
    padding: 5% 8%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4%;
    background-color: white;

    position: sticky;
    top: 0px;
  }
  .filter-text{
    font-weight: 800;
  }
  .filteredCount {
    position: sticky;
    bottom: 0;
    background-color: #0081df;
    border: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: Manrope;
    font-size: 4vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 3.2vw ;
  }
  .mobile-cart-section {
    display: inline;
    position: fixed;
    bottom: 7.8vh;
    background-color: white;
    height: fit-content;
    width: 100%;
    border-radius: 5px 5px 0px 0px;
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.25);
  }

  .mobile-cart-section div {
    display: flex;
    justify-content: space-between;
    padding: 4vw 3vw;
  }

  .mobile-cart-section div p {
    color: #259f07;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    align-self: center;
  }

  .mobile-cart-section div button {
    border-radius: 7px;
    background: #0081df;
    color: #fff;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 6px 14px 5px 16px;
    border: none;
  }

  .animated-placeholder {
    position: absolute;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    pointer-events: none;
    top: 2.3vw;
    left: 11vw;
    margin: auto;
    color: #c4c4c4;
  }

  .wrapper-plp {
    margin-top: 100px;
    position: relative;
    /* background-color: red; */
    width: 100%;
    margin: 6% 0%;
    margin-top: 60px;
    margin-top: 17vw;
    /* padding: 2px 12px; */
  }

  .plp-container {
    /* background-color: green; */
    width: 100%;
  }
  .plp-container-top {
    position: sticky;
    top: 14vw;
    z-index: 1;
    padding-top: 2vw;
    padding-bottom: 3vw;
    background-color: white;
  }
  .filter-sidebar-mobile {
    transition: 0.3s ease-out;
    /* transition: width 2s; */
    width: 50%;
  }

  /* .filterBox-mobile{
    position: absolute;
    z-index: 2;
    background-color: white;
    transition: left 10s ease-in-out;
    transform: translateX(0%);
    left: 0%;

  } */
  .categoryBox::-webkit-scrollbar-thumb {
    background-color: #f1f1f1; /* Color of the thumb (the draggable part) */
    border-radius: 4px;
  }
  .expanded::-webkit-scrollbar-thumb {
    background-color: #f1f1f1; /* Color of the thumb (the draggable part) */
    border-radius: 3px;
  }

  .filter-btn-mobile {
    display: flex;
    gap: 8%;
    padding: 8px 12px;
    margin-left: 1.5%;
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    background: #fff;
  }
  .filter-btn-mobile span {
    color: #000;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .Box-mobile {
    display: flex;
    position: absolute;
    z-index: 2;
    width: 0px;
    height: 0px;
    background: white;
    transition: width 0.3s;

    left: -100%;
  }

  .expanded {
    /* display: inline; */
    width: 77vw;
    height: 100vh;
    overflow-y: scroll;
    background-color: white;
    position: absolute;
    left: 0px;
    top: 0px;
    box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.25);

    border-radius: 7px;
  }
  .category-link{
    gap: 3vw;
  }
  .view-cart {
    display: none;
  }

  .filterBox {
    display: none;
  }

  .filter-sidebar-mobile {
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    background: #fff;
    /* background-color: aqua; */
  }

  .with-cart .products {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 5px;
  }

  .products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* row-gap: 10px; */
    column-gap: 0px;
    /* background-color: magenta; */
    padding: 0px;
    width: 100%;
    justify-items: center;
    /* padding: 10px ; */
    padding-top: 1vw;
  }

  .products-listing {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
    /* background-color: red; */
    padding: 0px;
  }
  .products-section {
    display: grid;
    grid-template-columns: 1fr;
    /* background-color: green; */
  }
  .with-cart {
    display: grid;
    /* grid-template-columns: 2fr 1.2fr; */
    grid-template-columns: 1fr;
  }

  .search-products-field {
    /* width: 0%; */
    margin-left: 3%;
    margin-right: 3%;
    border-radius: 5px;
    margin-top: 0.7%;
    /* background-color: yellow; */
  }
  .search-products:focus {
    outline: none;
  }

  .close-filter-btn {
    position: absolute;
    right: 5%;
    border: none;
    background-color: white;
  }

  .cart-section {
    display: none;
  }
}
