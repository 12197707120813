@font-face {
  font-family: 'Manrope';
  src: url('https://fonts.gstatic.com/s/manrope/v7/3JnlS0xuDWRBEpijkSlEGIY3.ttf') format('truetype');
  font-display: swap;
}

@media (max-width :768px) {

    .alert-container {
        display: none;
      }
    
}