@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");

.emptyCart {
  font-family: Manrope;
  margin: auto;
  text-align: center;
  padding: 18vmax 10vmax 10vmax 10vmax;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  
}
.emptyCart > svg {
  font-size: 5vmax;
  /* color: tomato; */
}
.emptyCart > img {
  height:400px;
  width:400px;
}
.emptyCart > p {
  color: #0081DF;
  font-size: 30px;
  margin: 2%;
}
.emptyCart > a {
  background-color: #0081df;
  font-size: 16px;
  font-weight: 600;
  border-radius: 7px;
  color: white;
  border: none;
  padding: 1.2% 2%;
  cursor: pointer;
  margin: 2% 0%;
  text-decoration: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;
}
.emptyCart > a:hover {
  /* background-color: grey; */
  opacity: 0.7;
  /* transition: 0.1s ease-in-out; */
}


.cartPage-main {
  font-family: Manrope;
  border: 0.7px solid #cfcfcf;
  border-radius: 10px;
  max-height: fit-content;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin: 6% 18%;
 
}
.cartHeader-main {
  
  padding: 2% 4%;
  color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.cartBox-main {
  border: 0.7px solid #cfcfcf;
  border-radius: 10px;
  max-height: fit-content;
  margin: 1% 3%;
  position: relative;
  padding-bottom: 3%;
  padding-right: 3%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  
}
.cart-sidebar {
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%; /* Occupies full height of the list item */
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  background-color: #0081df;
}
.cartContainer-main {
  margin-left: 10%;
  height: 6vmax;
  display: grid;
  grid-template-columns: 3.5fr 1.2fr 1fr;
  padding-bottom: 2%;
  border-bottom: 0.7px solid #cfcfcf;
}
.cartContainer-main span {
  cursor: pointer;
}
.cartInput-main {
  margin-top: 1%;
}
/* .cartInputBox-main-main{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 0.7px solid #CFCFCF;
  border-radius: 5px;
  margin-top: 10%;
  width: 100%;
  height: min-content;
  
} */
/* .cartInputBox-main-main > input {
  font-size: 17px;
  font-weight: 500;
  border:none;
  outline: none;
  width:50%;
  margin: 0% 15%;
  text-align: center;
  color: #0081DF;
} */
.cartInputBox-main {
  display: flex;
  align-items: center;
  margin-top: 14%;
  justify-content: flex-start;
  border: 0.7px solid #cfcfcf;
  border-radius: 7px;
  width: 100%;
  height: 4vmax;
  position: relative;
}
.cartInputBox-main > input {
  font-size: 16px;
  font-weight: 500;
  border: none;
  width: 15%;
  outline: none;
  margin: 0%;
  text-align: center;
  color: #0081df;
}
.cartInputBox-main > p {
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: #0081df;
  margin-left: 5%;
}
.vertical-line-main {
  border-left: 1px solid #cfcfcf;
  height: 52px;
}
.dropdown-main {
  position: relative;
  /* display: flex;
  align-items: center;
  gap:0; */
  display: grid;
  grid-template-columns: 3fr 1fr;
}
/* ..dropdown-main{
  display: flex;
} */
.dropdown-main > div {
  padding-left: 7%;
}
.dropdown-main p {
  color: #0081df;
  font-size: 15px;
  cursor: pointer;
  padding-top: 2%;
  font-weight: 500;
  /* cursor: pointer; */
}
.dropdown-main > div {
  display: flex;
  flex-direction: column;
}
.dropdown-main ul {
  list-style: none;
  position: absolute;
  margin-top: 23%;
  top: 100%;
  left: 0;
  right: 0;
  padding-left: 0;
  border: #c4c4c4 solid 0.7px;
  border-radius: 5px;
  z-index: 1;
  background-color: white;
  box-shadow: 4px 4px 3px 2px rgba(0, 0, 0, 0.1);
}
.dropdown-main li {
  color: #0081df;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  padding: 6% 8%;
  background-color: white;
  border-radius: 5px;
}
.dropdown-main li:hover {
  border-radius: 3px;
  background-color: #0081df;
  color: white;
  transition: 0.1s ease-in;
}
/* .cartSubtotal {
    display: flex;
    padding: 0.5vmax;
    height: 8vmax;
    align-items: center;
    box-sizing: border-box;
    font: 300 1vmax cursive;
    justify-content: flex-end;
    color: rgba(0, 0, 0, 0.753);
  } */

/* .cartGrossProfit {
    display: grid;
    grid-template-columns: 2fr 1.2fr;
  } */

/* .cartGrossProfitBox {
    border-top: 3px solid tomato;
    margin: 1vmax 4vmax;
    box-sizing: border-box;
    padding: 2vmax 0;
    font: 300 1vmax "Roboto";
    display: flex;
    justify-content: space-between;
  } */

.checkOutBtn-main {
  margin-top: 3%;
  display: flex;
  justify-content: flex-end;
}
.checkOutBtn-main > button {
  background-color: #0081df;
  font-size: 16px;
  font-weight: 500;
  border-radius: 7px;
  color: white;
  padding: 10px 20px;
  border: none;
  white-space: nowrap;
  margin-bottom: 3%;
  margin-right: 3%;
}
.checkOutBtn-main > button:hover {
  opacity: 0.7;
  cursor: pointer;
}

.plusicon{
  width: 10px;
  height: 10px;
  cursor: pointer;
  padding: 0% 4%;
}

.minusicon{
  width: 14px;
  height: 16px;
  cursor: pointer;
  padding: 0% 4%;
}

@media (max-width:768px) {

  .plusicon{
    width: 12px;
    height: 12px;
    cursor: pointer;
    padding: 0% 3%;
  }
  
  .minusicon{
    width: 16px;
    height: 16px;
    cursor: pointer;
    padding: 0% 4%;
    padding-top: 2%;
  }
  
  .emptyCart {
    margin: auto;
    text-align: center;
    padding: 27vmax 10vmax 10vmax 10vmax;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
  }
  .emptyCart > svg {
    font-size: 5vmax;
    /* color: tomato; */
  }
  .emptyCart > img {
    height:170px;
    width:220px;
  }
  .emptyCart > p {
    color: #0081DF;
    font-size: 21px;
    margin: 1%;
    margin-top: 4%;
    margin-bottom: 5%;
    
    width: 100%;
  }
  .emptyCart > a {
    background-color: #0081df;
    font-size: 14px;
    font-weight: 600;
    border-radius: 7px;
    color: white;
    border: none;
    padding: 10px 10px;
    cursor: pointer;
    margin: 2% 0%;
    text-decoration: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    
  }
  .emptyCart > a:hover {
    background-color: grey;
    
  }

  .cartPage-main {
    font-family: Manrope;
    border: 0.7px solid #cfcfcf;
    border-radius: 10px;
    max-height: fit-content;
    margin: 6% 6%;
    
    margin-top: 70px;
    
  }
  .cartHeader-main {
    
    padding: 2% 4%;
    
    color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  }
  .cartBox-main {
    border: 0.7px solid #cfcfcf;
    border-radius: 10px;
    height: fit-content;
    margin: 1% 3%;
    position: relative;
    padding-bottom: 3%;
    padding-right: 3%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  
  }
  .cart-sidebar {
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%; /* Occupies full height of the list item */
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    background-color: #0081df;
  }
  .cartContainer-main {
    margin-left: 10%;
    height: auto;
    display: grid;
    grid-template-columns: 3fr 2fr 1fr;
    padding-bottom: 2%;
    border-bottom: 0.7px solid #cfcfcf;
   
  }
  .cartContainer-main span {
    cursor: pointer;
    margin-top: 8%;
  }
  .cartInput-main {
    margin-top: -1%;
  }
  
  .cartInputBox-main {
    display: flex;
    align-items: center;
    margin-top: 17%;
    justify-content: flex-start;
    border: 0.7px solid #cfcfcf;
    border-radius: 7px;
    width: 100%;
    height: 4vmax;
    position: relative;
  
  }
  .cartInputBox-main > input {
    font-size: 13px;
    font-weight: 500;
    border: none;
    width: 15%;
    outline: none;
    margin: 0%;
    text-align: center;
    color: #0081df;

    font-size: 12px;
    font-weight: 500;
    border: none;
    width: 35%;
    outline: none;
    margin: 0%;
    text-align: center;
    color: #0081df;
    
  }
  .cartInputBox-main > p {
    font-size: 14px;
    font-weight: 800;
    text-align: center;
    color: #0081df;
    margin-left: 5%;
  }
  .vertical-line-main {
    border-left: 1px solid #cfcfcf;
    height: 100%;
  }
  .dropdown-main {
    position: relative;
    /* display: flex;
    align-items: center;
    gap:0; */
    display: grid;
    grid-template-columns: 3fr 1fr;
  }
  
  .dropdown-main > div {
    padding-left: 7%;
  }
  .dropdown-main p {
    color: #0081df;
    font-size: 10px;
    cursor: pointer;
    padding-top: 13%;
    font-weight: 500;
    /* cursor: pointer; */
  }
  .dropdown-main > div {
    display: flex;
    flex-direction: column;
  }
  .dropdown-main ul {
    list-style: none;
    position: absolute;
    margin-top: 23%;
    top: 100%;
    left: 0;
    right: 0;
    padding-left: 0;
    border: #c4c4c4 solid 0.7px;
    border-radius: 5px;
    z-index: 1;
    background-color: white;
    box-shadow: 4px 4px 3px 2px rgba(0, 0, 0, 0.1);
  }
  .dropdown-main li {
    color: #0081df;
    font-size: 10px;
    font-weight: 500;
    cursor: pointer;
    padding: 6% 8%;
    background-color: white;
    border-radius: 5px;
  }
  .dropdown-main li:hover {
    border-radius: 3px;
    background-color: #0081df;
    color: white;
    transition: 0.1s ease-in;
  }
 
  .checkOutBtn-main {
    margin-top: 3%;
    display: flex;
    justify-content: flex-end;
  }
  .checkOutBtn-main > button {
    background-color: #0081df;
    font-size: 10px;
    font-weight: 500;
    border-radius: 7px;
    color: white;
    padding: 5px 10px;
    border: none;
    white-space: nowrap;
    margin-bottom: 3%;
    margin-right: 3%;
  }
  .checkOutBtn-main > button:hover {
    opacity: 0.7;
    cursor: pointer;
  }

}

@media screen and (max-width: 600px) {
  .cartPage {
    padding: 0;
    min-height: 60vh;
    background-color: red;
  }

  .cartHeader {
    width: 100%;
    font: 300 1.7vmax "Roboto";
    grid-template-columns: 3fr 1fr 1fr;
  }

  .cartContainer {
    width: 100%;
    grid-template-columns: 3fr 1fr 1fr;
  }

  .cartInput {
    height: 20vmax;
  }

  .cartInput > button {
    padding: 1.5vmax;
  }

  .cartInput > input {
    width: 2vmax;
    padding: 1.5vmax;
    font: 400 1.8vmax "Roboto";
  }

  .cartSubtotal {
    padding: 1.5vmax;
    height: 20vmax;
    font: 300 2vmax "Roboto";
  }

  .cartGrossProfit {
    display: grid;
    grid-template-columns: 0fr 2fr;
  }

  .cartGrossProfitBox {
    padding: 2vmax;
    font: 300 2vmax "Roboto";
  }

  .checkOutBtn > button {
    padding: 2vmax 4vmax;
    width: 100%;
    font: 300 2vmax "Roboto";
  }
}
