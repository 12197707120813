.category-page {
  margin: 7% 5%;
}
.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5%;
  padding: 2% 2%;
  justify-content: flex-start;
  min-height: 30vh;
  border: 0.7px solid #cfcfcf;
  border-radius: 7px;
}

.category-Heading {
  font-size: 26px;
  font-weight: 700;
}
.categoryCard {
  display: block;
  text-align: center;
  width: 18%;
  height: 100%;
  align-items: flex-end;
  margin-bottom: 3%;
}
.categoryCard > div {
  width: 100%;
  aspect-ratio: 1.138;
  border-radius: 7px;
  background-color: #f7f6f6;
  display: flex;
  justify-content: center;
  position: relative;
  /* align-items: center; */
}
.category-icons {
  position: absolute;
  top: 20%;
  max-width: 80px;
  max-height: 80px;
  object-fit: contain;
}
.categoryCard .category-description {
  /* position: relative; */
  text-transform: uppercase;
  font-family: Manrope;
  font-weight: 600;
  text-align: center;
  position: absolute;
  top: 77%;
  color: #868686;
  /* display: flex;
  justify-content: center; */
  font-size: 16px;
}
.med-icons {
  position: absolute;
  top: 20%;
}
/* .category-image{
  height: 170px;
  width: 100%;
  object-fit: inherit;
  border-radius: 7px;
}
.gradient-overlay{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &::after{
    content:"";
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-image: linear-gradient(180deg, rgba(247, 246, 246, 0.00) 23.94%, #0E2438 100%);
    border-radius: 7px;
    opacity: 0.85;
  }

} */
/* .category-description {
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  font-family: Manrope;
  font-weight: 400;
  text-align: center;
  position: absolute;
  top:77%;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 16px;
} */
.show-product-button {
  margin-top: 10px;
  margin-bottom: 3%;
  padding: 12px 16px;
  background-color: transparent;
  border: 0.7px solid;
  border-color: #0d99ff;
  border-radius: 7px;
  cursor: pointer;
  width: 100%;

  color: #0d99ff;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: all 0.3s ease;
}
.show-product-button:hover {
  background-color: #0d99ff;
  color: white;
}
.category-searchbar {
  display: flex;
  gap: 3%;
  align-items: center;
  margin-bottom: 3%;
}
.search-category {
  border: none;
  border-radius: 7px;
  background-color: #f1f1f1;
  padding: 8px 15px;
  display: inline-flex;
  gap: 5px;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  width: 77%;
}
.search-category-items {
  font-family: "Manrope";
  font-size: 15px;
  width: 100%;
  outline: 0;
  border: none;
  background: transparent;
  padding-left: 10px;
}
.search-category-items::placeholder {
  color: #c4c4c4;
}
/* .categoryCard {
    width: 14vmax;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: rgb(48, 48, 48);
    margin: 2vmax;
    transition: all 0.5s;
    padding-bottom: 0.5vmax;
  }
  
  .categoryCard > img {
    width: 14vmax;
  }
  
  .categoryCard > div {
    margin: 0.5vmax;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .categoryCard {
    margin: 0.5vmax;
    font: 300 0.7vmax "Roboto";
  }
  
  .categoryCard > p {
    font-family: "Roboto";
    font-size: 1.2vmax;
    margin: 1vmax 0.5vmax;
    margin-bottom: 0;
  }
  
  .categoryCard > span {
    margin: 0.5vmax;
    color: tomato;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 1vmax;
  }
  
  .categoryCard:hover {
    box-shadow: 0 0 5px rgba(15, 15, 15, 0.26);
  
    transform: translateY(-1vmax);
  }
   */
@media (min-width: 1350px) {
  .category-page {
    margin: 7% 6%;
  }
}
@media (min-width: 1450px) {
  .category-page {
    margin: 7% 7%;
  }
}
@media (min-width: 1500px) {
  .category-page {
    margin: 7% 8%;
  }
  .categoryCard {
    width: 15%;
  }
  .categories {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    padding: 2% 2%;
    justify-content: flex-start;
    min-height: 30vh;
    border: 0.7px solid #cfcfcf;
    border-radius: 7px;
  }
}

@media (max-width: 768px) {
  .category-page {
    margin: 17% 4%;
    margin-bottom: 17vw;
  }
  .categories {
    display: flex;
    flex-wrap: wrap;
    
    padding: 2% 3%;
    /* justify-content: space-evenly; */
    justify-content: flex-start;
    min-height: 30vh;
    border: 0.7px solid #ece9e9;
    border-radius: 5px;
    gap: 3.5%;
    
  
  }

  .category-Heading {
    color: #000;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .categoryCard {
    display: block;
    text-align: center;
    width: 31%;
    height: 100%;
    align-items: flex-end;
    margin-bottom: 3%;
  }
  .categoryCard > div {
    width: 100%;
    aspect-ratio: 1.138;
    border-radius: 3px;
    background-color: #f7f6f6;
    display: flex;
    justify-content: center;
    position: relative;
    padding: none;
  }
  .category-icons {
    position: absolute;
    top: 20%;
    /* width: 32px;
    height: 32px; */
    max-height: 11vw;
    max-width: 11vw;
    object-fit: contain;
  }
  .categoryCard .category-description {
    text-transform: uppercase;
    font-family: Manrope;
    font-weight: 600;
    text-align: center;
    position: absolute;
    top: 77%;
    color: #868686;
    font-size: 8px;
  }
  .med-icons {
    position: absolute;
    top: 20%;
  }
  .show-product-button {
    margin-top: 6px;
    margin-bottom: 3%;
    padding: 5px 2px;
    background-color: transparent;
    width: 100%;
    font-size: 10px;
    border-radius: 3px;
  }
  .show-product-button:hover {
    background-color: #0d99ff;
    color: white;
  }
  .category-searchbar {
    display: flex;
    gap: 3%;
    align-items: center;
    margin-bottom: 5%;
  }
  .search-category {
    border: none;
    border-radius: 7px;
    background-color: #f1f1f1;
    padding: 8px 15px;
    display: inline-flex;
    gap: 5px;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    width: 77%;
  }
  .search-category-items {
    font-family: "Manrope";
    font-size: 15px;
    width: 100%;
    outline: 0;
    border: none;
    background: transparent;
    padding-left: 10px;
  }
  .search-category-items:focus {
    outline: none;
  }
  .search-category-items::placeholder {
    color: #c4c4c4;
  }
}
