@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
a{
  text-decoration: none;
}
.CartItemCard-plp {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap:5%;
    margin-top: 5%;
    margin-left: 2%;
  }
  .CartItemCard-plp img {
    width: 4vmax;
    height:4vmax;
    /* border: solid 0.7px #c4c4c4;
    border-radius: 5px; */
  }
  .CartItemCard-plp p {
    color: black;
    font-size: 12.5px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    .CartItemCard {
      padding: 3vmax;
      height: 25vmax;
    }
    .CartItemCard > img {
      width: 10vmax;
    }
  
    .CartItemCard > div {
      margin: 1vmax 2vmax;
    }
  
    .CartItemCard > div > a {
      font: 300 2vmax cursive;
    }
  
    .CartItemCard > div > span {
      font: 300 1.9vmax "Roboto";
    }
  
    .CartItemCard > div > p {
      font: 100 1.8vmax "Roboto";
    }
  }