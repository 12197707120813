@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
.refurb-container{
  display: flex;
  width:100%;
  background: linear-gradient(272deg, #EBF6F9 22.38%, #EBF6F9 84.61%, #FFF 102.22%);
  font-family: "Manrope", sans-serif;
  padding-top: 30px;
  margin: none;
}
.refurb-heading{
  color: #000;
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.refurb-heading br{
  display: none;
}
.alltext{
  white-space: nowrap;
  padding-left:11%;
  padding-top: 7%;

  
}
.refurb-text >p{
  color: #000;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.machineImg{
  margin-bottom: 2%;
  display: flex;
  justify-content: center;
}
.machineImg>img{
  /* height:350px;
  width:350px; */
  height: 100%;
  width:90%;
  aspect-ratio: 1;
}
.refurb-button {
  border-radius: 7px;
  border: 1px solid #0D99FF;
  background: #FFF;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.07);
  padding: 12px 17px;
  /* Text Styles */
  font-size: 16px;
  font-weight: 500;
  color: #0D99FF;
  text-align: center;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  margin-top: 10px;

}

/* Hover effect */
.refurb-button:hover {
  background-color: #0D99FF;
  transition: 0.4s ease-out;
  color: #FFF;
}
@media (max-width: 1300px) {
  .refurb-heading{
    font-size: 28px;
    margin-bottom: 20px;
  }
  .alltext{
    white-space: nowrap;
    padding-left:11%;
    padding-top: 7%;  
  }
  .machineImg>img{
    height: 100%;
    width:85%;
    aspect-ratio: 1;
  }
  .refurb-text >p{
    font-size: 22px;
  }
  .refurb-button {
    font-size: 13px;
  }
}

@media (max-width:768px) {

 
  .refurb-container{
    width: 100%;
    overflow-x: hidden;
    padding-bottom: 10px;
    padding-top: 10px;
    
    margin-top: 0px;
    
    
  }

  .refurb-heading{
    color: #000;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 3vw;
  }

  .refurb-heading br {
    display: block;
  }
  
  .refurb-text p{
    color: #000;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* margin-bottom: 1vw; */
  }

  .refurb-button {
    border-radius: 7px;
    border: 1px solid #0D99FF;
    background: #FFF;
    box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.07);
    padding: 5px 16px;
    color: #0D99FF;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;  
    margin-top: 0px;
  }

  .alltext{
  
    white-space: nowrap;
  padding-left:8%;
  padding-top: 4%;
  
  
  }
  .machineImg{
    
    
  }

  .machineImg>img{
    
    width: 100%;
    height: 40vw
  }

}

