  @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
  .wrapper-loader{
    margin-left: 37%;
    /* display: flex;
    justify-content: center; */
   }
   .loader-container {
     width: 20vw;
     height: 100vh;
     /* display: flex;
     justify-content: flex-start;
     align-items: flex-start; */
   }
   .loader-slide {
     height:100%;
     position: relative;
     left:40%;
     right:45%;
     top:30%; 
     bottom: -20%;
   }
   
   .loader-slide > div {
     text-align: center;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     height:20vmax;
     position: absolute;
     animation: slide 6s infinite;
     opacity: 0;
   }
   .loader-image-container-1,.loader-image-container-4{
     width:150px;
     height:150px;
   }
   .loader-image-container-2,.loader-image-container-3{
     width:100px;
     height:100px;
   }
   .loader-slide div:nth-child(1) {
     animation-delay: 0s;
     
   }
   
   .loader-slide div:nth-child(2) {
     animation-delay: 2s;
   }
   .loader-slide div:nth-child(2)>div {
     margin-bottom: 15px;
   }
   .loader-slide div:nth-child(3)>div {
     margin-bottom: 25px;
   }
 
   .loader-slide div:nth-child(3) {
     animation-delay: 4s;
   }
   
   .loader-slide div:nth-child(4) {
     animation-delay: 6s;
   }
  
   .loader-image {
     width:100%;
     height:100%;
     object-fit: contain;
   }
   .loader-slide p {
     position: absolute;
     top:75%;
     white-space: nowrap;
     font-family: Manrope;
     font-size: 18px;
     font-weight: 700;
   }
   @keyframes slide {
     0% {
       left: 100%;
       opacity: 0;
     }
     4% {
       left: 0;
       opacity: 1;
     }
     20% {
       left: 0;
       opacity: 1;
     }
     24% {
       left: -100%;
       opacity: 0;
     }
     100% {
       left: -100%;
       opacity: 0;
     }
   }

   @media (max-width:768px) {

    .wrapper-loader {
      margin-left:35%;
    }
    .loader-container {
      width: 15vw;
      height: 50vh;
    }
    .loader-slide {
      height: 100%;
      position: relative;
      left: 0%;
      right: 0%;
      top: 60%;
      bottom: 0%;
    }
    
    .loader-slide > div {
      text-align: center;
      /* display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; */
      height: 35vmax;
      position: absolute;
      animation: slide 6s infinite;
      opacity: 0;
    }
    .loader-image-container-1,
    .loader-image-container-4 {
      width: 150px;
      height: 150px;
    }
    .loader-image-container-2,
    .loader-image-container-3 {
      width: 100px;
      height: 100px;
    }
    .loader-slide div:nth-child(1) {
      animation-delay: 0s;
    }
    
    .loader-slide div:nth-child(2) {
      animation-delay: 2s;
    }
    .loader-slide div:nth-child(2) > div {
      margin-bottom: 15px;
    }
    .loader-slide div:nth-child(3) > div {
      margin-bottom: 25px;
    }
    
    .loader-slide div:nth-child(3) {
      animation-delay: 4s;
    }
    
    .loader-slide div:nth-child(4) {
      animation-delay: 6s;
    }
    
    .loader-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .loader-slide p {
      position: absolute;
      top: 75%;
      white-space: nowrap;
      font-family: Manrope;
      font-size: 18px;
      font-weight: 700;
    }
   }