.referral-container{
    margin-top:7%;
    display: flex;
    margin-bottom:8% ;
    justify-content: space-evenly;
    margin-left: 5%;
    margin-right: 5%;

}

.referimg{
    height: 450px;
    width: 700px;
}
.referral-left{
    display: flex;
    flex-direction: column;
}

.referral-left h1{
    color: #0081DF;
    font-family: Manrope;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 133.333% */
    letter-spacing: -2.25px;
}

.referral-left h2{
    color: #E85400;
    font-family: Manrope;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
}

.refer-form-head {
    color: #0081DF;
font-family: Manrope;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 35px; /* 175% */
margin-bottom: 10px
;
}

.referral-left form{  
   border-radius: 7px;
    border: 0.7px solid #0081DF;
    background: #0081DF;
    width: 35vw;
    height: fit-content;

}

.basic-form-Referral {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 4.5%;
    gap: 20px;
    width: 90%;
    padding-top: 30px;
    padding-bottom: 40px;
    height: fit-content;

}
.basic-form-Referral div input{
    width: 100%;
    height: 37px;
    border-radius: 3px;
    border: 0.7px solid #0081DF;
    background: #FFF;
}

.basic-form-Referral > div{
    position: relative;
}

.basic-form-Referral > div > input{

    color: black;
    padding-left: 2%;
    font-family: Manrope;
    font-weight: 500;
}
.placeholder-name{
    position: absolute;
    top: -1px;
    left: 10px;
    color: #0081DF;
    font-family: Manrope;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 293.333% */
    pointer-events: none;
  }
  
  .placeholder-name:after{
    content: '*';
    color: red
  }

  .placeholder-name-withoutstar{
    position: absolute;
    top: -1px;
    left: 10px;
    color: #0081DF;
    font-family: Manrope;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 293.333% */
    pointer-events: none;
  }

  
/*   
  .form-control:valid + .placeholder-name { 
    display: none;
  } */

  /* .form-name-Referral:valid + .placeholder-name,
.form-name-Referral:focus + .placeholder-name ,.form-friendname-Referral:valid + .placeholder-name,
.form-friendname-Referral:focus + .placeholder-name + .form-info-Referral:valid , .placeholder-name-withoutstar + .form-info-Referral:focus {
    display: none;
} */

/* .form-name-Referral:valid + .placeholder-name,
.form-name-Referral:focus +  .placeholder-name, .form-friendname-Referral:valid + .placeholder-name,
.form-friendname-Referral:focus   + .form-info-Referral:valid + .placeholder-name-withoutstar, .form-info-Referral:focus + .placeholder-name-withoutstar {
    display: none;
} */

.form-name-Referral:valid + .placeholder-name,
.form-name-Referral:focus + .placeholder-name,
.form-friendname-Referral:valid + .placeholder-name,
.form-friendname-Referral:focus + .placeholder-name,
.form-friendname-Referral:valid + .form-info-Referral:valid + .placeholder-name-withoutstar,
.form-friendname-Referral:focus + .form-info-Referral:valid + .placeholder-name-withoutstar,
.form-info-Referral:focus + .placeholder-name-withoutstar {
    display: none;
}


  .placeholder-mobile{
    position: absolute;
    top: -1px;
    left: 10px;
    color: #0081DF;
    font-family: Manrope;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 293.333% */
    pointer-events: none;
  }
  
  .placeholder-mobile:after{
    content: '*';
    color: red
  }
  
  
  /* .form-control:valid + .placeholder-mobile { 
    display: none;
  } */
  .mobile-number-Referral:valid + .placeholder-mobile,
  .mobile-number-Referral:focus + .placeholder-mobile  , .mobile-friendnumber-Referral:valid + .placeholder-mobile,
  .mobile-friendnumber-Referral:focus + .placeholder-mobile {
      display: none;
  }

  .disabled-Referral{
    border-radius: 7px;
    border: 0.3px solid #D0D0D0;
    background: #D4D4D4;
    width: 95px;
    height: 35px;
    align-self: flex-end;
    cursor: not-allowed;
    margin-left: 83%;
    color: #6F6F6F;
font-family: Manrope;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }

  .active-Referral{
    border-radius: 7px;
    border: 0.3px solid #D0D0D0;
    background: green;
    width: 95px;
    height: 35px;
    align-self: flex-end;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: 83%;
  }

  .referral-tnc{
    color: #0081DF;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px; 
    margin-bottom: 20px;
  }

  .referral-right{
    display: flex;
    flex-direction: column;
    gap: 20px;
    
  }

  .referral-right ul{
    align-self: flex-start;
    margin-left: 10%;
  }
  .referral-right ul li{
    color: #006AB7;
font-family: Manrope;
font-size: 25px;
font-style: normal;
font-weight: 500;
line-height: 35px; /* 140% */
margin-bottom: 3px;
  }

  .phone-error-refer{
    color: white;
    font-size: 12px;

  }

  .referral-container-mobile{
    display: none;
  }


  @media (max-width:768px) {
    .referral-left{
      display: none;
    }
    .referral-right{
      display: none;
    }

    .referral-container{
      margin-left: 0%;
    margin-right: 0%;
    }

    .referral-container-mobile{
      display: flex;
      flex-direction: column;
      margin-top: 20%;
      margin-bottom: 20%;
      width: 100%;
      height: fit-content;
      align-items: center;
      gap: 10px;
      
    }

    .referral-banner-mobile{
      width: 100%;
      height: 140px;
    }

    .referral-cards-container{
      display: flex;
      gap: 4px;
      padding: 0px 8px;
    }

    .referral-card{
     
      position: relative;
      display: flex;
      flex-direction: column;   
      border-radius: 7px 7px 0px 0px;
      border: 0.2px solid #CFCFCF;
      background: #FCFCFC;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      width: 32%;
      height: 76px;
      padding-top: 5px;
      justify-content: space-around;
    }

    .rcard-text{
      color: #000;
      text-align: center;
      font-family: Manrope;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 15px; 
      padding: 5px;
    
    
    }

    .rbottom-bar{
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 7px;
      flex-shrink: 0;
      background: #0081DF;
      margin-top: 5px;

    }
    .referral-container-mobile form{  
      
       border: 0.7px solid #0081DF;
       background: #0081DF;
       width: 100%;
       height: fit-content;
       display: flex;
       justify-content: center;
       border-radius: 15px 15px 0px 0px;
      
   }
   
   .basic-form-Referral {
       position: relative;
       display: flex;
       flex-direction: column;
       margin-left: 0%;
       gap: 20px;
       width: 87%;
       padding-top: 30px;
       padding-bottom: 0px;
       height: fit-content;  
   }
   .basic-form-Referral div input{
       width: 100%;
       height: 37px;
       border-radius: 3px;
       border: 0.7px solid #0081DF;
       background: #FFF;
   }
   
   .basic-form-Referral > div{
       position: relative;
   }
   
   .basic-form-Referral > div > input{
   
       color: black;
       padding-left: 2%;
       font-family: Manrope;
       font-weight: 500;
   }
   
     .disabled-Referral{
       width: 90px;
       height: 35px;
       margin-left: 75%;
       
     }
   
     .active-Referral{
        width: 90px;
        height: 35px;
        margin-left: 75%;
     }

     .referral-tnc{
      color: #FFF;
      font-family: Manrope;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 35px; 
      margin-bottom: 0px;
    }

    .placeholder-name{
      position: absolute;
      top: 11px;
      left: 10px;
      line-height: 44px; /* 293.333% */
      pointer-events: none;
      color: #000;
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .placeholder-name-withoutstar{
      position: absolute;
      top: 11px;
      left: 10px;
      line-height: 44px; /* 293.333% */
      pointer-events: none;
      color: #000;
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .placeholder-mobile{
      position: absolute;
      top: 11px;
      left: 10px;
      pointer-events: none;
      color: #000;
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }




    


    
  }
 
   

