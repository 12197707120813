@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;500&display=swap");
.whatsapp-container {
  /* position: relative; */
  
  
}
.whatsapp-icon {
  height: 55px;
  width: 55px;
  position: fixed;
  right: 4%;
  top: 83%;
  z-index: 100;
}
.appear {
  animation: whatsappIconAppear 3s ease-in-out;
}

.whatsapp-icon:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
}
.whatsapp-notif {
  position: fixed;
  top: 82.3%;
  right: 3.7%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: white;
  background-color: #d20000;
  border: none;
  border-radius: 50%;
  z-index: 100;
}
.appear2 {
  animation: whatsappNotifAppear 1s ease-in-out;
}
.chat-container {
  border-radius: 15px;
  border: 0.7px solid #d9d9d9;
  background: #f7f7f7;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.05);
  position: fixed;
  right: 3.5%;
  top: 55%;
  height: 40vh;
  width: 30vw;
  
}
.chat-header {
  border-radius: 10px 10px 0px 0px;
  background: rgba(37, 211, 102, 1);
  padding: 4% 5%;
  display: flex;
  justify-content: flex-start;
  gap: 3%;
  align-items: center;
  position: relative;
}
.chat-header > span {
  color: #fff;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.whatsapp-outlined{
  height: "30px";
  width: "31px";
}
.chat-close {
  height: 23px;
  width: 23px;
  position: absolute;
  right: 5%;
  cursor: pointer;
}
.chat-body {
  padding: 6.5% 8% 17% 12%;
}
.chat-body > p {
  color: #000;
  font-family: Manrope;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.7px;
  margin-bottom: 8%;
  position: relative;
}

.chat-button {
  position: absolute;
  margin: 3% 2%;
  right: 4%;
  bottom: 2%;
  border: none;
  font-family: Inter;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-radius: 10px;
  background: rgba(37, 211, 102, 1);
  padding: 3% 7%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat-button:hover {
  cursor: pointer;
  border: solid rgba(37, 211, 102, 1);
  padding: 2.5% 6.5%;
  background-color: white;
  color: rgba(37, 211, 102, 1);
  transition: background-color 0.3s ease-in-out;
}
.send-icon{
  color:white;
  margin-left: 10px;
}
.chat-button:hover .send-icon{
  color: rgba(37, 211, 102, 1);
}



@keyframes whatsappIconAppear {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  75% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes whatsappNotifAppear {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px){
  .whatsapp-icon {
    height: 45px;
    width: 45px;
    position: fixed;
    right: 4%;
    top:82%;
    z-index: 1000;
  }
  .appear {
    animation: whatsappIconAppear 3s ease-in-out;
  }
  .on-plp{
    top: 73.5%;
  }
  .whatsapp-icon:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: transform 0.5s ease-in-out;
  }
  .whatsapp-notif {
    position: fixed;
    top: 81.5%;
    right: 3%;
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: white;
    background-color: #d20000;
    border: none;
    border-radius: 50%;
    z-index: 1100;
  }
  .on-plp-notif{
    top: 73%;
    right: 3%;
  }
  .appear2 {
    animation: whatsappNotifAppear 1s ease-in-out;
  }
  .chat-container {
    border-radius: 15px;
    border: 0.7px solid #d9d9d9;
    background: #f7f7f7;
    box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.05);
    position: fixed;
    right: 2vw;
    top: 117vw;
    height: 23vh;
    width: 53vw;
    
  }
  .whatsapp-outlined{
    height: "10px";
    width: "10px";
  }
  .chat-header {
    border-radius: 10px 10px 0px 0px;
    background: rgba(37, 211, 102, 1);
    padding: 3% 5%;
    display: flex;
    justify-content: flex-start;
    gap: 3%;
    align-items: center;
    position: relative;
  }
  .chat-header > span {
    color: #fff;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 5px;
  }
  .chat-close {
    height: 15px;
    width: 15px;
    position: absolute;
    right: 5%;
    cursor: pointer;
  }
  .chat-body {
    padding: 4% 6% 17% 7%;
  }
  .chat-body > p {
    color: #000;
    font-family: Manrope;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 8%;
    position: relative;
  }
  
  .chat-button {
    position: absolute;
    margin: 3% 2%;
    right: 4%;
    bottom: 2.5%;
    border: none;
    font-family: Inter;
    color: white;
    font-size: 11px;
    font-weight: 600;
    border-radius: 7px;
    background: rgba(37, 211, 102, 1);
    padding: 2% 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .chat-button:hover {
    cursor: pointer;
    border: solid rgba(37, 211, 102, 1);
    padding: 2.5% 6.5%;
    background-color: white;
    color: rgba(37, 211, 102, 1);
    transition: background-color 0.3s ease-in-out;
  }
  .send-icon{
    color:white;
    margin-left: 10px;
    height: 10px;
    width:10px;
    
  }
  .chat-button:hover .send-icon{
    color: rgba(37, 211, 102, 1);
  }
  
}