@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
.LYP {
  margin: 6% 5% 2% 5%;
  padding-top: 10px;
  font-family: Manrope;
  display: grid;
  grid-template-columns: 0.95fr 1fr;
  column-gap: 2.3%;
  position: relative;
}
.LYP-img {
  height: fit-content;
}
.LYP-img > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.LYP-img > div > p {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-top: 5%;
  max-width: 60%;
}
.LYP-img img {
  width: 100%;
  margin-top: 5%;
  aspect-ratio: 1.749;
}
.LYP-img > h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 3%;
}
.LYP-img > p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 4%;
}

.LYP-form {
  background-image: url("../../images/bg-lyp.jpeg");
  background-size: 100% 100%;
  border: none;
  border-radius: 7px;
  padding: 2.5% 5%;
  margin-top: 2%;
  height: fit-content;
  position: relative;
}
.LYP-form > h3 {
  font-size: 20px;
  margin-bottom: 2%;
}
.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0%;
}
.basic-form-LYP > div > div {
  margin-bottom: 6%;
  width: 94%;
}

.basic-form-LYP input {
  border: none;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  width: 90%;
  padding: 3.5% 6%;
  margin-top: 2%;
}

.basic-form-LYP .form-name::placeholder {
  color: #868686;
  font-weight: 500;
}
.name-container {
  margin-bottom: 3%;
  width: 100%;
}
.name-container .placeholder {
  left: 2.9%;
  top: 40%;
}
.input-container {
  position: relative;
}
.input-container input:valid + .placeholder {
  display: none;
}
.input-container .form-name::placeholder {
  color: #868686;
  font-weight: 600;
  opacity: 0.98;
}
.placeholder {
  position: absolute;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  pointer-events: none;
  top: 33%;
  left: 6%;
  margin: auto;
  color: #868686;
}
.placeholder span {
  color: #df0d00;
}
.form-name:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.basic-form-LYP .mobile-number::placeholder {
  color: #868686;
  font-weight: 500;
}
.mobile-number:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.LYP-info {
  border: none;
  border-radius: 3px;
  width: 92%;
  font-size: 14px;
  padding: 1.5% 3%;
  margin-top: 1.5%;
  font-family: Manrope;
  resize: none;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 2%;
}
.LYP-form .LYP-info::placeholder {
  color: #868686;
  font-weight: 600;
  font-size: 14px;
}
.LYP-info:focus {
  outline: solid 1.5px #0081df;
  border: none;
}
.active-LYP {
  background-color: #0081df;
  font-size: 17px;
  font-weight: 500;
  border-radius: 7px;
  color: white;
  padding: 1.5% 6%;
  border: none;
  /* position: absolute;
      right: 4%;
      top: 89%; */
  margin-left: 75.5%;
  margin-bottom: 2%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.active-LYP:hover {
  opacity: 0.7;
  cursor: pointer;
}
.disabled-LYP {
  background-color: #0081df;
  background-color: #c4c4c4;
  cursor: not-allowed;
  font-size: 17px;
  font-weight: 500;
  border-radius: 7px;
  color: white;
  padding: 2% 6%;
  border: none;
  margin-left: 75.5%;
  margin-bottom: 2%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.phone-LYP-form {
  display: none;
}
@media (min-width: 1500px) {
  .LYP {
    margin: 6% 8% 2% 8%;
  }
}
@media (max-width: 1300px) {
  .LYP-img > p {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .dropdown-icon-LYP {
    position: absolute;
    left: 90%;
    margin-top: 1.6%;
  }

 

  .basic-form-LYP-mobile {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 10px;
    align-items: center;
  }

  .basic-form-LYP-mobile > div {
    position: relative;
    display: flex;
    justify-content: flex-start;
    /* margin-top:10px ; */
    align-items: center;
    width: 87%;
  }

  .mobile-placeholder-name-LYP,
  .mobile-placeholder-mobile-LYP,
  .mobile-placeholder-city-LYP {
    color: #bababa;
    position: absolute;
    top: 4.5vw;
    left: 12px;
    color: #6f6f6f;

    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    pointer-events: none;
  }

  .mobile-placeholder-name-LYP:after,
  .mobile-placeholder-mobile-LYP:after,
  .mobile-placeholder-city-LYP:after {
    content: "*";
    color: red;
  }

  .form-name-LYP:valid + .mobile-placeholder-name-LYP {
    display: none;
  }
  .mobile-number-LYP:valid + .mobile-placeholder-mobile-LYP {
    display: none;
  }
  .form-city-LYP:valid + .mobile-placeholder-city-LYP {
    display: none;
  }

  .LYP-img div {
    display: none;
  }

  .LYP {
    display: flex;
    flex-direction: column;
    
    padding-bottom: 12vw;
    padding-top: 10vw;
    margin-left: 0vw;
    margin-right: 0vw;
  }
  .LYP-img {
    margin-left: 5%;
    margin-right: 5%;
  }
  .LYP-img {
    display: flex;
    flex-direction: column;
  }
  .LYP-img h3 {
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .LYP-img p {
    color: #000;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .LYP-form {
    display: none;
  }

  .phone-LYP-form {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-top: 3vw;
    height: fit-content;
    padding-bottom: 10px;
    border-radius: 15px 15px 0px 0px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.15);
    padding-left: 1vw;
    padding-right: 1vw;
    margin-left: 1vw;
    margin-right: 1vw;
  }

  .phone-LYP-form h4 {
    align-self: flex-start;
    margin-left: 6vw;
    margin-bottom: 3vw;
    margin-top: 4vw;
  }

  .basic-form-LYP-mobile {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 6px;
    align-items: center;
  }

  .basic-form-LYP-mobile input {
    border-radius: 5px;
    padding: 4% 3%;
    margin-top: 2%;
    background-color: transparent;
    color: black;
    width: 100%;
    font-family: Manrope;
    resize: none;
    font-weight: 500;
    font-size: 15px;
    height: 10px;
    border: 0.7px solid #c7c7c7;
  }
  .dropdown-type div {
    border-radius: 5px;
    /* padding:5% 3%;
    margin-top: 2%; */
    background-color: transparent;
    color: black;
    width: 100%;
    font-family: Manrope;
    resize: none;
    font-weight: 500;
    /* font-size: 15px; */
    height: 20px;
    border: 0.7px solid #c7c7c7;
  }
  .dropdown-type li{
    font-size: 12px;
    padding: 2% ;
  }
  .dropdown-type p{
    margin-top: 0%;
  }
  .LYP-info,
  .basic-form-LYP-mobile textarea {
    border: 0.7px solid #cfcfcf;
    border-radius: 5px;
    margin-top: 1%;
    height: 100px;
    background-color: transparent;
    padding: 1.5% 2%;
    width: 82%;
    font-family: Manrope;
    font-weight: 500;
    font-size: 15px;
    color: black;
  }
  .LYP-info::placeholder {
    text-align: left;
    color: #6f6f6f;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  input:focus,
  textarea:focus {
    outline: solid 2px #0081df;
    border: none;
  }
  .active-LYP-mobile {
    background-color: #0081df;
    font-size: 16px;
    font-weight: 600;
    border-radius: 7px;
    color: white;
    padding: 7px 25px;
    border: none;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.25);
    align-self: flex-end;
  }
  .active-LYP-mobile:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .disabled-LYP-mobile {
    background-color: #e3e3e3;
    font-size: 16px;
    font-weight: 600;
    border-radius: 7px;
    color: #a59f9f;
    padding: 7px 25px;
    border: none;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.25);
    align-self: flex-end;
    margin-bottom: 2vw;
  }

  ::placeholder {
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .LYP-dropdown-mobile {
    color: #6f6f6f;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
