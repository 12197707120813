@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");


.vds-google-cast-button{
  display: none;
}

/* .vds-time{
  display: none;
} */

.videoplayercontainer{
  height: fit-content;
  padding-bottom: 4%;
  padding-top:3% ;
  /* padding-left: 1%; */
  }


  .videoplayercontainer >p{
    align-self: flex-start;
    line-height: normal;
    margin-left: 9%;
    color: #000000;
    font-family: "Manrope", Helvetica;
    font-size: 34px;
    font-weight: 700;
    left: 128px;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: 4%;
}

  .container{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    height: fit-content;
    padding: 0px;
    align-items: flex-start;
    /* padding-top: 2%; */
  }


  .testimonial-video-container{
    height: 20vw;
    width: 50%;
    position: relative;
    padding: 0px;
    margin: 0px;

  }

  /* all three slides' container */
  .testimonial-video{
    height: fit-content ;
    width: 36vw ;
    margin: 0px;
    padding: 0px; 
    display: flex;
    justify-content: center;
    align-items: center; 
    margin-right: 4%;
    background-color: transparent;
    cursor: context-menu;
    
}
.testimonial-video-container > div > div { 
  transition: all 1.5s ease 0s !important;  
  cursor:  grab !important;
}

.react-player{
    /* height: 100% !important  ;
    width: 100%  !important; */
    margin: 0px;
    padding: 0px; 
    background-color: transparent;
    border-radius: 8px;
    box-shadow: 3px 2px 2px 2px #00000040;
    background-color: transparent;
    
}

.react-player > div > video{
  width: 100%;

}
.testimonial-video {
  position: relative !important;
  filter: blur(0px) !important;
}

.blur {
  filter: blur(3px) !important; 
  pointer-events: none;
}

  .testimonialslider{
    width: 42%;
    height: 68%;
    height: fit-content;
    display:flex;
    align-items: center;
  }
  .testimoninaltext{
    height: fit-content;
    font-family: 'Manrope';
    font-weight: 500;
    font-size: 18px;
    padding: 15px; 
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    position: relative;
    padding-left:5px;
    padding-top: 5%;
    z-index: 2;

  }

  .tquote{
    margin-bottom: 10px;
    text-align: left;
    color: #000000;
      font-family: "Manrope", Helvetica;
      font-size: 24px;
      font-weight: 400;
      line-height:normal;    
  }
  .opening-brace{
    position: absolute;
    left: 0%;
    top: -1%;
    font-size: 19px;
    height: 37px !important;
    width: 39px !important;
  }
  
  .closing-brace{
    position: absolute;
    /* right: 20%;
    top:48%; */
    margin-top: 3px;
    margin-left: 10px;
    height: 37px !important;
    width: 39px !important;
  }
  
  .thospital{
      color: #890000;
      font-family: "Manrope", Helvetica;
      font-size: 23px;
      font-weight: 700;
      left: 0;
      letter-spacing: 0;
      line-height: normal;
     
  }

  
  
  .tdesignation{
    
    margin-bottom: 10px;
    color: #006a81;
      font-family: "Manrope", Helvetica;
      font-size: 22px;
      font-weight: 700;
      left: 0;
      letter-spacing: 0;
      line-height: normal;
    
    
  }
  .tname{
    color: #000000;
    font-family: "Manrope", Helvetica;
    font-size: 28px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
  }

  .testimonial-arrow-t-next-arrow , .testimonial-arrow-t-prev-arrow{
    background-color: white;
    border: 0px;
    align-self: center;
    cursor: pointer;
    padding: 0px;
    margin: 0px;
    
  }


  .testimonial-btn{
    background-color: transparent;
    border: none;
    padding: none;
  }

  .inactive-card {
    pointer-events: none;
  }

  .vds-controls{
    border-radius: 8px;
  }
  .container .slick-dots{
    position: absolute;
    bottom: -24%;
    right: 58%;
    pointer-events: none;
    height: 50px;
  }

  

@media (max-width:768px) {


  .container .slick-dots{
    position: absolute;
    bottom: -33%;
    right: 0%;
    pointer-events: none;
  }
  
  .vds-poster{
    height: 100%;
  }

  
  .videoplayercontainer{
    height: fit-content;
    /* background-color: magenta; */
    padding-top:3% ;
    padding-bottom: 2%;
    }

    .videoplayercontainer > p{

      align-self: flex-start;
      line-height: normal;
      margin-left: 3%;
      font-size: 16px;
      letter-spacing: 0;
      line-height: normal;
      margin-bottom: 2%;
    }
  
    .container{
      
      display: flex;
      flex-direction: column;
      /* gap: 10px; */
      gap: 0px;
      justify-content: flex-start;
      height: fit-content;
      padding: 0px;
      align-items: center;
      padding-top: 2%;
      padding-bottom: 2%;
  
    }
  
    .testimonialslider{
     
      width: 90%;
      height: fit-content;
      display:flex;
      align-items: center;
      margin-bottom: 6%;
    }

    .testimonial-video-container{  
      height: 45vw;
      width: 90%;
      position: relative;
      padding: 0px;
      margin-right: 1%;
       
    }

    .cc{   
      height: 100% !important;
      width: 100% !important;
      border-radius: 10px;
    }
  
   
    .testimonial-video{
      height: fit-content !important;
      width :60vw !important;
        margin: 0px;
        padding: 0px;
        background-color: transparent; 
        border-radius: 10px;
        
        
    }
  
    .react-player{
      margin-right: 10%; 
      margin-top: 0%;
      width: 100% ;
      margin: 0px;
      padding: 0px;
      border-radius: 8px;
      box-shadow: 3px 1px 4px 1px #00000040;
      height: fit-content !important;
      background-color: transparent;    
    
    }

   

    .react-player > div > video{
      height: 100% !important  ;
      width: 100%  !important;
      border-radius: 10px !important ;
      border-top-left-radius: 10px !important;   
      height: 22vh !important;      
    }
  
    .testimonial-btn{
      background-color: transparent;
      border: none;
      padding: none;
    }
  
    /* .testimonialsection{
      background-color: #890000;
      height: auto !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
    } */
  
    .testimoninaltext{
      height: fit-content;
      padding: 0px;
      width: 90%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      gap: 2px;
      align-items: center;
      text-align: center;
      /* background-color: yellow; */
      padding-top: 10px;
      position: relative;
    }
  
    .tquote{
      margin-bottom: 5px;
      text-align: center;
      color: #000000;
        font-family: "Manrope", Helvetica;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        margin-top: 0px;
        
    }
  
    .opening-brace{
      position: absolute;
      left: 0%;
      top: -1%;
      height: 15px !important;
      width: 15px !important;
      margin-bottom: 5px;
    
    }
    
    .closing-brace{
      position: absolute;
      /* right: 10%;
      top: 43%; */
      height: 15px !important;
      width: 15px !important;
      margin: 0px;
      margin-top: 5px;
      margin-left: 5px;
    
    }
    
    .thospital{
        
        color: #890000;
        font-family: "Manrope", Helvetica;
        font-size: 13px;
        font-weight: 700;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
       
    }
    
    .tdesignation{
      
      margin-bottom: 0px;
      color: #006a81;
        font-family: "Manrope", Helvetica;
        font-size: 12px;
        font-weight: 700;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
      
      
    }
    .tname{
      color: #000000;
      font-family: "Manrope", Helvetica;
      font-size: 14px;
      font-weight: 700;
      left: 0;
      letter-spacing: 0;
      line-height: normal;
    }
  
    .testimonial-arrow {
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      padding: 8px 16px;
      border: none;
      cursor: pointer;
      outline: none;
      position: absolute;
      top:33%;
      transform: translateY(-50%);
     
    }
    
  
    .testimonial-arrow-t-next-arrow , .testimonial-arrow-t-prev-arrow{
      background-color: transparent;
      border: 0px;
      position: absolute;
      cursor: pointer;
    }

     .testimonial-arrow-t-next-arrow{
      bottom: 14%;
      right: 8%;
      /* bottom: 0%; */
      z-index: 1000;
    }
    .testimonial-arrow-t-prev-arrow{
      bottom: 14%;
      left: 10%;
      z-index: 1000;
      /* bottom: 0%; */
     
    }

   
}


  