/* ProductDisplayBar.css */

@import url("https://fonts.googleapis.com/css?family=Manrope");

.category-container {
  display: flex;
  justify-content: space-between;
  max-width: 82%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4%;
  position: relative;
}

.department-categories {
  height: 100%;
  margin-top: 4%;
  padding: 0;
  color: #000;
  font-family: Manrope;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.view-all-button-categories {
  color: #0d99ff;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  bottom: 0px;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  right: 5px;
  letter-spacing: 0;
  line-height: normal;

  height: 20px;
}
.view-all-button-categories:hover {
  border-bottom: 3px solid #0d99ff;
}

.product-display-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 82%;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  gap: 2.36%;
  /* height: 188px;   */
}

.product-card {
  display: block;
  text-align: center;
  width: 18%;
  /* height: 100%; */
  align-items: flex-end;
  
}
.product-card > div{
  width:100%;
  aspect-ratio: 1.138;
  border-radius: 7px;
  background-color: #F7F6F6;
  display: flex;
  justify-content: center;
  position: relative;
  /* align-items: center; */
}

/* .product-image {
  aspect-ratio: 1.138;
  width: 100%;
  object-fit: inherit;
  border-radius: 7px;
}
.gradient-overlay {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      180deg,
      rgba(247, 246, 246, 0) 23.94%,
      #0e2438 100%
    );
    border-radius: 7px;
    opacity: 0.85;
  }
} */
.product-card .category-description {
  /* position: relative; */
  font-family: Manrope;
  font-weight: 600;
  text-align: center;
  position: absolute;
  top: 77%;
  color:#868686;
  /* display: flex;
  justify-content: center; */
  font-size: 16px;
}
.med-icons{
  position: absolute;
  top: 20%;
  
}
.product-display-bar button {
  margin-top: 10px;
  margin-bottom: 0%;
  padding: 12px 16px;
  background-color: transparent;
  border: 0.7px solid;
  border-color: #0d99ff;
  border-radius: 7px;
  cursor: pointer;
  width: 100%;

  color: #0d99ff;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mobile-product-display-bar{
  display: none;
}
@media (min-width: 1350px) {
  .category-container {
    max-width: 82.4%;
  }
  .product-display-bar {
    max-width: 82.4%;
  }
}
@media (min-width: 1500px) {
  .category-container {
    max-width: 80%;
  }
  .product-display-bar {
    max-width: 80%;
  }
}
@media(max-width:1350px){
  .med-icons{
    max-width: 90px;
    max-height: 80px;
    object-fit: contain;
  }
}
@media(max-width:1100px){
  .med-icons{
    max-width: 70px;
    max-height: 70px;
    object-fit: contain;
  }
}

@media (max-width:768px) {

  
 
  .product-display-bar{
    display: none;
  }

  .product-display-bar-box{ 
    
    max-width: 100%;
    width: 100vw;
    background-color:white;
    margin-top: 5vw;  
    font-family: Manrope;
    margin-left: none;
    margin-right: none;
    width: 100%;
    padding: 0px;  
    margin-top: 5vw;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column; 
    align-items: center; 
    
  }

  .category-container{
    display: flex;
    
    max-width: 100%;
    /* background-color: blue; */
    margin: 0px;
    padding: 0px;
    
    width: 95vw;
    margin-bottom: 2%;
    
    

  }
  
.department-categories{
color: #000;
font-family: Manrope;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 3%;
margin-bottom: 2vw;
}
.view-all-button-categories{
color: #0081DF;
font-family: Manrope;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.mobile-product-display-bar{

  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  max-width: 100%;
  width: 95vw;
  gap: 3px;
  /* background-color: red; */

}

.product-card {
display: block;
text-align: center;
width:31.5%;
/* height: 100%; */
align-items: flex-end;
padding-left: 4px;
padding-right: 4px;

}

.product-card > div{
width:100%;
aspect-ratio: 1.138;
border-radius: 5px;
background-color: #F7F6F6;
display: flex;
justify-content: center;
position: relative;
/* align-items: center; */

}
.med-icons{
max-height: 50px;
max-width:50px;
object-fit: contain;


}

.product-card .category-description {

font-size: 11.5px;
}


.mobile-product-display-bar button{
  margin-top: 10px;
  margin-bottom: 0%;
  padding: 6px 4px;
  background-color: transparent;
  border: 0.7px solid;
  border-color: #0d99ff;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  color: #0d99ff;
  font-family: "Manrope";
  font-size: 12.5px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
  
}